import axios from "axios";
//import { toast } from "react-toastify";

// import logService from "services/logService";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

// console.log("REACT_APP_NAME:", process.env.REACT_APP_NAME);
// console.log("REACT_APP_VERSION:", process.env.REACT_APP_VERSION);
console.log("REACT_APP_SERVER_URL:", process.env.REACT_APP_SERVER_URL);
console.log("REACT_APP_CLIENT_URL:", process.env.REACT_APP_CLIENT_URL);
// console.log("REACT_APP_SITE_TITLE:", process.env.REACT_APP_SITE_TITLE);

axios.interceptors.response.use(null, (error) => {
   // if (error.response) {
   //    // The request was made and the server responded with a status code
   //    // that falls out of the range of 2xx
   //    // console.log("HTTP:", error.response.data);
   //    // console.log("HTTP:", error.response.status);
   //    // console.log("HTTP:", error.response.headers);

   //    if (error.response.status >= 500) {
   //       let logParams = {
   //          data: error.response.data,
   //          status: error.response.status,
   //          headers: error.response.headers,
   //       };
   //       logService.error("HTTP", "The request was made and the server responded with a status code that falls out of the range of 2xx.", logParams);
   //    }
   // } else if (error.request) {
   //    // The request was made but no response was received
   //    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
   //    // http.ClientRequest in node.js
   //    // console.log("HTTP:", error.request);

   //    // if (error.request.readyState === 4) {
   //    //    if (error.request.status === 200) {
   //    //       console.log("HTTP:", error.request.responseText);
   //    //    } else {
   //    //       console.log("HTTP:", error.statusText);
   //    //    }
   //    // }
   //    // console.log("HTTP:", error.config);

   //    let logParams = {
   //       //request: error.request,
   //       status: error.request.status,
   //       statusText: error.statusText,
   //       responseText: error.request.responseText,
   //       url: error.config.url,
   //    };
   //    logService.error("HTTP", "The request was made but no response was received.", logParams);
   // } else {
   //    // Something happened in setting up the request that triggered an Error
   //    // console.log("HTTP:", error.message);

   //    let logParams = {
   //       message: error.message,
   //    };
   //    logService.error("HTTP", "Something happened in setting up the request that triggered an error.", logParams);
   // }

   return Promise.reject(error);
});

function setToken(userToken) {
   if (userToken !== "undefined") {
      //console.log("setToken: " + userToken);
      axios.defaults.headers.common["x-auth-token"] = userToken;
   }
}

function deleteData(url, data) {
   return axios.delete(url, { data });
}

export default {
   get: axios.get,
   post: axios.post,
   put: axios.put,
   delete: axios.delete,
   deleteData,
   setToken,
};
