import React, { Component } from "react";

import catalogService from "services/catalogService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-catalog.css";

import tokenImg from "img/token.png";

const CATALOG_IMG_PATH = "/img/catalog/";

class RoomCatalog extends Component {
   state = {
      catalogData: [],
   };

   async componentDidMount() {
      const catalogData = await catalogService.getCatalog();
      //console.log("catalogData:", catalogData);

      this.setState({ catalogData });
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   selectProduct = (event) => {
      //console.log("selectProduct()");

      const redeemItemId = event.currentTarget.value;
      console.log("redeemItemId:", redeemItemId);

      const { setRedeemProductId } = this.props;
      setRedeemProductId(redeemItemId);
   };

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Catalog</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>

            <div className="side-body">
               <div className="catalog">{this.renderCatalog()}</div>
            </div>
         </div>
      );
   }

   renderCatalog() {
      const { catalogData } = this.state;

      if (!catalogData || catalogData.length === 0) return null;

      return this.renderProducts(catalogData);
   }

   renderProducts(catalogData) {
      const html = [];

      for (const user of catalogData) {
         const { id, name, price, img } = user;

         let classes = "product";

         const imgSrc = CATALOG_IMG_PATH + img;

         html.push(
            <div key={"product-" + id} className={classes}>
               <div className="image img-fit-width">
                  <img src={imgSrc} alt={name} />
               </div>
               <div className="name">{name}</div>
               <button className="price" value={id} onClick={this.selectProduct}>
                  <div className="icon img-fit-height">
                     <img src={tokenImg} alt="XR Token" />
                  </div>
                  {price}
               </button>
            </div>
         );
      }

      return html;
   }
}

export default RoomCatalog;
