import http from "services/httpService";

import msgService from "services/msgService";

//  const leaderboardEndpoint = "/api/leaderboard";

async function getLeaderboard(meetingId) {
   //console.log("getLeaderboard() meetingId:", meetingId);

   // const leaderboardUrl = leaderboardEndpoint + "/" + meetingId;

   const leaderboardUrl = "/leaderboard.json";

   try {
      const { data } = await http.get(leaderboardUrl);
      // console.log("data:", data);

      return data;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "leaderboard_data_error");
   }
}

export default {
   getLeaderboard,
};
