import React, { Component } from "react";
import Joi from "joi-browser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { joiSchema } from "data/joiSchema";

import Input from "components/form/input";
import InputSimple from "components/form/inputSimple";
import PasswordOld from "components/form/passwordOld";
import PasswordNew from "components/form/passwordNew";
import Select from "components/form/select";
import DropdownMenu from "components/form/dropdownMenu";
import PlatformMenu from "components/form/platformMenu";
import DateTime from "components/form/dateTime";
import Textarea from "components/form/textarea";
import Checkbox from "components/form/checkbox";

class Form extends Component {
   state = {
      formData: {},
      isValid: {},
      errors: {},
      scores: {},
   };

   schema = {};

   componentDidMount() {
      this.getSchema();
   }

   getSchema = () => {
      const { formData } = this.state;

      for (let i in formData) {
         this.schema[i] = joiSchema(i);
      }
   };

   validateAll = () => {
      if (Object.entries(this.schema).length === 0) {
         this.getSchema();
      }

      const options = { abortEarly: false };

      let { error } = Joi.validate(this.state.formData, this.schema, options);

      const errors = {};
      const scores = { ...this.state.scores };
      const keys = Object.keys(scores);
      //console.log("keys:", keys);

      for (const key of keys) {
         //console.log("key:", key, "value:", scores[key]);
         const score = scores[key];
         if (score && score <= 1) {
            errors[key] = '"Password" is too weak.';
         }
      }

      if (error) {
         for (let item of error.details) {
            errors[item.path[0]] = item.message;
         }
      }

      const errorKeys = Object.keys(errors).length;
      //console.log(errorKeys);

      return errorKeys !== 0 ? errors : null;
   };

   validateOne = ({ name, value, score }) => {
      //console.log("validateOne", name, value, score);

      if (!this.schema[name]) {
         this.getSchema();
      }

      const obj = { [name]: value };
      const schema = { [name]: this.schema[name] };
      const { error } = Joi.validate(obj, schema);

      if (error) return error.details[0].message;

      if (score && score <= 1) {
         return '"' + schema[name]._flags.label + '" is too weak.';
      } else {
         return null;
      }
   };

   handleFocus = ({ currentTarget: input }) => {
      //console.log("handleFocus", input);

      const errors = { ...this.state.errors };
      delete errors[input.name];

      this.setState({ errors });
   };

   handleChange = ({ currentTarget: input }) => {
      //console.log("handleChange()", input);

      const errorMessage = this.validateOne(input);

      const isValid = { ...this.state.isValid };
      isValid[input.name] = errorMessage ? false : true;
      //console.log(input.name, "isValid:", isValid[input.name], "errorMessage:", errorMessage);

      const formData = { ...this.state.formData };
      formData[input.name] = input.value;

      const scores = { ...this.state.scores };
      scores[input.name] = input.score;

      this.setState({ formData, isValid, scores });
   };

   handleBlur = ({ currentTarget: input }) => {
      const errors = { ...this.state.errors };
      let errorMessage = this.validateOne(input);

      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
      //console.log("error:", errors[input.name]);

      const isValid = { ...this.state.isValid };
      isValid[input.name] = errorMessage ? false : true;
      //console.log(input.name, "isValid:", isValid[input.name]);

      const formData = { ...this.state.formData };
      formData[input.name] = input.value;

      const scores = { ...this.state.scores };
      scores[input.name] = input.score;

      this.setState({ formData, isValid, errors, scores });
   };

   handleSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.validateForm();

      this.doSubmit();
   };

   validateForm = () => {
      //console.log("validateForm()");

      const errors = this.validateAll();
      //console.log("errors", errors);
      this.setState({ errors: errors || {} });
      if (errors) return;
   };

   renderInput(name, label, autoComplete, isDisabled = false, help = false) {
      const { formData, isValid, errors } = this.state;

      return <Input name={name} value={formData[name]} isValid={isValid[name]} label={label} help={help} type="text" onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} autoComplete={autoComplete} error={errors[name]} isDisabled={isDisabled} />;
   }

   renderInputSimple(name, isDisabled = false) {
      const { formData, isValid, errors } = this.state;

      return <InputSimple name={name} value={formData[name]} isValid={isValid[name]} type="text" onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} isDisabled={isDisabled} />;
   }

   renderSelect(name, label, options, selected) {
      const { formData, isValid, errors } = this.state;

      return <Select name={name} value={formData[name]} isValid={isValid[name]} label={label} options={options} selected={selected} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderDropdownMenu(name, label, options, selected) {
      const { formData, isValid, errors } = this.state;

      return <DropdownMenu name={name} value={formData[name]} isValid={isValid[name]} label={label} options={options} selected={selected} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderPlatformMenu(name, label, options, selected) {
      const { formData, isValid, errors } = this.state;

      return <PlatformMenu name={name} value={formData[name]} isValid={isValid[name]} label={label} options={options} selected={selected} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderDateTime(name, label, options) {
      const { formData, isValid, errors } = this.state;

      return <DateTime name={name} options={options} value={formData[name]} isValid={isValid[name]} label={label} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderTextarea(name, label, rows) {
      const { formData, isValid, errors } = this.state;

      return <Textarea name={name} value={formData[name]} isValid={isValid[name]} label={label} rows={rows} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderPasswordOld(name, label) {
      const { formData, isValid, errors } = this.state;

      return <PasswordOld name={name} value={formData[name]} isValid={isValid[name]} label={label} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderPasswordNew(name, label) {
      const { formData, isValid, errors } = this.state;

      return <PasswordNew name={name} value={formData[name]} isValid={isValid[name]} label={label} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} error={errors[name]} />;
   }

   renderCheckbox(name, label, isDisabled = false) {
      const { formData } = this.state;

      return <Checkbox name={name} label={label} value={formData[name]} onFocus={this.handleFocus} onChange={this.handleChange} onBlur={this.handleBlur} isDisabled={isDisabled} />;
   }

   renderButton(label, icon, type = "primary") {
      const classes = "btn btn-" + type;
      return (
         <button disabled={this.validateAll()} className={classes}>
            {icon && <FontAwesomeIcon icon={icon} className="btn-icon" />}
            {label}
         </button>
      );
   }
}

export default Form;
