import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Iframe from "react-iframe";

import userService from "services/userService";

import RoomLoader from "components/room/windows/roomLoader";
import RoomVideoPlayer from "components/room/windows/roomVideoPlayer";
import RoomTrivia from "components/room/windows/roomTrivia";

import "css/room-main.css";

import tokenImg from "img/token.png";

import keysImg from "img/welcome/keys.png";
import mouseImg from "img/welcome/mouse.png";

import toolsImg from "img/welcome/tools.svg";
import rewardsImg from "img/welcome/rewards.svg";
import experiencesImg from "img/welcome/experiences.svg";

const uiTour = require("data/uiTour.json");

class RoomMain extends Component {
   state = {
      showWorld: true,
      showWelcome: true,
      showInstruct: true,
      showTour: true,

      currentTourIndex: 0,

      percentLoaded: null,
   };

   features = [
      "accelerometer",
      // "autoplay",
      // "camera",
      // "fullscreen",
      // "geolocation",
      "gyroscope",
      "magnetometer",
      // "microphone",
      //"vr",
      "xr-spatial-tracking",
   ];

   allowFeatures = "";
   iframeSandbox = "allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox";

   tourPopupRef = null;
   tourtourMasterElId = null;
   tourAlign = null;

   view360IframeWindow = null;

   POPUP_WIDTH = 350;

   componentDidMount() {
      this.tourPopupRef = React.createRef();

      let allow = "";
      for (const feature of this.features) {
         allow += feature + ";";
      }
      this.allowFeatures = allow;
      // console.log("allow:", this.allowFeatures);

      window.addEventListener("resize", this.onWindowResize);

      const user = userService.getCurrentUser();
      if (user && user._id === "5f6adf499f58b61cd6a2e58a") {
         this.setState({ showWorld: true, showWelcome: false, showInstruct: false, showTour: false });
      }

      this.initMessageListener();

      // this.doFakeLoad();
   }

   componentDidUpdate() {
      this.onWindowResize();
   }

   componentWillUnmount() {
      this.stopTour();
   }

   // doFakeLoad = () => {
   //    //console.log("doFakeLoad()");

   //    let { percentLoaded } = this.state;
   //    percentLoaded += 5;
   //    //console.log("percentLoaded:", percentLoaded);

   //    if (percentLoaded > 100) {
   //       percentLoaded = null;
   //    } else {
   //       setTimeout(this.doFakeLoad, 100);
   //    }

   //    this.setPercentLoaded(percentLoaded);
   // };

   setPercentLoaded = (percentLoaded) => {
      //console.log("setPercentLoaded() percentLoaded:", percentLoaded);
      if (isNaN(percentLoaded)) return console.log("Percent is not a number.");
      if (percentLoaded < 0) percentLoaded = 0;
      if (percentLoaded > 100) percentLoaded = 100;
      if (percentLoaded >= 100) setTimeout(this.setPercentLoaded, 100, null);
      this.setState({ percentLoaded });
   };

   onWindowResize = () => {
      if (!this.tourMasterElId || !this.tourPopupRef.current) return;

      const tourEl = document.getElementById(this.tourMasterElId);
      if (!tourEl) return null;

      const rect = tourEl.getBoundingClientRect();
      // console.log("rect:", rect);

      let left, top;

      if (this.tourAlign === "bottom") {
         left = Math.round(10 - rect.x + rect.width / 2);
         top = Math.round(30 + rect.y + rect.height);
      } else {
         left = Math.round(rect.x - this.POPUP_WIDTH - 30);
         top = Math.round(rect.y + rect.height / 2 - 50);
      }

      this.tourPopupRef.current.style.left = left + "px";
      this.tourPopupRef.current.style.top = top + "px";
      this.tourPopupRef.current.style.width = this.POPUP_WIDTH + "px";
   };

   onLoadView360 = () => {
      //console.log("onLoadView360()");

      this.view360IframeWindow = window.frames["view360-iframe"];

      this.updateView360Model();
   };

   initMessageListener() {
      //console.log("initMessageListener()");

      const validTypes = ["content-load-progress"];

      window.addEventListener("message", (event) => {
         //console.log(event);

         const eventData = event.data;
         //console.log("eventData:,", eventData);

         if (validTypes.includes(eventData.type)) {
            // const originHost = new URL(event.origin).host;
            // if (eventData.type && originHost !== "xureal.com" && originHost !== window.location.host) {
            //    console.info(window.location.host, "not accepting messages from:", originHost);
            //    return;
            // }

            //console.log("eventData:,", eventData);

            if (eventData.type === "content-load-progress") {
               const { percentLoaded } = eventData;
               //console.log("content-load-progress", percentLoaded);
               this.setPercentLoaded(percentLoaded);
            }
         }
      });
   }

   updateView360Model = () => {
      //console.log("updateView360Model()");

      const { userContent } = this.props;
      const { contentId } = userContent;

      this.currentView360ContentId = contentId;

      if (this.view360IframeWindow && this.view360IframeWindow.contentWindow && typeof this.view360IframeWindow.contentWindow.showModelById !== "undefined") {
         this.view360IframeWindow.contentWindow.showModelById(contentId, true);
      }

      if (this.state.percentLoaded === null) {
         setTimeout(this.setPercentLoaded, 0, 0);
      }
   };

   hideWelcome = () => {
      setTimeout(this.startTour, 500);

      this.setState({ showWelcome: false });
   };

   instructClick = () => {
      this.setState({ showInstruct: false });
   };

   startTour = () => {
      this.setState({ showTour: true });
   };

   stopTour = () => {
      this.tourPopupRef = null;
      this.tourMasterElId = null;

      window.removeEventListener("resize", this.onWindowResize);

      this.setState({ showTour: false });
   };

   nextTour = () => {
      //console.log("nextTour()");

      const { currentTourIndex } = this.state;

      const newIndex = currentTourIndex + 1;
      const showTour = newIndex < uiTour.length;

      this.setState({ currentTourIndex: newIndex, showTour });
   };

   view360Close = () => {
      //console.log("view360Close()");
      const { setUserContent } = this.props;
      setUserContent({ contentType: null });
   };

   render() {
      const { showWorld, showWelcome, showInstruct, showTour, percentLoaded } = this.state;
      const { userContent, setUserContent, roomData, userData, usersList } = this.props;
      const { contentType, contentId } = userContent;

      return (
         <div className="room-content">
            {showWorld && this.renderWorld()}
            {contentType === "view360" && contentId && this.renderView360()}
            {contentType === "video" && contentId && <RoomVideoPlayer contentId={contentId} setUserContent={setUserContent} />}
            {contentType === "trivia" && contentId && <RoomTrivia contentId={contentId} setUserContent={setUserContent} roomData={roomData} userData={userData} usersList={usersList} />}

            {this.renderScreenShare()}

            {!showWelcome && showInstruct && this.renderInstruct()}
            {showTour && this.renderTour()}
            {showWelcome && this.renderWelcome()}
            {contentType && percentLoaded !== null && <RoomLoader percentLoaded={percentLoaded} />}

            {!showWelcome && this.renderTokens()}
         </div>
      );
   }

   renderWorld() {
      const { roomData, onLoadIframe } = this.props;
      const { meetingContentUrl } = roomData;
      if (!meetingContentUrl) return null;
      //console.log("meetingContentUrl:", meetingContentUrl);

      return <Iframe url={meetingContentUrl} onLoad={onLoadIframe} width="100%" height="100%" id="room-content-iframe" className="room-content-iframe" sandbox={this.iframeSandbox} allow={this.allowFeatures} allowvr="yes" />;
   }

   renderView360() {
      const view360Url = "/view360/index.html";
      //console.log("view360Url:", view360Url);

      const { contentId } = this.props.userContent;

      if (contentId !== this.currentView360ContentId) {
         this.updateView360Model();
      }

      return (
         <div className="view360">
            <Iframe url={view360Url} onLoad={this.onLoadView360} width="100%" height="100%" id="view360-iframe" className="view360-iframe" sandbox={this.iframeSandbox} allow={this.allowFeatures} allowvr="yes" />
            <button className="side-close view360-close" onClick={this.view360Close}>
               <FontAwesomeIcon icon="times" className="icon" />
            </button>
         </div>
      );
   }

   renderScreenShare() {
      const { desktopMediaTrack, screenShareRef } = this.props;

      let screenShareClasses = "screenshare";
      if (!desktopMediaTrack) screenShareClasses += " hidden";

      return (
         <div className={screenShareClasses}>
            <video autoPlay={true} className="screenshare-video" ref={screenShareRef}></video>
         </div>
      );
   }

   renderWelcome() {
      return (
         <div className="xu-modal main-welcome">
            <div className="xu-modal-box fade-in">
               <div className="xu-modal-title">Welcome to a new kind of virtual experience.</div>
               <div className="xu-modal-subtitle">One pane of glass for a hybrid, scalable and immersive group experience.</div>

               <div className="features img-fit-width">
                  <div className="feature">
                     <div className="image">
                        <img src={toolsImg} alt="Communication Tools" />
                     </div>
                     <div className="desc">Real-time communication tools for everyone.</div>
                  </div>

                  <div className="feature">
                     <div className="image">
                        <img src={rewardsImg} alt="Rewards" />
                     </div>
                     <div className="desc">Earn rewards by interacting with content.</div>
                  </div>

                  <div className="feature">
                     <div className="image">
                        <img src={experiencesImg} alt="Engaging Experiences" />
                     </div>
                     <div className="desc">Turn your existing content into highly engaging experiences.</div>
                  </div>
               </div>

               <button className="btn btn-special" onClick={this.hideWelcome}>
                  Continue
               </button>
            </div>
         </div>
      );
   }

   renderInstruct() {
      return (
         <div className="main-instruct" onClick={this.instructClick}>
            <div className="box img-fit-height">
               <div className="instruct">
                  <div className="text">
                     <div className="title">Move Around</div>
                     <div className="desc">Use keyboard arrows.</div>
                  </div>
                  <div className="image">
                     <img src={keysImg} alt="Arrow Keys" />
                  </div>
               </div>

               <div className="instruct">
                  <div className="text">
                     <div className="title">Look Around</div>
                     <div className="desc">
                        Use mouse to
                        <br />
                        point and click.
                     </div>
                  </div>
                  <div className="image">
                     <img src={mouseImg} alt="Mouse" />
                  </div>
               </div>

               <button className="side-close">
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>
         </div>
      );
   }

   renderTokens() {
      const { userTokens } = this.props.userData;

      return (
         <div id="tour-tokens" className="main-tokens img-fit-height" onClick={this.tokensClick}>
            <div className="icon">
               <img src={tokenImg} alt="XR Token" />
            </div>
            <div className="text">{userTokens}</div>
         </div>
      );
   }

   renderTour() {
      const { currentTourIndex } = this.state;

      const tourData = uiTour[currentTourIndex];
      const { title, body, align, id } = tourData;

      const isLastItem = currentTourIndex >= uiTour.length - 1;
      const btnText = isLastItem ? "Finish" : "Next";

      let classes = "arrow ";
      classes += align ? align : "left";

      this.tourMasterElId = "tour-" + id;
      // console.log("elId:", elId);

      this.tourAlign = align;

      const arrowLeft = align === "bottom" ? 30 : this.POPUP_WIDTH;
      const arrowStyles = { left: arrowLeft + "px" };
      //console.log("align:", align, "arrowStyles:", arrowStyles);

      return (
         <div className="tour-popup" ref={this.tourPopupRef} onClick={this.tokensClick}>
            <div style={arrowStyles} className={classes}></div>
            <div className="title">{title}</div>
            <div className="body">{body}</div>

            <div className="buttons">
               {!isLastItem && (
                  <button className="btn btn-link" onClick={this.stopTour}>
                     Skip Tour
                  </button>
               )}

               <button className="btn btn-special ml-auto" onClick={this.nextTour}>
                  {btnText}
               </button>
            </div>
         </div>
      );
   }
}

export default RoomMain;
