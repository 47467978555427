import React from "react";

import Form from "components/form/form";

import logoImg from "img/logos/xureal_horizontal_color_white.svg";

class RoomJoin extends Form {
   state = {
      formData: {
         userFirstName: "",
         email: "",
      },
      isValid: {
         userFirstName: null,
         email: null,
      },
      errors: {},
   };

   componentDidMount() {
      const { formData } = this.props;
      //console.log("formData:", formData);

      this.setState({ formData });
   }

   doSubmit = async () => {
      const { joinMeeting } = this.props;
      const { userFirstName, email } = this.state.formData;

      joinMeeting(userFirstName, email);
   };

   render() {
      return (
         <div className="xu-modal">
            <div className="xu-modal-box">
               <div className="join-logo">
                  <img src={logoImg} alt="Xureal Logo" width="100%" />
               </div>

               <div className="xu-modal-title">Hello and welcome</div>
               <div className="xu-modal-subtitle">Please enter your information below to access the virtual experience.</div>

               <div className="join-form">
                  <form onSubmit={this.handleSubmit}>
                     {this.renderInput("userFirstName", "First Name", "given-name")}
                     {this.renderInput("email", "Email", "email")}

                     <div className="join-btn">{this.renderButton("Join Event", null, "special")}</div>
                  </form>
               </div>
            </div>
         </div>
      );
   }
}

export default RoomJoin;
