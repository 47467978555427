import http from "services/httpService";

import msgService from "services/msgService";

//  const quizEndpoint = "/api/quiz";

async function getTrivia(quizId) {
   //console.log("getTrivia() quizId:", quizId);

   // const quizUrl = quizEndpoint + "/" + quizId;
   const quizUrl = "trivia/" + quizId + ".json";

   try {
      const { data } = await http.get(quizUrl);
      // console.log("data:", data);

      return data;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "quiz_data_error");
   }
}

export default {
   getTrivia,
};
