import Joi from "joi-browser";

export function joiSchema(name) {
   //console.log("joiSchema(" + name + ")");

   const label = getLabel(name);

   switch (name) {
      case "name":
      case "form":
      case "userFirstName":
      case "userLastName":
         return Joi.string().required().min(1).max(30).label(label);

      case "email":
      case "newEmail":
         return Joi.string().required().email().label(label);

      case "password":
      case "passwordNew":
      case "passwordOld":
         return Joi.string().required().min(8).label(label);

      case "userTimezone":
      case "meetingTimezone":
         // [TODO] - selected value
         return Joi.string().optional().label(label);

      /* MEETING FORM */

      case "meetingId":
         return Joi.string().label(label);

      case "meetingPlatform":
         // [TODO] - selected value
         return Joi.string().optional().label(label);

      case "meetingName":
         return Joi.string().required().min(2).max(30).label(label);

      case "meetingDateTime":
         return Joi.optional().label(label);

      case "meetingDuration":
         return Joi.number().label(label);

      case "meetingContentUrl":
         return Joi.string().required().uri().label(label);

      case "meetingShowChat":
      case "meetingShowMap":
         return Joi.boolean().label(label);

      // case "meetingPasscode":
      //    return Joi.string()
      //       .regex(new RegExp(/([a-zA-Z0-9@_*!-])+/))
      //       .allow("")
      //       .optional()
      //       .max(10)
      //       .label(label);

      case "updatedParticipants":
         return Joi.array().label(label);

      /* INVITE EMAIL FORM */

      case "inviteSubject":
      case "inviteMessage1":
         return Joi.string().required().min(2).max(50).label(label);

      case "inviteMessage2":
         return Joi.string().required().min(2).max(100).label(label);

      case "inviteBkgrndColor":
      case "inviteTextColor":
      case "inviteBtnColor":
      case "inviteBtnTextColor":
         return Joi.string()
            .regex(/^#[A-Fa-f0-9]{6}/)
            .error(() => {
               return {
                  message: label + " should be a HEX color code.",
               };
            });

      /* VIDEO CHAT */

      case "message":
         return Joi.string().required().label(label);

      case "audioInputDeviceId":
      case "audioOutputDeviceId":
      case "videoInputDeviceId":
         return Joi.label(label);

      /* REDEEM FORM */

      case "userAddress1":
      case "userCity":
      case "userState":
      case "userZipCode":
         return Joi.string().required().label(label);

      case "userAddress2":
         return Joi.string().allow("").optional().label(label);

      /* DEFAULT - SCHEMA NOT FOUND */

      default:
         console.warn("Schema " + name + " not found!");
         return Joi.string();
   }
}

function getLabel(name) {
   //console.log("getLabel(" + name + ")");

   switch (name) {
      case "form":
         return "Account Form";

      case "userFirstName":
         return "First Name";

      case "userLastName":
         return "Last Name";

      case "newEmail":
         return "New Email";

      case "email":
         return "Email";

      case "password":
         return "Password";

      case "passwordNew":
         return "New Password";

      case "passwordOld":
         return "Old Password";

      case "userTimezone":
         return "Timezone";

      /* MEETING FORM */

      case "meetingId":
         return "Meeting ID";

      case "meetingPlatform":
         return "Video Service Provider";

      case "meetingName":
         return "Meeting Name";

      case "meetingDateTime":
         return "Meeting Date & Time";

      case "meetingTimezone":
         return "Meeting Timezone";

      case "meetingDuration":
         return "Duration";

      case "meetingContentUrl":
         return "Meeting Content URL";

      case "meetingShowChat":
         return "Meeting Chat Feature";

      case "meetingShowMap":
         return "Meeting Map Feature";

      // case "meetingPasscode":
      //    return "Passcode";

      case "updatedParticipants":
         return "Participants";

      /* INVITE EMAIL FORM */

      case "inviteSubject":
         return "Email Subject";

      case "inviteMessage1":
         return "Email Message Title";

      case "inviteMessage2":
         return "Email Message";

      case "inviteBkgrndColor":
         return "Email Background Color";

      case "inviteTextColor":
         return "Email Text Color";

      case "inviteBtnColor":
         return "Email Join Button Background Color";

      case "inviteBtnTextColor":
         return "Email Join Button Text Color";

      /* VIDEO CHAT */

      case "message":
         return "Message";

      case "audioInputDeviceId":
         return "Microphone";

      case "audioOutputDeviceId":
         return "Speakers";

      case "videoInputDeviceId":
         return "Camera";

      /* REDEEM FORM */

      case "userAddress1":
         return "Address 1";

      case "userAddress2":
         return "Address 2";

      case "userCity":
         return "City";

      case "userState":
         return "State";

      case "userZipCode":
         return "ZipCode";

      /* DEFAULT - LABEL NOT FOUND */

      default:
         console.warn("Label " + name + " not found!");
         return name;
   }
}
