import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MultiEmail from "components/common/multiEmail";
import Topper from "components/meetings/topper";

import meetingService from "services/meetingService";
import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-meetings.css";

class MeetingParticipants extends Component {
   state = {
      userEmail: "",
      addEmails: [],
      meetingParticipants: [],
      selectedLength: 0,
      selectAllChecked: false,
      isLoaded: false,
   };

   async componentDidMount() {
      const meetingId = this.props.match.params.meetingId;
      //console.log("meetingId:", meetingId);

      const { email: userEmail } = userService.getCurrentUser();
      //console.log("userEmail:", userEmail);

      const meetingParticipants = await meetingService.getParticipants(meetingId);
      //console.log("meetingParticipants:", meetingParticipants);

      for (let i in meetingParticipants) {
         meetingParticipants[i].selected = false;
      }

      this.setState({ userEmail, meetingParticipants, isLoaded: true });
   }

   participantsAdded = (addEmails) => {
      this.setState({ addEmails });
   };

   handleAddClick = async (e) => {
      //console.log("handleAddClick()");

      const { addEmails, meetingParticipants } = this.state;

      const invalidEmails = [];
      for (const participant of meetingParticipants) {
         invalidEmails.push(participant.email);
      }
      //const user = userService.getCurrentUser();
      //invalidEmails.push(userEmail);
      //console.log("invalidEmails:", invalidEmails);

      const newParticipants = [...meetingParticipants];
      const newEmails = [];

      for (const email of addEmails) {
         if (invalidEmails.includes(email)) continue;
         newParticipants.push({ email, selected: false });
         newEmails.push(email);
         //console.log("email:", email);
      }

      this.setState({ meetingParticipants: newParticipants, addEmails: [] });
   };

   handleSelectAllToggle = () => {
      //console.log("handleSelectAllToggle()");

      const meetingParticipants = [...this.state.meetingParticipants];
      const { selectAllChecked } = this.state;

      let selectedLength = 0;
      for (let i in meetingParticipants) {
         meetingParticipants[i].selected = !selectAllChecked;
         if (meetingParticipants[i].selected) selectedLength++;
      }
      //console.log("selectedLength:", selectedLength);

      this.setState({ meetingParticipants, selectedLength, selectAllChecked: !selectAllChecked });
   };

   handleOptionClick = (participant) => {
      //console.log("handleOptionClick() participant:", participant);

      const meetingParticipants = [...this.state.meetingParticipants];

      const optionIndex = meetingParticipants.findIndex((obj) => obj.email === participant.email);
      //console.log("optionIndex:", optionIndex);

      meetingParticipants[optionIndex].selected = !participant.selected;

      let selectedLength = 0;
      for (let i in meetingParticipants) {
         if (meetingParticipants[i].selected) selectedLength++;
      }
      //console.log("selectedLength:", selectedLength);

      this.setState({ meetingParticipants, selectedLength, selectAllChecked: false });
   };

   handleRemoveClick = async (e) => {
      //console.log("handleRemoveClick()");

      e.preventDefault();

      const { userEmail } = this.state;

      let newParticipants = [...this.state.meetingParticipants];

      for (const participant of newParticipants) {
         if (participant.selected) {
            const email = participant.email;
            if (email !== userEmail) {
               //deleteEmails.push(email);
               newParticipants = newParticipants.filter((s) => s.email !== email);
            } else {
               msgService.showMsgById("meeting_remove_self");
            }
         }

         participant.selected = false;
      }
      //console.log("newParticipants", newParticipants);

      this.setState({ meetingParticipants: newParticipants, selectedLength: 0, selectAllChecked: false });
   };

   handleResendClick = async (e) => {
      //console.log("handleResendClick()");

      e.preventDefault();

      let newParticipants = [...this.state.meetingParticipants];

      for (const participant of newParticipants) {
         if (participant.selected) {
            participant.status = 0;
         }

         participant.selected = false;
      }
      //console.log("newParticipants", newParticipants);

      this.setState({ meetingParticipants: newParticipants, selectedLength: 0, selectAllChecked: false });
   };

   doSubmit = async () => {
      //console.log("doSubmit()");

      const meetingId = this.props.match.params.meetingId;
      //console.log("meetingId:", meetingId);

      const meetingParticipants = [...this.state.meetingParticipants];
      //console.log("meetingParticipants:", meetingParticipants);

      const response = await meetingService.saveParticipants(meetingId, meetingParticipants);
      //console.log("response:", response);

      if (response && response.status === 200) {
         //const newParticipants = response.data;
         //this.setState({ meetingParticipants: newParticipants, selectedLength: 0 });

         //window.location = "/meetings";
         this.props.history.push("/meetings");
      } else {
         msgService.responseErrorById(response, "meeting_update_error");
      }
   };

   render() {
      const { meetingParticipants, addEmails, selectedLength, isLoaded } = this.state;
      //console.log("addEmails:", addEmails);

      const addBtnDisabled = addEmails.length > 0 ? false : true;
      const removeBtnDisabled = selectedLength > 0 ? false : true;

      const invalidEmails = [];

      for (let i in meetingParticipants) {
         invalidEmails.push(meetingParticipants[i].email);
      }

      //const user = userService.getCurrentUser();
      //invalidEmails.push(user.email);
      //console.log("invalidEmails:", invalidEmails);

      const meetingId = this.props.match.params.meetingId;
      const backLink = "/meetings/invite/" + meetingId;

      return (
         <div className="page">
            <Topper currentPage="participants" />
            <div className="page-wide">
               <p className="page-eyebrow">SCHEDULE A VIRTUAL MEETING</p>

               <p className="page-title">Meeting Participants</p>

               <p className="page-desc">Add or remove meeting attendees. Invite emails will be sent to all participants.</p>

               {isLoaded && (
                  <Fragment>
                     <div className="row">
                        <div className="col">
                           <div className="page-subtitle">Add Participants</div>
                           <p className="small">Add participants by email address. Separate emails using a comma.</p>
                           <MultiEmail emails={addEmails} invalidEmails={invalidEmails} onUpdate={(addEmails) => this.participantsAdded(addEmails)} />

                           <button onClick={(e) => this.handleAddClick(e)} className="btn btn-primary btn-sm mt-20" disabled={addBtnDisabled}>
                              <FontAwesomeIcon icon="plus-circle" className="btn-icon" />
                              Add
                           </button>
                        </div>
                     </div>

                     {meetingParticipants.length > 0 && (
                        <div className="row mt-2">
                           <div className="col">
                              <div className="page-subtitle">Meeting Participants</div>

                              {this.renderParticipantsBox()}

                              <button onClick={(e) => this.handleRemoveClick(e)} className="btn btn-sm btn-danger" disabled={removeBtnDisabled}>
                                 <FontAwesomeIcon icon="minus-circle" className="btn-icon" />
                                 Remove
                              </button>
                              <button onClick={(e) => this.handleResendClick(e)} className="btn btn-sm btn-primary ml-20" disabled={removeBtnDisabled}>
                                 Resend Invites
                              </button>
                           </div>
                        </div>
                     )}

                     <div className="form-row form-btns">
                        <div className="form-col">
                           <Link to={backLink} className="btn btn-secondary">
                              Back
                           </Link>
                        </div>

                        <div className="form-col align-right">
                           <button className="btn btn-primary" onClick={this.doSubmit}>
                              Save and Complete
                           </button>
                        </div>
                     </div>
                  </Fragment>
               )}
            </div>
         </div>
      );
   }

   renderParticipantsBox = () => {
      //console.log("renderParticipantsBox()");

      const { selectAllChecked } = this.state;

      let classes = "participants-checkbox";
      if (selectAllChecked) classes += " checked";

      return (
         <div className="participants-box">
            <div className="participants-head">
               <div className="participants-head-item participants-select">
                  <button className={classes} onClick={this.handleSelectAllToggle}></button>
               </div>
               <div className="participants-head-item participants-email text-muted">Email</div>
               <div className="participants-head-item participants-status text-muted">Invite Status</div>
            </div>

            {this.renderParticipants()}
         </div>
      );
   };

   renderParticipants = () => {
      //console.log("renderParticipants()");

      const statusText = ["Not sent", "Sent", "Rejected", "Send update"];

      const { userEmail, meetingParticipants } = this.state;

      if (!meetingParticipants || meetingParticipants.length === 0) return;

      // sort participant emails alphabetically
      meetingParticipants.sort((a, b) => (a.email > b.email ? 1 : -1));

      // put owner email on top
      meetingParticipants.sort((a, b) => (a.email === userEmail ? -1 : 1));

      const html = [];

      for (const participant of meetingParticipants) {
         const optionEmail = participant.email;
         const key = optionEmail.replace(/[|&;$%@"<>()+,]/g, "");

         const optionSelected = participant.selected;
         //console.log("optionSelected:", optionSelected);

         const optionStatus = participant.status ? participant.status : 0;
         const optionStatusText = statusText[optionStatus];
         //console.log(optionEmail, "optionStatus:", optionStatus);

         let classes = "participants-checkbox";
         if (optionSelected) classes += " checked";

         html.push(
            <div className="participants-row" key={key}>
               <div className="participants-item participants-select">
                  <button className={classes} onClick={() => this.handleOptionClick(participant)}></button>
               </div>
               <div className="participants-item participants-email">{optionEmail}</div>
               <div className="participants-item participants-status">{optionStatusText}</div>
            </div>
         );
      }

      return html;
   };
}

export default MeetingParticipants;
