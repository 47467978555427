import React, { Component } from "react";

class DropdownMenu extends Component {
   state = {
      menuVisible: false,
   };

   constructor(props) {
      super(props);

      this.wrapperRef = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
   }

   componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
   }

   componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
   }

   handleClickOutside = (event) => {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
         this.handleClose();
      }
   };

   handleClose = () => {
      this.setState({ menuVisible: false });
   };

   onToggleMenu = (e) => {
      //console.log("onToggleMenu()");

      const { menuVisible } = this.state;

      this.setState({ menuVisible: !menuVisible });

      e.preventDefault();
      return false;
   };

   onSelectOption = (event) => {
      //console.log("onSelectOption()");

      this.setState({ menuVisible: false });

      const value = event.currentTarget.value;
      //console.log("value:", value);

      const { name, onChange } = this.props;
      const changeEvent = { currentTarget: { name, value: value } };
      //console.log("changeEvent:", changeEvent);
      onChange(changeEvent);

      event.preventDefault();
      return false;
   };

   render() {
      const { menuVisible } = this.state;
      const { name, label, options, selected, error } = this.props;

      let selectedValue = 0;
      if (selected) {
         selectedValue = options.filter((option) => {
            return option["value"] === selected;
         })[0].text;
      }
      console.log("selected:", selected, "selectedValue:", selectedValue);

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>

            <div id={name} className="video-dropdown" ref={this.wrapperRef}>
               <button type="button" className="group-input xu-select-arrow video-dropdown-display" onClick={this.onToggleMenu}>
                  {selectedValue}
               </button>

               {menuVisible && <div className="video-dropdown-menu">{this.renderOptions(options, selected)}</div>}
            </div>

            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }

   renderOptions = (options, selected) => {
      console.log("renderOptions() selected:", selected);

      let html = [];

      for (const option of options) {
         const key = option.key ? option.value + "-" + option.key : option.value;

         // let status = option.disabled ? "(disabled) " : "";
         // status += option.connected ? "(connected)" : "(not connected)";

         let classes = "group-input video-dropdown-menu-option";
         if (option.disabled || !option.connected) classes += " disabled";

         if (option.value === selected) classes += " selected";

         html.push(
            <button key={key} className={classes} onClick={this.onSelectOption} value={option.value}>
               <div className="float-left">{option.text}</div>
               {/* <div className="float-right text-muted">{status}</div> */}
            </button>
         );
      }

      return html;
   };
}

export default DropdownMenu;
