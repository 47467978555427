import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import zxcvbn from "zxcvbn";

class PasswordNew extends Component {
   state = {
      type: "password",
      password: "",
      score: 0,
   };

   handleToggle() {
      const { type } = this.state;

      const newType = type === "text" ? "password" : "text";

      this.setState({ type: newType });
   }

   validatePassword(password) {
      //console.log("validatePassword:", password);

      let score = 0;
      if (password.length >= 8) {
         score = zxcvbn(password).score + 1;
      }
      //console.log("score:", score);

      this.setState({ score, password });

      return score;
   }

   handleChange(e) {
      //console.log("handleChange()");
      const password = e.currentTarget.value;
      e.currentTarget.score = this.validatePassword(password);
      this.props.onChange(e);
   }

   handleBlur(e) {
      //console.log("handleBlur()");
      const password = e.currentTarget.value;
      e.currentTarget.score = this.validatePassword(password);
      this.props.onBlur(e);
   }

   render() {
      const { name, label, value, isValid, error, onFocus } = this.props;
      const { type, password } = this.state;
      let { score } = this.state;

      //console.log("value:", value, "password:", password, "isValid:", isValid, "error:", error);

      if (value !== password) {
         score = 0;
      }

      const icon = type === "text" ? "eye-slash" : "eye";

      let classes = "xu-auto group-input";
      if (isValid === true) classes += " is-valid";
      if (isValid === false && error) classes += " is-invalid";

      const meterStyles = { width: "100%", height: "10px" };

      const bkgrndColorAR = ["var(--account-header)", "#EA4335", "#F3801D", "#FBBC05", "#98B22C", "#00f06f"];

      // --red: #cc0000;
      // --orange: #fd7e14;
      // --yellow: #ff8800;
      // --green: #77b300;
      // const bkgrndColorAR = [
      //    "rgba(0, 0, 0, 0.2)",
      //    "var(--red)",
      //    "var(--orange)",
      //    "var(--yellow)",
      //    "#98B22C",
      //    "var(--green)",
      // ];

      const meterBoxStyles = [];

      for (let a = 0; a <= 4; a++) {
         const styles = {
            width: "20%",
            height: "100%",
            backgroundColor: bkgrndColorAR[0],
         };

         if (a < score) {
            styles.backgroundColor = bkgrndColorAR[score];
         }

         if (a !== 4) {
            styles.borderRight = "1px";
            styles.borderRightStyle = "solid";
            styles.borderRightColor = "var(--input-border)";
            styles.boxSizing = "border-box";
         }

         meterBoxStyles.push(styles);
      }

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>

            <div className="group-info">Great passwords use upper and lower case characters, numbers, and symbols like !?#$%.</div>

            <div className="input-group">
               <input id={name} ref="inputRef" name={name} value={value} type={type} onFocus={onFocus} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.handleBlur(e)} className={classes} autoComplete="new-password" />
               <div
                  className="xu-auto group-input input-group-append btn"
                  onClick={() => {
                     this.handleToggle();
                  }}
               >
                  <span>
                     <FontAwesomeIcon icon={icon} className="mx-auto" />
                  </span>
               </div>
            </div>

            <div className="password-box row" style={meterStyles}>
               {meterBoxStyles.map((style, i) => (
                  <div key={"mbox" + i} className="col" style={style} />
               ))}
            </div>

            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }
}

export default PasswordNew;
