import React, { Fragment } from "react";

import Form from "components/form/form";

import jitsiService from "services/jitsiService";

class RoomXurealSettings extends Form {
   state = {
      deviceOptions: {
         audioinput: [],
         audiooutput: [],
         videoinput: [],
      },

      formData: {
         audioInputDeviceId: "",
         audioOutputDeviceId: "",
         videoInputDeviceId: "",
      },
      isValid: {
         audioInputDeviceId: null,
         audioOutputDeviceId: null,
         videoInputDeviceId: null,
      },
      errors: {},
   };

   JitsiMeetJS;

   componentDidMount() {
      window.JitsiMeetJS.mediaDevices.addEventListener(window.JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, this.onDeviceListChanged);

      this.updateDeviceOptions();
   }

   componentWillUnmount() {
      window.JitsiMeetJS.mediaDevices.removeEventListener(window.JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, this.onDeviceListChanged);
   }

   onDeviceListChanged = () => {
      //console.log("onDeviceListChanged()");

      this.updateDeviceOptions();
   };

   async updateDeviceOptions() {
      //console.log("updateDeviceOptions()");

      const { platformSettings: formData } = this.props;
      //console.log("formData:", formData);

      const mediaDevices = await jitsiService.getMediaDevices();
      //console.log("mediaDevices:", mediaDevices);

      const deviceOptions = {};
      deviceOptions["audioinput"] = jitsiService.getDeviceOptions(mediaDevices, "audioinput");
      deviceOptions["audiooutput"] = jitsiService.getDeviceOptions(mediaDevices, "audiooutput");
      deviceOptions["videoinput"] = jitsiService.getDeviceOptions(mediaDevices, "videoinput");
      //console.log("deviceOptions:", deviceOptions);

      formData.audioInputDeviceId = window.JitsiMeetJS.mediaDevices.getAudioOutputDevice();
      //console.log("formData:", formData);

      this.setState({ deviceOptions });
   }

   handleSubmit = async (e) => {
      e.preventDefault();

      const { formData } = this.state;
      const { submitHandler } = this.props;

      submitHandler(formData);
   };

   render() {
      const { deviceOptions } = this.state;
      const { audioInputDeviceId, audioOutputDeviceId, videoInputDeviceId } = this.state.formData;

      const audioInputClasses = deviceOptions["audioinput"].length > 0 ? "" : "disabled";
      const audioOutputClasses = deviceOptions["audiooutput"].length > 0 ? "" : "disabled";
      const videoInputClasses = deviceOptions["videoinput"].length > 0 ? "" : "disabled";

      return (
         <Fragment>
            <div className="video-settings">
               <div className="video-settings-box">
                  <div className="video-settings-txt1">Device Settings</div>

                  <div className="video-settings-form">
                     <form onSubmit={this.handleSubmit}>
                        <div className={audioInputClasses}>{this.renderSelect("audioInputDeviceId", "Microphone", deviceOptions["audioinput"], audioInputDeviceId)}</div>
                        <div className={audioOutputClasses}>{this.renderSelect("audioOutputDeviceId", "Speakers", deviceOptions["audiooutput"], audioOutputDeviceId)}</div>
                        <div className={videoInputClasses}>{this.renderSelect("videoInputDeviceId", "Cameara", deviceOptions["videoinput"], videoInputDeviceId)}</div>

                        <div className="video-settings-btn">{this.renderButton("Save", null, "special")}</div>
                     </form>
                  </div>
               </div>
            </div>
         </Fragment>
      );
   }
}

export default RoomXurealSettings;
