import React, { Component } from "react";

class Select extends Component {
   render() {
      const { name, label, options, selected, error, onChange } = this.props;

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>
            <select id={name} name={name} value={selected} options={options} onChange={onChange} className="xu-select xu-select-arrow group-input">
               {this.renderOptions(options)}
            </select>
            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }

   renderOptions = (options) => {
      let optionsHtml = [];

      let key;
      for (let i = 0; i < options.length; i++) {
         key = options[i].key ? options[i].value + "-" + options[i].key : options[i].value;
         optionsHtml.push(this.renderOption(key, options[i].value, options[i].text));
      }

      return optionsHtml;
   };

   renderOption = (key, value, text) => {
      return (
         <option key={key} value={value} className="select-selected" autoComplete="off">
            {text}
         </option>
      );
   };
}

export default Select;
