import React, { Fragment } from "react";
import Joi from "joi-browser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SideNav from "components/nav/sideNav";
import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-account.css";

class AccountForm extends Form {
   navItems = [
      { name: "Basic Info", icon: "address-card", value: "/user/account/info" },
      { name: "Email Address", icon: "envelope", value: "/user/account/email" },
      { name: "Change Password", icon: "key", value: "/user/account/password" },
      { name: "Zoom", icon: "video", value: "/user/account/zoom" },
      { name: "Account Deletion", icon: "trash", value: "/user/account/delete" },
   ];

   state = {
      selectedNav: "",
      oldEmail: "",
      isEmailVerified: null,
      formData: {
         newEmail: "",
      },
      isValid: {
         newEmail: null,
      },
      errors: {},
      isLoaded: false,
   };

   updateSchema(oldEmail) {
      this.schema = {
         newEmail: Joi.string()
            .email()
            .invalid(oldEmail)
            .label("New Email")
            .error((errors) => {
               errors.forEach((err) => {
                  //console.log("type:", err.type);
                  switch (err.type) {
                     case "any.invalid":
                        err.message = "Already using this email address.";
                        break;
                     case "any.empty":
                        err.message = "";
                        break;
                     default:
                        break;
                  }
               });
               return errors;
            }),
      };
   }

   async componentDidMount() {
      const verifyToken = this.props.match.params.token;
      //console.log("verifyToken:", verifyToken);

      this.updateSchema(null);

      if (verifyToken) {
         try {
            await userService.verifyEmail(verifyToken);
            this.refreshPage();
         } catch (err) {
            msgService.responseErrorById(err, "verify_token_invalid");
         }
      }

      const user = await userService.getCurrentUser();
      if (!user) return;

      const { data } = await userService.getAccount(user._id);
      const oldEmail = data.email;
      const isEmailVerified = data.isEmailVerified;
      //console.log("isEmailVerified:", isEmailVerified);

      this.updateSchema(oldEmail);

      this.setState({ oldEmail, isEmailVerified, isLoaded: true });
   }

   refreshPage = () => {
      this.props.history.push("/user/account/email");
   };

   handleResend = async () => {
      //console.log("handleResend()");

      try {
         await userService.verifyEmailResend();
         msgService.showMsgById("verify_email_resent");
      } catch (err) {
         msgService.responseErrorById(err, "verify_email_error");
      }

      return false;
   };

   doSubmit = async () => {
      //console.log("doSubmit()");

      try {
         const user = userService.getCurrentUser();
         const { newEmail } = this.state.formData;

         const submitData = {
            _id: user._id,
            form: "email",
            email: newEmail,
         };

         const formData = {
            newEmail: "",
         };

         const isValid = {
            newEmail: null,
         };

         this.setState({ formData, isValid });
         this.updateSchema(newEmail);

         const res = await userService.saveAccount(submitData);
         msgService.responseSuccessById(res, "user_email_updated");

         this.setState({ isEmailVerified: false, oldEmail: newEmail });
      } catch (err) {
         msgService.responseErrorById(err, "user_email_error");
      }
   };

   render() {
      return (
         <div className="account-page">
            <div className="sidemenu">
               <SideNav items={this.navItems} />
            </div>

            <div className="account-content">
               <div className="page-narrow">{this.renderContent()}</div>
            </div>
         </div>
      );
   }

   renderContent() {
      const { isLoaded, oldEmail, isEmailVerified } = this.state;

      let verifiedClasses = "email-status-text";
      let verifiedText = "";
      let verifiedIcon = "";

      if (isEmailVerified !== null) {
         verifiedClasses += isEmailVerified ? " is-valid text-success" : " is-invalid text-danger";
         verifiedText = isEmailVerified ? "Email Verified" : "Email Not Verified";
         verifiedIcon = isEmailVerified ? "check-circle" : "times-circle";
      }

      return (
         <Fragment>
            <p className="page-eyebrow">MY ACCOUNT</p>

            <p className="page-title">Email Address</p>

            {isLoaded && (
               <Fragment>
                  <div className="form-group">
                     <label className="group-label" htmlFor="oldEmail">
                        Current Email Address
                     </label>
                     <input id="oldEmail" name="oldEmail" value={oldEmail} type="text" className="group-input" disabled />
                     {isEmailVerified === false && <div className="group-feedback">Check your email inbox or spam folder for instructions to verify your email.</div>}
                  </div>

                  {isEmailVerified !== null && (
                     <div className="email-status">
                        <div className={verifiedClasses}>
                           <FontAwesomeIcon icon={verifiedIcon} className="btn-icon fs-16 mr-10" />
                           <span className="fs-10">{verifiedText}</span>
                        </div>

                        {isEmailVerified === false && (
                           <button className="btn btn-primary" onClick={(e) => this.handleResend(e)}>
                              Resend Verification Email
                           </button>
                        )}
                     </div>
                  )}

                  <div className="page-subtitle">Change Email</div>
                  {this.renderInput("newEmail", "New Email Address", "email", false, true)}

                  <div className="account-btns">
                     <button className="btn btn-primary" disabled={this.validateAll()} onClick={(e) => this.handleSubmit(e)}>
                        Update Email
                     </button>
                  </div>
               </Fragment>
            )}
         </Fragment>
      );
   }
}

export default AccountForm;
