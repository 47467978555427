import http from "services/httpService";
import showMsg from "utils/showMsg";
import constants from "data/constants";

const uploadEndpoint = "/api/meetings/upload";

const UPLOAD_DELAY = 1000;

const uploadQueue = [];

//let updatedCallback = progressUpdate;
let currentFileData;

// use this to simulate a slow upload
// when showing a progress bar
const debugUpload = false;

// export function setUpdatedCallback(callback) {
//    updatedCallback = callback;
// }

export function progressUpdate(fileData) {
   // dummy progress callback
   console.log("progress:", fileData.progress);
}

export function uploadFiles(files) {
   //console.log("uploadFiles() files:", files);

   if (files && files.length > 0) {
      files = [...files];

      files.forEach((fileData) => {
         processFile(fileData);
      });
   }

   setTimeout(nextQueue, UPLOAD_DELAY);
}

export function uploadFile(fileData) {
   //console.log("uploadFile() fileData:", fileData);

   processFile(fileData);

   setTimeout(nextQueue, UPLOAD_DELAY);
}

export function processFile(fileData) {
   //console.log("processFile() fileData:", fileData);

   //console.log("fileBytes:", fileData.size);
   if (fileData.size > constants.UPLOAD_MAX_BYTES) return showMsg("upload_invalid_filesize");

   //console.log("fileType:", fileData.type);
   if (!constants.UPLOAD_FILETYPES.includes(fileData.type)) return showMsg("upload_invalid_filetype");

   fileData.progress = 0;
   //console.log("fileData:", fileData);

   uploadQueue.push(fileData);

   //updatedCallback(fileData);
   fileData.progressCallback(fileData);
}

function nextQueue() {
   //console.log("nextQueue()");

   if (!currentFileData && uploadQueue.length > 0) {
      currentFileData = uploadQueue.shift();
      startUpload(currentFileData);
   }
}

async function startUpload(fileData) {
   //console.log("startUpload() fileData:", fileData);

   try {
      if (debugUpload === true) {
         handleUploadProgress();
      } else {
         const options = {
            onUploadProgress: handleUploadProgress,
            headers: {
               "Content-Type": "multipart/form-data",
            },
         };

         const formData = new FormData();
         formData.append("fileData", fileData);
         formData.append("meetingId", fileData.meetingId);
         formData.append("filePurpose", fileData.filePurpose);

         const { data } = await http.post(uploadEndpoint, formData, options);
         //console.log("data:", data);

         if (data) fileData.inviteImgs = data;

         //updatedCallback(fileData);
         fileData.progressCallback(fileData);
      }
   } catch (err) {
      if (err.response && err.response.status !== 200) {
         showMsg(err, "upload_error");
      }
   }
}

function handleUploadProgress(progressEvent) {
   if (debugUpload === true) {
      currentFileData.progress += 5;
   } else {
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      currentFileData.progress = progress;
   }
   //console.log("Upload Progress:", currentFileData.progress);

   //updatedCallback(currentFileData);
   currentFileData.progressCallback(currentFileData);

   if (currentFileData.progress >= 100) {
      currentFileData = null;
      setTimeout(nextQueue, UPLOAD_DELAY);
   } else {
      if (debugUpload === true) {
         setTimeout(handleUploadProgress, 100);
      }
   }
}

// function showError(fileData, errorCode) {
//    //console.log("showError() fileData:", fileData, "errorCode:", errorCode);

//    // [TODO] - fix error messages

//    showMsg(errorCode);

//    updatedCallback(fileData);

//    //deletedCallback(fileData);
// }

export default {
   //setUpdatedCallback,
   uploadFiles,
   uploadFile,
};
