import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal } from "react-bootstrap";

class ModalPopup extends Component {
   //state = {
   //show: true,
   //};

   //componentDidUpdate() {
   //console.log("componentDidUpdate()");
   //}

   //componentWillUnmount() {
   //console.log("componentWillUnmount()");
   //}

   handleClick(callFunc) {
      //console.log("handleClick(" + callFunc + ")");

      //this.setShow(false);

      if (callFunc) callFunc();

      const { modalData } = this.props;
      if (modalData.onClose) modalData.onClose();
   }

   // handleShow = () => {
   //    this.setShow(true);
   // };

   // setShow = (show) => {
   //    this.setState({ show });
   // };

   render() {
      const { modalData } = this.props;

      if (!modalData.show) return null;

      if (!modalData.buttons) {
         modalData.buttons = [
            {
               text: "Close",
               type: "primary",
               //icon: "times",
            },
         ];
      }

      return (
         <Modal show={modalData.show} onHide={modalData.onClose}>
            <Modal.Header>
               <Modal.Title>{modalData.title}</Modal.Title>

               {modalData.showClose && (
                  <button type="button" className="close" onClick={(e) => this.handleClick(modalData.onClose)}>
                     <span>&times;</span>
                  </button>
               )}
            </Modal.Header>

            <Modal.Body>{modalData.text}</Modal.Body>

            <Modal.Footer>
               {modalData.buttons.map((button, index) => (
                  <button
                     type="button"
                     className={"btn btn-" + button.type}
                     key={"modal_btn_" + index}
                     onClick={(e) => this.handleClick(button.onClick)}
                  >
                     {button.icon && <FontAwesomeIcon icon={button.icon} className="btn-icon" />}
                     {button.text}
                  </button>
               ))}
            </Modal.Footer>
         </Modal>
      );
   }
}

export default ModalPopup;
