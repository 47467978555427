import React from "react";

import { Link, useLocation } from "react-router-dom";

import logoImg from "img/logos/xureal_vertical_black.png";

const Footer = () => {
   const location = useLocation();
   const sideClasses = location.pathname.indexOf("user/account") !== -1 ? "sidemenu" : "";

   return (
      <div className="footer">
         <div className={sideClasses}></div>
         <div className="footer-right">
            <div className="footer-logo">
               <img src={logoImg} alt="Logo" height="100%" />
            </div>
            <div className="footer-text">
               <span className="small">Copyright &copy;2020 XUREAL, LLC. All rights reserved. &nbsp;</span>
               <Link to="/legal" className="btn btn-link f-normal small text-xureal">
                  Privacy &amp; Legal Policies
               </Link>
            </div>
         </div>
      </div>
   );
};

export default Footer;
