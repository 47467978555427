import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { ToastContainer } from "react-toastify";
//import ModalContainer from "components/modal/modalContainer";

import NavBar from "components/nav/navbar";
import Footer from "components/nav/footer";
import ProtectedRoute from "components/nav/protectedRoute";

import UserSignup from "components/user/signup";
import UserLogin from "components/user/login";
import UserLogout from "components/user/logout";

import UserAccountInfo from "components/user/accountInfo";
import UserAccountEmail from "components/user/accountEmail";
import UserAccountPassword from "components/user/accountPassword";
import UserAccountZoom from "components/user/accountZoom";
import UserAccountDelete from "components/user/accountDelete";

import UserResetRequest from "components/user/resetRequest";
import UserResetSent from "components/user/resetSent";
import UserResetPassword from "components/user/resetPassword";

import MeetingsList from "components/meetings/meetingsList";
import MeetingDetails from "components/meetings/meetingDetails";
import MeetingInvite from "components/meetings/meetingInvite";
import MeetingParticipants from "components/meetings/meetingParticipants";

import Room from "components/room/room";

import NotFound from "components/pages/notfound";

import { library } from "@fortawesome/fontawesome-svg-core";

import { faAddressCard, faChevronCircleRight, faEnvelope, faEye, faEyeSlash, faKey, faSignInAlt, faSignOutAlt, faSortDown, faSortUp, faTrash, faUser, faUserMinus, faUserPlus, faUserClock, faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faCalendarPlus, faCheckCircle, faTimesCircle, faPlusCircle, faMinusCircle, faDoorOpen, faWindowMinimize, faWindowMaximize, faCog, faCheck, faBars, faDesktop, faCommentAlt, faShoppingBag, faMapMarkerAlt, faTrophy, faTimes, faChevronRight, faExpandArrowsAlt, faCompressArrowsAlt, faPlay, faPause, faFilm, faStreetView, faUserShield, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faWebcam, faVolume, faVolumeMute } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

library.add(faAddressCard, faChevronCircleRight, faEnvelope, faEye, faEyeSlash, faKey, faSignInAlt, faSignOutAlt, faSortDown, faSortUp, faTrash, faUser, faUserMinus, faUserPlus, faUserClock, faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faCalendarPlus, faCheckCircle, faTimesCircle, faPlusCircle, faMinusCircle, faDoorOpen, faWindowMinimize, faWindowMaximize, faCog, faCheck, faBars, faDesktop, faCommentAlt, faShoppingBag, faMapMarkerAlt, faTrophy, faTimes, faChevronRight, faExpandArrowsAlt, faCompressArrowsAlt, faPlay, faPause, faFilm, faStreetView, faUserShield, faStopwatch);

library.add(faWebcam, faVolume, faVolumeMute, faSpinnerThird);

class App extends Component {
   state = {};

   render() {
      return (
         <React.Fragment>
            <ToastContainer />

            <Switch>
               <Route path="/room" component={null} />
               <Route path="/user/login" component={null} />
               <Route path="/" component={NavBar} />
            </Switch>

            <main role="main" className="main">
               <Switch>
                  <Route path="/user/signup" component={UserSignup} />
                  <Route path="/user/login" component={UserLogin} />
                  <Route path="/user/logout" component={UserLogout} />

                  <Route path="/user/reset/request" component={UserResetRequest} />
                  <Route path="/user/reset/sent" component={UserResetSent} />
                  <Route path="/user/reset/:token" component={UserResetPassword} />

                  <Redirect from="/user/account" to="/user/account/info" exact />
                  <ProtectedRoute path="/user/account/info" component={UserAccountInfo} />
                  <ProtectedRoute path="/user/account/email/:token" component={UserAccountEmail} />
                  <ProtectedRoute path="/user/account/email" component={UserAccountEmail} />
                  <ProtectedRoute path="/user/account/password" component={UserAccountPassword} />
                  <ProtectedRoute path="/user/account/zoom/:code" component={UserAccountZoom} />
                  <ProtectedRoute path="/user/account/zoom" component={UserAccountZoom} />
                  <ProtectedRoute path="/user/account/delete" component={UserAccountDelete} />

                  <ProtectedRoute path="/meetings/participants/:meetingId" component={MeetingParticipants} />
                  <ProtectedRoute path="/meetings/invite/:meetingId" component={MeetingInvite} />
                  <ProtectedRoute path="/meetings/details/:meetingId" component={MeetingDetails} />
                  <ProtectedRoute path="/meetings/details" component={MeetingDetails} />

                  <ProtectedRoute path="/meetings" component={MeetingsList} />

                  <Route path="/room/:meetingId/:userId" component={Room} />
                  <Route path="/room/:meetingId" component={Room} />
                  <Redirect from="/room" to="/notfound" exact />

                  <Route path="/notfound" component={NotFound} />

                  <Redirect from="/" to="/meetings" exact />
               </Switch>
            </main>

            <Switch>
               <Route path="/room" component={null} />
               <Route path="/user/login" component={null} />
               <Route path="/" component={Footer} />
            </Switch>
         </React.Fragment>
      );
   }
}

export default App;
