import React, { Component } from "react";

import { SketchPicker } from "react-color";

class ColorPicker extends Component {
   state = {
      pickerVisible: false,
   };

   constructor(props) {
      super(props);

      this.wrapperRef = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
   }

   componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
   }

   componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
   }

   handleClickOutside = (event) => {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
         this.handleClose();
      }
   };

   onTogglePicker = () => {
      const pickerVisible = !this.state.pickerVisible;

      // if (pickerVisible) {
      //    const { onPickerOpen } = this.props;
      //    onPickerOpen(this.handleClose);
      // }

      this.setState({ pickerVisible });
   };

   handleClose = () => {
      this.setState({ pickerVisible: false });
   };

   handleChange = (color) => {
      const { onChangeComplete } = this.props;

      onChangeComplete(color);

      //this.handleClose();
   };

   render() {
      const { label, color } = this.props;

      return (
         <div className="form-group" ref={this.wrapperRef} style={{ cursor: "pointer" }}>
            <label className="group-label">{label}:</label>

            <div className="group-input color-picker" onClick={this.onTogglePicker}>
               <div className="color-picker-chip" style={{ backgroundColor: color }}></div>
               <div className="color-picker-text">{color}</div>
            </div>

            {this.state.pickerVisible && (
               <div className="sketch-picker">
                  <SketchPicker color={color} onChangeComplete={this.handleChange} />
               </div>
            )}
         </div>
      );
   }
}

export default ColorPicker;
