import React from "react";

import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

class PasswordReset extends Form {
   state = {
      formData: {
         passwordNew: "",
      },
      isValid: {
         passwordNew: null,
      },
      errors: {},
   };

   doSubmit = async () => {
      try {
         const forgotToken = this.props.match.params.token;
         //console.log("forgotToken: ", forgotToken);

         const { passwordNew } = this.state.formData;

         await userService.forgotTokenLogin(forgotToken, passwordNew);

         this.props.history.push("/");
      } catch (err) {
         msgService.responseErrorById(err, "user_reset_error");
      }
   };

   render() {
      return (
         <div className="page">
            <div className="page-narrow">
               <p className="page-title">Password Reset</p>

               <form onSubmit={this.handleSubmit}>
                  {this.renderPasswordNew("passwordNew", "New Password")}

                  {this.renderButton("Update Password")}
               </form>
            </div>
         </div>
      );
   }
}

export default PasswordReset;
