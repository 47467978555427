import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "services/userService";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (!auth.getCurrentUser())
               return (
                  <Redirect
                     to={{
                        pathname: "/user/login",
                        state: { from: props.location },
                     }}
                  />
               );
            return Component ? <Component {...props} /> : render(props);
         }}
      />
   );
};

export default ProtectedRoute;
