import React, { Component } from "react";

import { DATETIME_FORMAT_LONG } from "data/constants";

import DateRangePicker from "react-bootstrap-daterangepicker";

import moment from "moment-timezone";

class DateTime extends Component {
   handleApply = (event, picker) => {
      const { name, onChange } = this.props;
      const startDate = picker.startDate.toDate();
      const date = moment(startDate).toDate();
      const currentTarget = { name, value: date };
      onChange({ currentTarget });

      //this.setState({ value: startDate });
   };

   render() {
      const { name, label, options, value, error, isValid, autoComplete } = this.props;
      const { minDate, maxDate } = options;

      if (!value) return "";

      const startDate = moment(value);

      //console.log("minDate:", minDate);
      //console.log("maxDate:", maxDate);
      //console.log("startDate:", startDate);

      let classes = "group-input xu-select-arrow";
      if (isValid === true) classes += " is-valid";
      if (isValid === false && error) classes += " is-invalid";

      const initialSettings = {
         singleDatePicker: true,
         //minYear: 2020,
         timePicker: true,
         timePickerIncrement: 15,
         timePicker24Hour: false,
         locale: {
            format: DATETIME_FORMAT_LONG,
         },
         linkedCalendars: false,
         showCustomRangeLabel: false,
         startDate: startDate,
         minDate: minDate,
         maxDate: maxDate,
         drops: "down",
      };

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>

            <DateRangePicker initialSettings={initialSettings} className="datetimepicker xu-select-arrow" onApply={this.handleApply}>
               <input name={name} type="text" className={classes} autoComplete={autoComplete ? autoComplete : "off"} readOnly={true} />
            </DateRangePicker>

            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }
}

export default DateTime;
