module.exports = Object.freeze({
   DATETIME_FORMAT_LONG: "dddd, MMMM Do YYYY, h:mm A",
   DATETIME_FORMAT_SHORT: "MMM Do YYYY, h:mm A",

   UPLOAD_FILETYPES: ["image/png", "image/jpg", "image/jpeg"],
   UPLOAD_WHITELIST: ["png", "jpg"],
   UPLOAD_MAX_BYTES: 1000000 * 15,

   MEETING_CONTENT_URL: "https://experience.xureal.com/world",

   INVITE_IMAGES: {
      logo: { name: "logo.png", width: 170 },
      hero: { name: "hero.jpg", width: 510 },
      xureal: { name: "xureal.png", width: 100 },
   },

   INVITE_ELEMENTS: {
      inviteSubject: "Virtual Invite",
      inviteMessage1: "YOU HAVE BEEN INVITED TO",
      inviteMessage2: "join us at our Virtual EBC!",
      inviteBkgrndColor: "#333333",
      inviteTextColor: "#ffffff",
      inviteBtnColor: "#00e973",
      inviteBtnTextColor: "#ffffff",
   },

   // STREAM: {
   //    LARGE: {
   //       width: 256,
   //       height: 144,
   //    },
   // },

   // SIZE: {
   //    SMALL: { value: 0, name: "Small", code: "S" },
   //    MEDIUM: { value: 1, name: "Medium", code: "M" },
   //    LARGE: { value: 2, name: "Large", code: "L" },
   // },
});
