import React from "react";

const Input = ({ name, isValid, isDisabled, label, value, help, type, error, onFocus, onChange, onBlur, autoComplete }) => {
   let classes = "xu-auto group-input";
   if (isValid === true) classes += " is-valid";
   if (isValid === false && error) classes += " is-invalid";

   //console.log("name:", name, "isValid:", isValid, "value:", value, "error:", error);

   if (!value) value = "";

   return (
      <div className="form-group">
         {label && (
            <label className="group-label" htmlFor={name}>
               {label}
            </label>
         )}
         {help && (name === "email" || name === "newEmail") && <div className="group-info">Used for important account related communications only. We'll never share your email.</div>}
         <input id={name} type={type} name={name} value={value} onChange={onChange} onAnimationStart={onChange} onFocus={onFocus} onBlur={onBlur} className={classes} autoComplete={autoComplete ? autoComplete : "off"} disabled={isDisabled ? isDisabled : false} />
         {error && <div className="group-feedback">{error}</div>}
      </div>
   );
};

export default Input;
