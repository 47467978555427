import http from "services/httpService";
import jwtDecode from "jwt-decode";

const loginEndpoint = "/api/user/login";
const resetEndpoint = "/api/user/reset";
const verifyEndpoint = "/api/user/verify";
const userProfileEndpoint = "/api/user/me";

const userAuthTokenKey = "userAuthToken";
const userTimezoneKey = "userTimezoneToken";

http.setToken(getToken());

function signup(formData) {
   //console.log("signup()", formData);

   return http.post(userProfileEndpoint, formData);
}

function getAccount() {
   return http.get(userProfileEndpoint);
}

async function saveAccount(profileData) {
   //console.log("saveAccount()", profileData);

   let res;

   if (profileData._id) {
      if (profileData._id) delete profileData._id;
      res = await http.put(userProfileEndpoint, profileData);
   } else {
      res = await http.post(userProfileEndpoint, profileData);
   }

   const userAuthToken = res.headers["x-auth-token"];
   //console.log("res:", res, "userAuthToken:", userAuthToken);
   if (userAuthToken) loginWithJwt(userAuthToken);

   return res;
}

function deleteUser(formData) {
   return http.deleteData(userProfileEndpoint, formData);
}

async function login(email, password) {
   //console.log("login()", email, password);

   const res = await http.post(loginEndpoint, { email, password });
   //console.log("res:", res);

   const { data: userAuthToken } = res;
   //console.log("userAuthToken:", userAuthToken);

   if (userAuthToken) loginWithJwt(userAuthToken);

   return res;
}

async function forgotTokenLogin(forgotToken, passwordNew) {
   //console.log("forgotTokenLogin()", forgotToken, passwordNew);

   const res = await http.put(resetEndpoint + "/" + forgotToken, { forgotToken, passwordNew });
   const { data: userAuthToken } = res;
   //console.log("userAuthToken:", userAuthToken);

   if (userAuthToken) loginWithJwt(userAuthToken);

   return res;
}

async function verifyEmail(verifyToken) {
   //console.log("verifyEmail()", verifyToken);

   const res = await http.post(verifyEndpoint + "/" + verifyToken, { verifyToken });
   const { data: userAuthToken } = res;
   //console.log("res:", res);
   //console.log("userAuthToken:", userAuthToken);

   if (userAuthToken) loginWithJwt(userAuthToken);

   return res;
}

function verifyEmailResend() {
   return http.get(verifyEndpoint);
}

function loginWithJwt(userAuthToken) {
   //console.log("loginWithJwt() userAuthToken:", userAuthToken);

   localStorage.setItem(userAuthTokenKey, userAuthToken);
   http.setToken(userAuthToken);

   const decode = jwtDecode(userAuthToken);

   const userTimezone = decode.userTimezone;
   if (userTimezone) localStorage.setItem(userTimezoneKey, userTimezone);
}

function reset(email) {
   //console.log("reset(" + email + ")");
   return http.post(resetEndpoint, { email });
}

function getCurrentUser() {
   try {
      const userAuthToken = localStorage.getItem(userAuthTokenKey);
      if (!userAuthToken) return null;

      const userTokenDecode = jwtDecode(userAuthToken);

      if (!userTokenDecode.userTimezone) {
         userTokenDecode.userTimezone = localStorage.getItem(userTimezoneKey);
      }
      if (userTokenDecode.userTimezone === "null" || userTokenDecode.userTimezone === "undefined") userTokenDecode.userTimezone = null;
      //console.log("userTimezone:", decode.userTimezone);

      //console.log("userTokenDecode:", userTokenDecode);

      return userTokenDecode;
   } catch (error) {
      return null;
   }
}

function getTimezone() {
   let userTimezone = localStorage.getItem(userTimezoneKey);
   if (userTimezone === "null" || userTimezone === "undefined") userTimezone = null;
   //console.log("userTimezone:", userTimezone);
   return userTimezone;
}

function getToken() {
   try {
      return localStorage.getItem(userAuthTokenKey);
   } catch (error) {
      return null;
   }
}

function isAdmin() {
   try {
      const userAuthToken = localStorage.getItem(userAuthTokenKey);
      const decode = jwtDecode(userAuthToken);
      return decode.isAdmin;
   } catch (error) {
      return null;
   }
}

function logout() {
   localStorage.removeItem(userAuthTokenKey);
   localStorage.removeItem(userTimezoneKey);
}

export default {
   signup,
   getAccount,
   saveAccount,
   deleteUser,
   login,
   loginWithJwt,
   verifyEmail,
   verifyEmailResend,
   reset,
   forgotTokenLogin,
   getCurrentUser,
   getTimezone,
   getToken,
   isAdmin,
   logout,
};
