import React from "react";

import socketService from "services/socketService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "components/form/form";

import "css/room-chat.css";

class RoomChat extends Form {
   state = {
      formData: {
         message: "",
      },
      isValid: {
         message: null,
      },
      errors: {},
   };

   hostUserId;
   myUserId;

   componentDidMount() {
      const { hostUserId } = this.props.roomData;
      if (!hostUserId) throw new Error("hostUserId is missing");
      this.hostUserId = hostUserId;

      const { userId: myUserId } = this.props.userData;
      if (!myUserId) throw new Error("myUserId is missing");
      this.myUserId = myUserId;

      //console.log("hostUserId:", hostUserId, "myUserId:", myUserId);

      this.msgRef = React.createRef();

      // socketService.relayMessage("chat-typing", this.userTyping);
   }

   componentDidUpdate() {
      if (this.msgRef.current) this.msgRef.current.scrollTop = this.msgRef.current.scrollHeight;
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   sendMessage = (e) => {
      //console.log("sendMessage()");
      e.preventDefault();

      const { formData } = this.state;

      socketService.sendRequest("chat-message", { formData });

      formData.message = "";

      this.setState({ formData });
   };

   // userTyping = (data) => {
   //    //console.log("userTyping() data:", data);
   // };

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Chat</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>

            <div className="side-body">
               <div className="chat-msgs" ref={this.msgRef}>
                  {this.renderMessages()}
               </div>

               <form className="chat-form" onSubmit={this.sendMessage}>
                  {this.renderInputSimple("message")}
                  {this.renderButton("Send", null, "special btn-sm chat-sendbtn")}
               </form>
            </div>
         </div>
      );
   }

   renderMessages() {
      const { chatMessages } = this.props;

      chatMessages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

      const html = [];

      for (const chatMessage of chatMessages) {
         const { userId, timestamp, userFirstName, message } = chatMessage;

         let classes = "chat-msg";
         if (userId === this.myUserId) classes += " me";
         if (userId === this.hostUserId) classes += " host";

         html.push(
            <div className={classes} key={timestamp}>
               <div className="chat-msg-name">{userFirstName}:</div>
               <div className="chat-msg-text">{message}</div>
            </div>
         );
      }

      return html;
   }
}

export default RoomChat;
