import React from "react";

const InputSimple = ({ name, isValid, isDisabled, value, type, error, onFocus, onChange, onBlur, autoComplete }) => {
   let classes = "xu-auto group-input";
   if (isValid === true) classes += " is-valid";
   if (isValid === false && error) classes += " is-invalid";

   //console.log("name:", name, "isValid:", isValid, "value:", value, "error:", error);

   if (!value) value = "";

   return <input id={name} type={type} name={name} value={value} onChange={onChange} onAnimationStart={onChange} onFocus={onFocus} onBlur={onBlur} className={classes} autoComplete={autoComplete ? autoComplete : "off"} disabled={isDisabled ? isDisabled : false} />;
};

export default InputSimple;
