import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

// import { Collapse } from "react-bootstrap";

import moment from "moment-timezone";

import Modal from "components/modal/modal";

import meetingService from "services/meetingService";
import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-meetings.css";

const platformOptions = require("data/platforms.json");

class MeetingsList extends Component {
   state = {
      user: null,
      currentTime: null,
      currentDate: null,
      meetingsData: [],
      isLoaded: false,
      modalData: {},
      detailsOpen: [],
   };

   userTimeZone;
   clockTimeout;

   async componentDidMount() {
      const user = userService.getCurrentUser();
      if (!user) return (window.location = "/");
      //console.log("user:", user);

      this.userTimezone = userService.getTimezone();
      if (!this.userTimezone) this.userTimezone = moment.tz.guess();
      //console.log("userTimezone", this.userTimezone);

      const meetingsData = await meetingService.getMeetings();
      //console.log("meetingsData:", meetingsData);

      if (meetingsData) {
         this.setState({ meetingsData, user, isLoaded: true }, this.updateTime());
      }
   }

   componentWillUnmount() {
      clearTimeout(this.clockTimeout);
   }

   updateTime = () => {
      const currentTime = moment.tz(this.userTimezone).format("h:mm A");
      //console.log("currentTime", currentTime);

      const currentDate = moment.tz(this.userTimezone).format("dddd, MMMM DD");
      //console.log("currentDate", currentDate);

      const delay = 60000 - (new Date().getTime() % 60000) + 100;
      this.clockTimeout = setTimeout(this.updateTime, delay);

      this.setState({ currentTime, currentDate });
   };

   showModalConfirmDelete = (meetingData) => {
      //console.log("showModalConfirmDelete()", meetingData);

      const modalData = {
         show: true,
         type: "modal",
         showClose: true,
         onClose: (e) => this.handleModalClose(e),
         title: "Confirm Meeting Deletion",
         text: "Are you sure you want to delete this meeting? This action is permanent and irreversible. Participants will not be automatically notified.",
         buttons: [
            {
               text: "Cancel",
               type: "secondary",
               onClick: (e) => this.handleModalClose(e),
            },
            {
               text: "Delete Meeting",
               type: "danger",
               //icon: "trash",
               onClick: (e) => this.handleDelete(meetingData),
            },
         ],
      };

      this.setState({ modalData });
   };

   handleModalClose = () => {
      //console.log("handleModalClose()");

      this.setState({ modalData: null });
   };

   handleDelete = async (meetingData) => {
      //console.log("handleDelete()", meetingData);

      const { meetingId } = meetingData;
      //console.log("meetingId:", meetingId);

      const { meetingsData } = this.state;
      const oldMeetings = [...meetingsData];
      //console.log("oldMeetings:", oldMeetings);

      const newMeetings = oldMeetings.filter(function (obj) {
         return obj.meetingId !== meetingId;
      });
      //console.log("newMeetings:", newMeetings);

      this.setState({ meetingsData: newMeetings });

      try {
         await meetingService.deleteMeeting(meetingId);
      } catch (error) {
         if (error.response && error.response.status !== 200) {
            this.setState({ meetingsData: oldMeetings });
            msgService.responseErrorById(error, "meeting_delete_error");
         }
      }
   };

   properize(string) {
      return string + "'" + (string[string.length - 1] !== "s" ? "s" : "");
   }

   setDetailsOpen(meetingId, openState) {
      //console.log("setDetailsOpen(" + meetingId + ", " + openState + ")");

      const { detailsOpen } = this.state;

      detailsOpen[meetingId] = openState;

      this.setState({ detailsOpen });
   }

   render() {
      const { currentTime, currentDate, isLoaded, modalData } = this.state;

      if (!isLoaded) return null;

      const { userFirstName } = this.state.user;
      const firstName = this.properize(userFirstName).toUpperCase();

      return (
         <Fragment>
            {isLoaded && (
               <div className="page">
                  <div className="page-topper">
                     <div className="page-wide">
                        <div className="meeting-row">
                           <div className="meeting-row-fill">
                              <div className="page-eyebrow m-0">{firstName} MEETINGS</div>
                              <div className="page-title m-0">Upcoming Virtual Meetings</div>
                           </div>

                           <div className="meeting-row-fit align-right">
                              <div className="page-title m-0">{currentTime}</div>
                              <div className="page-eyebrow m-0">{currentDate}</div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="page-wide">{this.renderMeetings()}</div>
               </div>
            )}

            {modalData && <Modal modalData={modalData} />}
         </Fragment>
      );
   }

   renderMeetings() {
      const { meetingsData } = this.state;

      if (meetingsData.length === 0) return this.renderNoMeetings();

      const html = [];

      for (const meetingData of meetingsData) {
         html.push(this.renderMeeting(meetingData));
      }

      return <div className="meetings">{html}</div>;
   }

   renderNoMeetings() {
      return (
         <Fragment>
            <div className="page-desc f-bold">You have no upcoming virtual meetings.</div>
            <Link to="/meetings/details" className="btn btn-link text-xureal mt-20">
               Schedule a virtual meeting
            </Link>
         </Fragment>
      );
   }

   renderMeeting(meetingData) {
      //console.log("renderMeeting() meetingData:", meetingData);

      const { meetingId, meetingName, meetingDateTime, meetingDuration, meetingParticipants, meetingPlatform } = meetingData;

      const nowMoment = moment().tz(this.userTimezone);
      const meetingMoment = moment(meetingDateTime).tz(this.userTimezone);

      const daysToMeeting = meetingMoment.diff(nowMoment, "days");
      //console.log("daysToMeeting", daysToMeeting);

      // const minutesToMeeting = meetingMoment.diff(nowMoment, "minutes");
      //console.log("minutesToMeeting", minutesToMeeting);

      let joinBtnClasses = "btn";

      // disable join button
      // if (minutesToMeeting < 15) {
      joinBtnClasses += " btn-primary";
      // } else {
      //    joinBtnClasses += " btn-secondary disabled";
      // }

      let meetingDay;

      if (daysToMeeting > 0) {
         meetingDay = meetingMoment.format("dddd, MMMM DD").toUpperCase();
      } else {
         meetingDay = "TODAY";
      }

      const meetingStartTime = meetingMoment.format("h:mm A");
      const meetingEndTime = meetingMoment.add(meetingDuration, "minutes").format("h:mm A");

      const meetingTime = meetingStartTime + " - " + meetingEndTime;

      const detailsId = meetingId + "-details";

      const openState = this.state.detailsOpen[meetingId];
      let collapseClasses = "collapse";
      collapseClasses += openState ? " opened" : " closed";

      const platformName = platformOptions.filter((platform) => {
         return platform["value"] === meetingPlatform;
      })[0].text;

      return (
         <div className="meeting" key={meetingId}>
            <div className="meeting-row">
               <div className="meeting-row-fill">
                  <div className="meeting-dot"></div>
                  <div className="meeting-day">{meetingDay}</div>
                  <div className="meeting-name">{meetingName}</div>
                  <div className="meeting-data">{meetingTime}</div>

                  <div className="meeting-details">
                     <div className="meeting-hideshow">
                        <div className="btn-link meeting-hideshow-icon">{openState ? "-" : "+"}</div>
                        <div className="btn btn-link" onClick={() => this.setDetailsOpen(meetingId, !openState)}>
                           {openState ? "Hide details" : "Show details"}
                        </div>
                     </div>

                     <div className={collapseClasses}>
                        <div id={detailsId}>
                           <div className="meeting-title">Platform:</div>
                           <div className="meeting-participant">{platformName}</div>

                           <div className="meeting-title">Participants:</div>
                           <div className="meeting-participants">
                              <div>{this.renderParticipants(meetingParticipants)}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="meeting-row-fit">
                  <Link className={joinBtnClasses} to={"/room/" + meetingId}>
                     Join
                  </Link>
               </div>

               <div className="meeting-row-fit vl">
                  <Link className="btn btn-link small meeting-btn" to={"/meetings/details/" + meetingId}>
                     Edit
                  </Link>
                  <br></br>

                  <button className="btn btn-link text-danger small meeting-btn" onClick={() => this.showModalConfirmDelete(meetingData)}>
                     Delete
                  </button>
               </div>
            </div>
         </div>
      );
   }

   renderParticipants(meetingParticipants) {
      //console.log("renderParticipants() meetingParticipants:", meetingParticipants);

      const { email: userEmail } = this.state.user;
      //console.log("userEmail", userEmail);

      const html = [];

      if (!meetingParticipants || meetingParticipants.length === 0) return;

      // sort participant emails alphabetically
      meetingParticipants.sort((a, b) => (a.email > b.email ? 1 : -1));

      // put owner email on top
      meetingParticipants.sort((a, b) => (a.email === userEmail ? -1 : 1));

      for (const participant of meetingParticipants) {
         const key = participant.email.split("@").join("-").split(".").join("-");
         html.push(
            <div key={key} className="meeting-participant">
               {participant.email}
            </div>
         );
      }

      return html;
   }
}

export default MeetingsList;
