import moment from "moment-timezone";

import { DATETIME_FORMAT_SHORT } from "data/constants";

import timezonesOptions from "data/timezones";
import platformOptions from "data/platforms";

export function formatString(key, value) {
   //console.log(key, value);
   if (!value) {
      console.error(key, value);
      return "";
   }
   switch (key) {
      case "meetingDuration":
         const hrs = Math.floor(value / 60);
         const mins = Math.round((value % 60) * 60);
         return hrs + ":" + String(mins).padStart(2, "0");

      case "meetingCreatedAt":
      case "meetingDateTime":
         return moment(value).format(DATETIME_FORMAT_SHORT);

      case "meetingPlatform":
         const provider = platformOptions.filter((obj) => {
            return obj.value === value;
         });
         return provider[0].text;

      case "meetingTimezone":
         const timezone = timezonesOptions.filter((obj) => {
            return obj.value === value;
         });
         return timezone[0].text;

      default:
         return value;
   }
}
