import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-menu.css";

let panelMenuBtns = require("data/panelMenu.json");

class RoomPanelsMenu extends Component {
   state = {
      isLoaded: false,
   };

   isHost;
   hostUserId;

   componentDidMount() {
      const { roomData, userData } = this.props;
      const { hostUserId, meetingPlatform } = roomData;
      const { isHost } = userData;

      this.isHost = isHost;
      this.hostUserId = hostUserId;
      //console.log("isHost:", isHost);

      if (meetingPlatform === "none") {
         const videoBtn = panelMenuBtns.find((btn) => btn.id === "video");
         videoBtn.disabled = true;
      }

      if (!isHost) {
         panelMenuBtns = panelMenuBtns.filter((btn) => btn.id !== "host");
      }

      this.setState({ isLoaded: true });
   }

   optionClicked = (event) => {
      //console.log("optionClicked()");

      const btnId = event.currentTarget.value;
      const { setMenuId } = this.props;
      setMenuId(btnId);
   };

   getBadgeColor = (btnId, badgeStatus) => {
      //console.log("showBadge() btnId:", btnId, "badgeStatus:", badgeStatus);

      if (btnId === "chat" && badgeStatus) {
         return "var(--danger)";
      }

      if (btnId === "video") {
         if (badgeStatus === "off") {
            return "var(--danger)";
         } else if (badgeStatus === "on") {
            return "var(--success)";
         }
      }

      return null;
   };

   render() {
      return <div className="room-menu">{this.renderBtns()}</div>;
   }

   renderBtns() {
      const { isLoaded } = this.state;
      if (!isLoaded) return null;

      const { currentMenuOption, menuBadges } = this.props;
      //console.log("menuBadges:", menuBadges);

      const html = [];

      for (const menuBtn of panelMenuBtns) {
         const { label, icon, id, disabled, alignBtm } = menuBtn;

         const badgeColor = this.getBadgeColor(id, menuBadges[id]);
         //console.log("badgeColor:", badgeColor);

         const badgeStyles = { backgroundColor: badgeColor };

         let classes = "menu-btn";
         if (disabled) classes += " disabled";
         if (currentMenuOption === id) classes += " selected";
         if (alignBtm) classes += " mt-auto";

         const btnId = "tour-" + id;

         html.push(
            <button id={btnId} className={classes} key={"menu-btn-" + id} onClick={this.optionClicked} value={id}>
               <div className="btn-circle">
                  <FontAwesomeIcon icon={icon} className="btn-icon" />
               </div>
               <div className="btn-label">{label}</div>
               {badgeColor && <div className="btn-badge" style={badgeStyles}></div>}
            </button>
         );
      }

      return html;
   }
}

export default RoomPanelsMenu;
