import React, { Component, Fragment } from "react";

import triviaService from "services/triviaService";
import socketService from "services/socketService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-trivia.css";

import logoImg from "img/trivia/trivia-challenge.png";
import instructImg from "img/trivia/trivia-instruct.png";
import spinnerImg from "img/trivia/spinner_green.png";
import tokenImg from "img/token.png";

class RoomTrivia extends Component {
   state = {
      contentId: null,

      showIntro: null,
      showInstruct: null,
      showWaiting: null,
      showResults: null,

      countdownNumber: null,

      triviaData: null,

      questionIndex: null,
      answerIndex: null,
      correctAnswerIndex: null,
      answerIsCorrect: null,

      answeredCorrect: null,
      answeredTotal: null,
      questionsTotal: null,
      pointsEarned: null,
   };

   QUESTION_DELAY = 1000;
   MAX_QUESTIONS = 5;

   isHost;
   timeOut;

   componentDidMount() {
      const { isHost } = this.props.userData;
      this.isHost = isHost;
      //console.log("isHost:", isHost);

      const { contentId } = this.props;
      //console.log("contentId:", contentId);

      this.loadTriviaData(contentId);
   }

   componentDidUpdate() {
      const { contentId } = this.props;
      //console.log("contentId:", contentId);

      if (contentId !== this.state.contentId) {
         this.loadTriviaData(contentId);
      }
   }

   componentWillUnmount() {
      clearTimeout(this.timeOut);
   }

   loadTriviaData = async (contentId) => {
      //console.log("closeClick()");

      clearTimeout(this.timeOut);

      this.setState({ showIntro: true, showInstruct: false, showWaiting: false, showResults: false, contentId, questionIndex: null, answerIndex: null, correctAnswerIndex: null, answerIsCorrect: null, answeredCorrect: 0, answeredTotal: 0, pointsEarned: 0 });

      socketService.setUserData({
         name: "trivia",
         value: "intro",
      });

      const triviaData = await triviaService.getTrivia(contentId);
      // console.log("triviaData:", triviaData);

      let questionsTotal = triviaData.Questions.length;
      if (questionsTotal > this.MAX_QUESTIONS) questionsTotal = this.MAX_QUESTIONS;

      this.setState({ triviaData, questionsTotal });
   };

   closeClick = (event) => {
      //console.log("closeClick()");

      const { setUserContent } = this.props;
      setUserContent({ contentType: null });
   };

   updateUserContentStatus(contentStatus) {
      const { contentId } = this.props;
      socketService.setUserContent({ contentType: "trivia", contentId, contentStatus });
   }

   setIntroDone = (event) => {
      //console.log("setIntroDone()");

      this.updateUserContentStatus("instruct");

      this.setState({ showIntro: false, showInstruct: true });
   };

   setInstructDone = (event) => {
      //console.log("setInstructDone()");

      this.timeOut = setTimeout(this.startGame, 1000);

      this.updateUserContentStatus("ready");

      this.setState({ showInstruct: true, showWaiting: true });
   };

   startGame = () => {
      //console.log("startGame() ");

      this.updateUserContentStatus("countdown");

      this.timeOut = setTimeout(this.doCountdown, 1000);

      this.setState({ showInstruct: false, showWaiting: false, countdownNumber: 3 });
   };

   doCountdown = (event) => {
      //console.log("doCountdown()");

      let { countdownNumber } = this.state;

      countdownNumber -= 1;

      if (countdownNumber === 0) countdownNumber = null;

      this.setState({ countdownNumber });

      if (countdownNumber > 0) {
         this.timeOut = setTimeout(this.doCountdown, 1000);
      } else {
         this.setQuestionIndex(0);
      }
   };

   setQuestionIndex = (questionIndex) => {
      //console.log("setQuestionIndex() questionIndex:", questionIndex);

      const contentStatus = "question-" + (questionIndex + 1);
      this.updateUserContentStatus(contentStatus);

      this.setState({ questionIndex, answerIndex: null, correctAnswerIndex: null, answerIsCorrect: null });
   };

   showNextQuestion = (delay) => {
      //console.log("showNextQuestion()");

      if (!delay) delay = 0;

      let { questionIndex } = this.state;
      questionIndex += 1;

      if (questionIndex <= this.state.questionsTotal - 1) {
         this.timeOut = setTimeout(this.setQuestionIndex, delay, questionIndex);
      } else {
         this.timeOut = setTimeout(this.showResults, delay);
      }
   };

   answerClick = (event) => {
      //console.log("answerClick()");

      const answerIndex = parseInt(event.currentTarget.value);
      //console.log("answerIndex:", answerIndex, event.currentTarget);

      const { triviaData, questionIndex } = this.state;
      const questionData = triviaData.Questions[questionIndex];
      const correctAnswerIndex = parseInt(questionData.CorrectAnswerChoices[0]);
      //console.log("answerIndex:", answerIndex, "correctAnswerIndex:", correctAnswerIndex);

      const answerIsCorrect = answerIndex === correctAnswerIndex;
      //console.log("answerIsCorrect:", answerIsCorrect);

      let { answeredTotal, answeredCorrect, pointsEarned } = this.state;

      answeredTotal++;
      if (answerIsCorrect) {
         answeredCorrect++;
         pointsEarned += questionData.Points;
      }

      this.setState({ answerIndex, correctAnswerIndex, answerIsCorrect, answeredTotal, answeredCorrect, pointsEarned });

      this.showNextQuestion(this.QUESTION_DELAY);
   };

   showResults = () => {
      //console.log("showResults()");

      this.updateUserContentStatus("results");

      this.setState({ questionIndex: null, showResults: true });
   };

   render() {
      const { showIntro, showInstruct, countdownNumber, questionIndex, showResults } = this.state;

      return (
         <div className="xu-modal trivia">
            <button className="side-close trivia-close" onClick={this.closeClick}>
               <FontAwesomeIcon icon="times" className="icon" />
            </button>

            {showIntro && this.renderIntro()}
            {showInstruct && this.renderInstruct()}
            {countdownNumber && this.renderCountdown()}
            {questionIndex !== null && this.renderQuestion()}
            {showResults && this.renderResults()}

            {/* {this.isHost && this.renderUsersStatus()} */}
         </div>
      );
   }

   renderIntro() {
      return (
         <Fragment>
            <img className="intro-logo" src={logoImg} alt="XUREAL Trivia Challenge" />
            <button className="btn btn-special intro-startbtn" onClick={this.setIntroDone}>
               Start Game
            </button>
         </Fragment>
      );
   }

   renderInstruct() {
      const { showWaiting } = this.state;

      return (
         <Fragment>
            <img className="instruct-logo" src={logoImg} alt="XUREAL Trivia Challenge" />
            <img className="trivia-instruct" src={instructImg} alt="XUREAL Trivia Instructions" />

            <div className="instruct-btm">
               {!showWaiting && (
                  <button className="btn btn-special instruct-startbtn" onClick={this.setInstructDone}>
                     <FontAwesomeIcon icon="play" className="icon" />
                  </button>
               )}

               {showWaiting && (
                  <Fragment>
                     <FontAwesomeIcon icon={["fad", "spinner-third"]} className="fad waiting-spin rotating" />
                     <span className="waiting-text">Waiting for all participants to join...</span>
                  </Fragment>
               )}
            </div>
         </Fragment>
      );
   }

   renderCountdown() {
      const { countdownNumber } = this.state;

      return (
         <Fragment>
            <div className="countdown-text">Trivia game starting in...</div>

            <div className="countdown-spinner">
               <img className="rotating" src={spinnerImg} alt="Countdown Spinner" />
               <div className="countdown-number flex-center">{countdownNumber}</div>
            </div>
         </Fragment>
      );
   }

   renderQuestion() {
      const { triviaData, questionIndex, questionsTotal } = this.state;

      if (!triviaData) return null;

      const questionData = triviaData.Questions[questionIndex];
      //console.log("questionData:", questionData);

      const { QuestionText, Points } = questionData;
      const questionNumber = questionIndex + 1;

      return (
         <Fragment>
            <div className="question-box flex-center">
               <div className="question-count">{questionNumber + " / " + questionsTotal}</div>
               <div className="question-text">{QuestionText}</div>
               <div className="question-points">
                  <div className="points-icon img-fit-height">
                     <img src={tokenImg} alt="XR Token" />
                  </div>
                  {Points}
               </div>
            </div>

            <div className="answer-box">{this.renderAnswers(questionData)}</div>
         </Fragment>
      );
   }

   renderAnswers(questionData) {
      const { correctAnswerIndex, answerIndex } = this.state;
      const { AnswerChoices } = questionData;

      const html = [];

      for (let i = 0; i < AnswerChoices.length; i++) {
         const key = "answer-" + i;

         let answerText = AnswerChoices[i];

         let btnClasses = "btn answer-btn flex-center";
         if (correctAnswerIndex !== null) {
            btnClasses += " answered";
            btnClasses += i === correctAnswerIndex ? " correct" : " wrong";

            if (i === answerIndex) btnClasses += " selected";

            // [HACK] - force button redraw
            answerText += " ";
         }

         html.push(
            <button className={btnClasses} key={key} value={i} onClick={this.answerClick}>
               {answerText}
            </button>
         );
      }

      return html;
   }

   renderResults() {
      const { answeredCorrect, questionsTotal, pointsEarned } = this.state;

      return (
         <div className="results-box flex-center">
            <div className="results-title">CONGRATULATIONS!</div>

            <div className="results-area">
               <div>You answered</div>
               <div className="results-count">{answeredCorrect + " / " + questionsTotal}</div>
               <div>CORRECTLY</div>
            </div>

            <div>YOU'VE EARNED</div>
            <div className="results-points">
               <div className="points-icon img-fit-height">
                  <img src={tokenImg} alt="XR Token" />
               </div>
               {pointsEarned}
            </div>

            <button className="btn btn-special results-closebtn" onClick={this.closeClick}>
               Back to World
            </button>
         </div>
      );
   }

   // renderUsersStatus() {
   //    const { usersList } = this.props;

   //    const html = [];

   //    for (const userData of usersList) {
   //       const { userId, userFirstName, userAspects } = userData;
   //       const triviaStatus = userAspects["trivia"];

   //       html.push(
   //          <div key={userId} className="trivia-user">
   //             <div>{userFirstName}</div>
   //             <div>{triviaStatus}</div>
   //          </div>
   //       );
   //    }

   //    return <div className="trivia-users">{html}</div>;
   // }
}

export default RoomTrivia;
