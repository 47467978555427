import { toast } from "react-toastify";

const messagesData = require("data/messages.json");

function responseSuccessById(res, defaultMsgId) {
   //console.log("responseSuccessById:", res, defaultMsgId);

   if (res.status === 200) {
      return showMsgById(defaultMsgId);
   }
}

function responseErrorById(err, defaultMsgId) {
   //console.error("responseErrorById:", err, defaultMsgId);

   if (!defaultMsgId) defaultMsgId = "site_unknown_error";

   if (!err.response) return showMsgById("site_server_error");

   if (err.response.status >= 400 && err.response.status < 500) {
      if (err.response.data) {
         return showMsgById(err.response.data, defaultMsgId);
      } else {
         return showMsgById(defaultMsgId);
      }
   }

   if (err.response.status >= 500) {
      return showMsgById("site_server_error");
   }

   return showMsgById("site_unknown_error");
}

function showMsgById(msgId, defaultMsgId) {
   //console.log("showMsgById:", msgId, defaultMsgId);

   let msgData = messagesData[msgId];
   if (!msgData) msgData = messagesData[defaultMsgId];
   if (!msgData) msgData = messagesData["site_unknown_error"];

   let msgText = msgData.text;
   let msgType = msgData.type;

   toast(msgText, { type: msgType });
}

function msg({ title, message, type, autoClose }) {
   if (!type) type = "info";
   if (autoClose === undefined) autoClose = false;

   //console[type]("message:", message);

   toast(message, { title, type, autoClose });
}

export default {
   responseSuccessById,
   responseErrorById,
   showMsgById,
   msg,
};
