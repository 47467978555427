import React, { Component } from "react";
import { Link } from "react-router-dom";

class PasswordReset extends Component {
   render() {
      const { data } = this.props.location;
      //console.log("data:", data);

      if (!data) {
         this.props.history.push("/");
      }

      return (
         <div className="page">
            <div className="page-narrow">
               <p className="page-title">Password Reset Email Sent</p>

               <p className="page-desc">An email was sent to:</p>

               <h4>{data}</h4>

               <Link to="/" className="btn btn-primary mt-3">
                  Home
               </Link>
            </div>
         </div>
      );
   }
}

export default PasswordReset;
