import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PasswordOld extends Component {
   state = {
      type: "password",
   };

   handleToggle() {
      const { type } = this.state;

      const newType = type === "text" ? "password" : "text";

      this.setState({ type: newType });
   }

   render() {
      const { name, isValid, label, value, error, onFocus, onChange, onBlur } = this.props;

      let classes = "xu-auto group-input";
      if (isValid === true) classes += " is-valid";
      if (isValid === false && error) classes += " is-invalid";

      const { type } = this.state;
      const icon = type === "text" ? "eye-slash" : "eye";

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>

            <div className="input-group">
               <input id={name} name={name} value={value} type={type} onFocus={onFocus} onChange={onChange} onBlur={onBlur} className={classes} autoComplete="current-password" />
               <div
                  className="xu-auto group-input input-group-append btn"
                  onClick={() => {
                     this.handleToggle();
                  }}
               >
                  <span>
                     <FontAwesomeIcon icon={icon} className="mx-auto" />
                  </span>
               </div>
            </div>
            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }
}

export default PasswordOld;
