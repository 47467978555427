import React, { Fragment } from "react";

import { Link, NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import userService from "services/userService";

import logoImg from "img/logos/xureal_horizontal_color_black.svg";

const NavBar = () => {
   const user = userService.getCurrentUser();

   const location = useLocation();
   let bkgrndClasses = "header-bkgrnd";
   bkgrndClasses += location.pathname.indexOf("user/account") !== -1 ? " header-bkgrnd-sidemenu" : "";

   const meetingsPage = location.pathname.indexOf("/meetings/");
   const btnStyles = meetingsPage !== -1 ? { opacity: "0", pointerEvents: "none" } : {};

   return (
      <div className="header">
         <div className={bkgrndClasses}></div>

         <nav className="navbar">
            <Link className="navbar-brand" to="/">
               <img src={logoImg} alt="Logo" width="100%" />
            </Link>

            <div className="navbar-nav">
               {user && (
                  <Fragment>
                     <NavLink className="nav-item btn btn-special btn-header" to="/meetings/details" style={btnStyles}>
                        Schedule a Meeting
                     </NavLink>

                     <NavLink className="nav-item nav-link" to="/meetings">
                        Meetings
                     </NavLink>

                     <NavLink className="nav-item nav-link" to="/user/account">
                        My Account
                     </NavLink>

                     <NavLink className="nav-item nav-link" to="/user/logout">
                        <FontAwesomeIcon icon="sign-out-alt" className="btn-icon" />
                        Sign Out
                     </NavLink>
                  </Fragment>
               )}

               {!user && (
                  <Fragment>
                     <NavLink className="nav-item nav-link" to="/user/signup">
                        Register
                     </NavLink>

                     <NavLink className="nav-item nav-link" to="/user/login">
                        <FontAwesomeIcon icon="sign-in-alt" className="btn-icon" />
                        Sign In
                     </NavLink>
                  </Fragment>
               )}
            </div>
         </nav>
      </div>
   );
};

export default NavBar;
