import io from "socket.io-client";

let myUserId = null;
let mySocketId = null;

let socket;

function connectSocket(socketParams) {
   //console.log("connectSocket() socketParams:", socketParams);

   //localStorage.debug = "*";

   if (socket) {
      socket.close();
      socket = null;
      mySocketId = null;
   }

   const { meetingId, userId, userFirstName, isHost } = socketParams;
   //console.log("meetingId:", meetingId);

   myUserId = userId;

   return new Promise((resolve, reject) => {
      let serverUrl = process.env.REACT_APP_SERVER_URL;
      //console.log("serverUrl:", serverUrl);

      socket = io.connect(serverUrl);

      socket.on("socket-connected", ({ socketId }) => {
         //console.log("socket-connected socketId:", socketId);

         mySocketId = socketId;

         sendRequest("join-meeting", { meetingId, userId, userFirstName, isHost });

         resolve(mySocketId);
      });

      // socket.on("meeting-joined", (roomData) => {
      //    console.log("meeting-joined", roomData);
      // });

      // socket.on("user-join", (userData) => {
      //    console.log("user-join userData:", userData);
      // });

      // socket.on("user-leave", (socketId) => {
      //    console.log("user-leave socketId:", socketId);
      // });

      // socket.on("host-join", (hostSocketId) => {
      //    console.log("host-join hostSocketId:", hostSocketId);
      // });

      // socket.on("room-updated", (aspectData) => {
      //    console.log("room-updated", aspectData);
      // });

      // socket.on("user-updated", (userData) => {
      //    console.log("user-updated", userData);
      // });

      // socket.on("chat-message", (data) => {
      //    // console.log("chat-message", data);
      //    // var msg = `[${msg.time}] - [${msg.nickname}]: ${msg.message}`;
      //    // clearTimeout(_timeout);
      //    // _timeout = setTimeout(resetTyping, 0);
      //    // $("#messages").append(//$("<li>").text(msg));
      // });

      socket.on("disconnect", function (evt) {
         //console.log("socket.io disconnect:", evt);
      });

      socket.on("error", function (err) {
         console.error("socket.io error:", err);
         reject(err);
      });
   });
}

function setRoomData(roomAspectData) {
   sendRequest("update-room", roomAspectData);
}

function setUserData(userAspectData) {
   userAspectData.userId = myUserId;
   sendRequest("update-user", userAspectData);
}

function setRoomContent(roomContent) {
   const roomAspectData = {};
   roomAspectData.name = "room-content";
   roomAspectData.value = roomContent;
   sendRequest("update-room", roomAspectData);
}

function setUserContent(userContent) {
   const userAspectData = {};
   userAspectData.name = "user-content";
   userAspectData.userId = myUserId;
   userAspectData.value = userContent;
   sendRequest("update-user", userAspectData);
}

function disconnectSocket() {
   if (socket) {
      socket.close();
      socket = null;
      mySocketId = null;
      //console.log("socket.io closed.");
   }
}

function isSocketConnected() {
   if (socket) {
      return true;
   } else {
      return false;
   }
}

function getMySocketId() {
   return mySocketId;
}

function sendRequest(type, data) {
   return new Promise((resolve, reject) => {
      if (socket) {
         socket.emit(type, data, (err, response) => {
            if (!err) {
               resolve(response);
            } else {
               reject(err);
            }
         });
      } else {
         console.error("Socket not connected.", type, data);
      }
   });
}

function relayMessage(type, callback) {
   //console.log("relayMessage() type:", type, "callback:", callback);

   if (!callback) throw new Error("missing callback:", callback);

   socket.on(type, function (message) {
      callback(message);
   });
}

export default {
   connectSocket,
   disconnectSocket,
   isSocketConnected,
   getMySocketId,
   sendRequest,
   relayMessage,
   setRoomData,
   setUserData,
   setRoomContent,
   setUserContent,
};
