import React, { Fragment } from "react";

import Form from "components/form/form";

import catalogService from "services/catalogService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-redeem.css";

import tokenImg from "img/token.png";

const CATALOG_IMG_PATH = "/img/catalog/";

class RoomRedeem extends Form {
   state = {
      itemData: null,
      sizeSelected: null,

      showConfirm: false,

      formData: {
         userFirstName: "",
         userLastName: "",
         userAddress1: "",
         userAddress2: "",
         userCity: "",
         userState: "",
         userZipCode: "",
      },
      isValid: {
         userFirstName: null,
         userLastName: null,
         userAddress1: null,
         userAddress2: null,
         userCity: null,
         userState: null,
         userZipCode: null,
      },
      errors: {},
   };

   async componentDidMount() {
      const { redeemItemId } = this.props;
      //console.log("redeemItemId:", redeemItemId);

      const catalogData = await catalogService.getCatalog();
      //console.log("catalogData:", catalogData);

      const itemData = catalogData.find((item) => item.id === redeemItemId);
      //console.log("itemData:", itemData);

      this.setState({ itemData });
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setRedeemProductId } = this.props;
      setRedeemProductId(null);
   };

   selectSize = (event) => {
      //console.log("selectSize() ");

      const sizeSelected = event.currentTarget.value;
      //console.log("sizeSelected:", sizeSelected);

      this.setState({ sizeSelected });
   };

   doSubmit = async () => {
      //console.log("doSubmit() ");

      this.setState({ showConfirm: true });
   };

   showOrder = (event) => {
      //console.log("showOrder() ");

      this.setState({ showConfirm: false });
   };

   placeOrder = (event) => {
      console.log("placeOrder() ");

      //const { formData } = this.state;
      //console.log("formData:", formData);
   };

   render() {
      const { itemData, showConfirm } = this.state;

      if (!itemData) return null;

      const { name, price, img, sizes } = itemData;

      const imgSrc = CATALOG_IMG_PATH + img;

      return (
         <Fragment>
            <div className="xu-modal">
               <div className="redeem-box">
                  <button className="side-close redeem-close" onClick={this.closeClick}>
                     <FontAwesomeIcon icon="times" className="icon" />
                  </button>

                  <div className="redeem-left redeem-side">
                     <div className="redeem-title">{name}</div>
                     <div className="redeem-image img-fit-width">
                        <img src={imgSrc} alt={name} />
                     </div>
                     <div className="redeem-price">
                        <div className="price-icon img-fit-height">
                           <img src={tokenImg} alt="XR Token" />
                        </div>
                        {price}
                     </div>
                  </div>

                  <div className="redeem-right redeem-side">
                     {showConfirm && this.renderConfirm()}
                     {!showConfirm && this.renderOrder(sizes)}
                  </div>
               </div>
            </div>
         </Fragment>
      );
   }

   renderOrder(sizes) {
      return (
         <Fragment>
            {sizes && sizes.length > 0 && (
               <div className="">
                  <div className="redeem-title">Size Preference</div>
                  <div className="redeem-sizes">{this.renderSizes()}</div>
               </div>
            )}

            <div className="">
               <div className="redeem-title">Shipping Address</div>
               <div className="redeem-form">{this.renderForm()}</div>
            </div>
         </Fragment>
      );
   }

   renderSizes() {
      const { itemData, sizeSelected } = this.state;
      const { sizes } = itemData;
      //console.log("sizes:", sizes);

      const html = [];

      for (const size of sizes) {
         let classes = "redeem-size";
         if (sizeSelected === size) classes += " selected";

         html.push(
            <button key={"size-" + size} className={classes} value={size} onClick={this.selectSize}>
               {size}
            </button>
         );
      }

      return html;
   }

   renderForm() {
      return (
         <form onSubmit={this.handleSubmit}>
            <div className="form-row">
               <div className="form-col pr-10">{this.renderInput("userFirstName", "First Name", "given-name")}</div>
               <div className="form-col pl-10">{this.renderInput("userLastName", "Last Name", "family-name")}</div>
            </div>

            <div className="form-row">
               <div className="form-col pr-10">{this.renderInput("userAddress1", "Address 1", "address-line1")}</div>
               <div className="form-col pl-10">{this.renderInput("userAddress2", "Address 2", "address-line2")}</div>
            </div>

            <div className="form-row">
               <div style={{ width: "58%" }} className="pr-10">
                  {this.renderInput("userCity", "City", "address-level2")}
               </div>
               <div style={{ width: "20%" }} className="pl-10 pr-10">
                  {this.renderInput("userState", "State", "address-level1")}
               </div>
               <div className="pl-10">{this.renderInput("userZipCode", "Zip Code", "postal-code")}</div>
            </div>

            <div className="redeem-btn">{this.renderButton("Redeem", null, "special")}</div>
         </form>
      );
   }

   renderConfirm() {
      const { formData, sizeSelected } = this.state;

      let address = formData.userFirstName + " " + formData.userLastName + "\n";
      address += formData.userAddress1 + "\n";
      if (formData.userAddress2) address += formData.userAddress2 + "\n";
      address += formData.userCity + ", " + formData.userState + " " + formData.userZipCode + "\n";

      return (
         <div className="redeem-confirm">
            <div>
               <div className="confirm-title">Confirmation</div>
               <div className="redeem-title">Please review your order below to confirm that all details are accurate before placing your order.</div>
            </div>

            {sizeSelected && (
               <div>
                  <div className="redeem-title">Size Preference</div>
                  <div className="confirm-mysize">{sizeSelected}</div>
               </div>
            )}

            <div>
               <div className="redeem-title">Shipping Address</div>
               <div className="confirm-address">{address}</div>
            </div>

            <div className="confirm-btns">
               <button className="btn btn-special" onClick={this.showOrder}>
                  Back
               </button>
               <button className="btn btn-special ml-20" onClick={this.placeOrder}>
                  Confirm
               </button>
            </div>
         </div>
      );
   }
}

export default RoomRedeem;
