import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import moment from "moment-timezone";

import FileUpload from "components/common/fileUpload";
import ColorPicker from "components/common/colorPicker";
import Topper from "components/meetings/topper";

import meetingService from "services/meetingService";
//import uploadService from "services/uploadService";
import msgService from "services/msgService";

import Form from "components/form/form";

import { INVITE_ELEMENTS, DATETIME_FORMAT_LONG } from "data/constants";
import { formatString } from "data/formatString";

import "../../css/page-meetings.css";

class MeetingInvite extends Form {
   state = {
      meetingId: null,
      inviteData: {},
      inviteImgs: {},
      currentPicker: null,
      formData: {
         inviteSubject: "",
         inviteMessage1: "",
         inviteMessage2: "",
         inviteBkgrndColor: "",
         inviteTextColor: "",
         inviteBtnColor: "",
         inviteBtnTextColor: "",
      },
      isValid: {
         inviteSubject: null,
         inviteMessage1: null,
         inviteMessage2: null,
         inviteBkgrndColor: null,
         inviteTextColor: null,
         inviteBtnColor: null,
         inviteBtnTextColor: null,
      },
      errors: {},
      // isLoaded: false,
   };

   async componentDidMount() {
      const meetingId = this.props.match.params.meetingId;
      //console.log("meetingId:", meetingId);

      // default values
      const formData = { ...INVITE_ELEMENTS };
      //console.log("formData:", formData);

      // saved values from database
      const inviteData = await meetingService.getInviteData(meetingId);
      //console.log("inviteData:", inviteData);
      for (let i in formData) {
         formData[i] = this.useData(inviteData[i], formData[i]);
      }
      //console.log("formData:", formData);

      const inviteImgs = { ...inviteData.inviteImgs };
      //console.log("inviteImgs:", inviteImgs);

      this.setState({ meetingId, inviteData, inviteImgs, formData });
      //this.setState({ meetingId, inviteData, inviteImgs, formData, isLoaded: true }, this.initialValidate());
   }

   useData(customValue, defaultValue) {
      return customValue ? customValue : defaultValue;
   }

   // initialValidate() {
   //console.log("initialValidate()");
   // if (!this.validated) {
   //    this.validated = true;
   //    this.validateForm();
   // }
   // }

   handleImageUploaded = (name, value) => {
      //console.log("handleImageUploaded(" + name + ", " + value + ")");

      const timestamp = new Date().getTime();

      const inviteImgs = { ...this.state.inviteImgs };
      inviteImgs[name] = value + "?" + timestamp;
      //console.log("inviteImgs:", inviteImgs);

      this.setState({ inviteImgs });
   };

   // currentPicker = null;

   // handlePickerOpen = (newPickerCallback) => {
   //    //console.log("handlePickerOpen()");

   //    if (this.currentPicker) {
   //       this.currentPicker();
   //    }

   //    this.currentPicker = newPickerCallback;
   // };

   handleColorChanged(color, prop) {
      //console.log("handleColorChanged() color:", color.hex, "prop:", prop);

      const formData = { ...this.state.formData };
      formData[prop] = color.hex;

      this.setState({ formData });
   }

   doSubmit = async () => {
      //console.log("doSubmit()");

      const { meetingId } = this.state;
      //console.log("meetingId:", meetingId);

      const inviteData = { ...this.state.formData };
      //console.log("inviteData:", inviteData);

      const response = await meetingService.saveInviteData(meetingId, inviteData);
      //console.log("response:", response);

      if (response && response.status === 200) {
         //window.location = "/meetings/participants/" + meetingId;
         this.props.history.push("/meetings/participants/" + meetingId);
      } else {
         msgService.responseErrorById(response, "meeting_update_error");
      }
   };

   render() {
      // const { isLoaded } = this.state;

      // if (!isLoaded) return null;

      return (
         <div className="page">
            <Topper currentPage="invite" />
            <div className="invite-btm">
               <div className="invite-left">
                  <div className="invite-side">{this.renderForm()}</div>
               </div>
               <div className="invite-right">
                  <div className="invite-side">{this.renderEmail()}</div>
               </div>
            </div>
         </div>
      );
   }

   renderForm() {
      const { meetingId, inviteImgs } = this.state;
      const { inviteBkgrndColor, inviteTextColor, inviteBtnColor, inviteBtnTextColor } = this.state.formData;

      const backLink = "/meetings/details/" + meetingId;

      return (
         <Fragment>
            <p className="page-eyebrow">SCHEDULE A VIRTUAL MEETING</p>

            <p className="page-title">Meeting Invite Email</p>

            <p className="page-desc">Customize the meeting invite email that will be sent to the participants.</p>

            <form onSubmit={this.handleSubmit}>
               {this.renderInput("inviteSubject", "Email Subject")}

               <div className="form-row">
                  <div className="form-col pr-10">
                     <FileUpload name="logo" value={inviteImgs.logo} label="Upload Logo" meetingId={meetingId} onFileUploaded={this.handleImageUploaded} />
                  </div>
                  <div className="form-col pl-10">
                     <FileUpload name="hero" value={inviteImgs.hero} label="Upload Hero Image" meetingId={meetingId} onFileUploaded={this.handleImageUploaded} />
                  </div>
               </div>

               {this.renderInput("inviteMessage1", "Email Message Title")}
               {this.renderTextarea("inviteMessage2", "Email Message to Participants", 2)}

               <div className="form-row">
                  <div className="form-col pr-10">
                     <ColorPicker
                        label={"Email Background Color"}
                        name={"inviteBkgrndColor"}
                        color={inviteBkgrndColor}
                        // onPickerOpen={this.handlePickerOpen}
                        onChangeComplete={(color) => {
                           this.handleColorChanged(color, "inviteBkgrndColor");
                        }}
                     />
                  </div>
                  <div className="form-col pl-10">
                     <ColorPicker
                        label={"Email Text Color"}
                        name={"inviteTextColor"}
                        color={inviteTextColor}
                        // onPickerOpen={this.handlePickerOpen}
                        onChangeComplete={(color) => {
                           this.handleColorChanged(color, "inviteTextColor");
                        }}
                     />
                  </div>
               </div>

               <div className="form-row">
                  <div className="form-col pr-10">
                     <ColorPicker
                        label={"Email Join Button Color"}
                        name={"inviteBtnColor"}
                        color={inviteBtnColor}
                        // onPickerOpen={this.handlePickerOpen}
                        onChangeComplete={(color) => {
                           this.handleColorChanged(color, "inviteBtnColor");
                        }}
                     />
                  </div>
                  <div className="form-col pl-10">
                     <ColorPicker
                        label={"Email Join Button Text Color"}
                        name={"inviteBtnTextColor"}
                        color={inviteBtnTextColor}
                        // onPickerOpen={this.handlePickerOpen}
                        onChangeComplete={(color) => {
                           this.handleColorChanged(color, "inviteBtnTextColor");
                        }}
                     />
                  </div>
               </div>

               <div className="form-row form-btns">
                  <div className="form-col">
                     <Link to={backLink} className="btn btn-secondary">
                        Back
                     </Link>
                  </div>

                  <div className="form-col align-right">{this.renderButton("Save and Continue")}</div>
               </div>
            </form>
         </Fragment>
      );
   }

   renderEmail() {
      const { inviteData, inviteImgs } = this.state;
      const { meetingDateTime, meetingTimezone } = inviteData;
      const { inviteBkgrndColor, inviteTextColor, inviteMessage1, inviteMessage2, inviteBtnColor, inviteBtnTextColor } = this.state.formData;

      //console.log("inviteImgs", inviteImgs);

      let inviteDateTime = "";

      if (meetingTimezone) {
         const timezoneDisplay = formatString("meetingTimezone", meetingTimezone);
         inviteDateTime = moment.tz(meetingDateTime, meetingTimezone).format(DATETIME_FORMAT_LONG);
         inviteDateTime += " (" + timezoneDisplay + ")";
         //console.log("inviteDateTime", inviteDateTime);
      }

      return (
         <div className="invite-email">
            <div
               style={{
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: inviteBkgrndColor,
                  color: inviteTextColor,
                  fontSize: "14px",
               }}
            >
               <div style={{ maxWidth: "640px", margin: "auto", overflow: "auto" }}>
                  {inviteImgs.logo && <img src={inviteImgs.logo} style={{ width: "170px", display: "block", margin: "40px auto" }} alt="Logo" />}
                  {inviteImgs.hero && <img src={inviteImgs.hero} style={{ width: "510px", display: "block", margin: "40px auto" }} alt="Hero" />}

                  <div style={{ margin: "5px auto", letterSpacing: "4px" }}>{inviteMessage1}</div>
                  <div style={{ margin: "5px auto", fontSize: "32px", fontWeight: "700", letterSpacing: "1px" }}>{inviteMessage2}</div>

                  <div style={{ margin: "20px auto", fontSize: "16px" }}>{inviteDateTime}</div>

                  <div
                     style={{
                        display: "inline-block",
                        padding: "10px 20px",
                        margin: "20px auto",
                        backgroundColor: inviteBtnColor,
                        color: inviteBtnTextColor,
                        fontWeight: "700",
                     }}
                  >
                     JOIN EVENT
                  </div>

                  {inviteImgs.xureal && (
                     <Fragment>
                        <div style={{ margin: "20px auto 10px", fontSize: "12px" }}>POWERED BY</div>
                        <img src={process.env.REACT_APP_SERVER_URL + "/img/email/xureal.png"} style={{ width: "100px", display: "block", margin: "0 auto 40px" }} alt="Xureal" />
                     </Fragment>
                  )}
               </div>
            </div>
         </div>
      );
   }
}

export default MeetingInvite;
