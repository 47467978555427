import React, { Component } from "react";

//import path from "path";

import uploadService from "services/uploadService";

class FileUpload extends Component {
   state = {
      fileName: "",
      uploadProgress: 0,
   };

   inputRef = React.createRef();

   componentDidMount() {}

   componentWillUnmount() {}

   preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
   }

   handleSubmit = (e) => {
      //console.log("handleSubmit");

      this.preventDefaults(e);

      const { files } = this.inputRef.current;
      console.log("files:", files);

      this.uploadFiles(files);
   };

   uploadFiles = (files) => {
      //console.log("uploadFiles()", files);

      const { name, meetingId } = this.props;

      const fileData = files[0];
      fileData.meetingId = meetingId;
      fileData.filePurpose = name;
      fileData.progressCallback = this.uploadFileProgress;

      uploadService.uploadFile(fileData);

      const fileName = fileData.name;
      //console.log("fileName:", fileName);

      this.setState({ fileName });
   };

   uploadFileProgress = (fileData) => {
      //console.log("uploadFileProgress:", fileData.progress);

      const uploadProgress = Math.round(fileData.progress);
      //console.log("uploadProgress:", uploadProgress);

      // if (uploadProgress === 100) {
      //    const { name, onFileUploaded } = this.props;
      //    onFileUploaded(name, "");
      // }

      if (uploadProgress === 100 && fileData.inviteImgs) {
         this.fileUploaded(fileData.inviteImgs);
      }

      this.setState({ uploadProgress });
   };

   fileUploaded = (inviteImgs) => {
      //console.log("fileUploaded() fileData:", inviteImgs);

      setTimeout(() => {
         this.setState({ uploadProgress: 0 });
      }, 500);

      const { name, onFileUploaded } = this.props;

      const value = inviteImgs[name];
      //console.log("value:", value);

      onFileUploaded(name, value);
   };

   truncateString(str, num) {
      if (str.length <= num) {
         return str;
      }

      return str.slice(0, num) + "...";
   }

   render() {
      const { label, name } = this.props;
      const { multiple, fileTypes } = this.props.options;

      const { uploadProgress, fileName } = this.state;
      //console.log("uploadProgress:", uploadProgress);

      const error = null;

      // let fileName = "";
      // if (value) {
      //    fileName = path.basename(value.split("?")[0]);
      //    //console.log("value:", value, "fileName:", fileName);
      // }

      let classes = "group-input upload-wrapper";
      //if (isValid === true) classes += " is-valid";
      //if (isValid === false && error) classes += " is-invalid";

      let barClasses = "upload-box-progress";
      if (uploadProgress >= 100) {
         barClasses += " upload-striped";
      }

      //const showName = truncateString(fileName, 10);

      return (
         <div className="form-group">
            <label className="group-label" htmlFor={name}>
               {label}
            </label>

            <div className={classes}>
               <div className="upload-left">
                  <div className="upload-filename">{fileName}</div>
               </div>
               <div className="upload-right">
                  <div className="upload-browse">Browse</div>
               </div>

               <input type="file" id={name} multiple={multiple} accept={fileTypes} ref={this.inputRef} onChange={this.handleSubmit} />

               <div className="upload-box">
                  <div style={{ width: uploadProgress + "%" }} className={barClasses}></div>
               </div>
            </div>

            {error && <div className="group-feedback">{error}</div>}
         </div>
      );
   }
}

FileUpload.defaultProps = {
   label: "File",
   options: {
      multiple: false,
      fileTypes: ".jpg, .png, image/png, image/jpeg",
   },
};

export default FileUpload;
