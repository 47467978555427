import React from "react";
import { Link, Redirect } from "react-router-dom";

import moment from "moment-timezone";

import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

class SignupForm extends Form {
   state = {
      formData: {
         email: "",
         passwordNew: "",
         userFirstName: "",
         userLastName: "",
      },
      isValid: {
         email: null,
         passwordNew: null,
         userFirstName: null,
         userLastName: null,
      },
      errors: {},
   };

   doSubmit = async (e) => {
      //console.log("handleSubmit(e)");

      try {
         const { formData } = this.state;
         //console.log("formData:", formData);

         formData.userTimezone = moment.tz.guess();
         //console.log("userTimezone:", formData.userTimezone);

         const response = await userService.signup(formData);

         const token = response.headers["x-auth-token"];
         userService.loginWithJwt(token);

         this.props.history.push("/meetings");
      } catch (err) {
         msgService.responseErrorById(err, "user_signup_error");
      }
   };

   render() {
      if (userService.getCurrentUser()) return <Redirect to="/" />;

      return (
         <div className="page">
            <div className="page-narrow">
               <p className="page-title">Create an Account</p>

               <p className="page-desc">
                  Already have an account? &nbsp;
                  <Link to="/user/login" className="btn-link">
                     Sign in
                  </Link>
               </p>

               <form onSubmit={(e) => this.handleSubmit(e)}>
                  {this.renderInput("userFirstName", "First Name", "given-name")}
                  {this.renderInput("userLastName", "Last Name", "family-name")}
                  {this.renderInput("email", "Email", "email", false, true)}
                  {this.renderPasswordNew("passwordNew", "Password", "current-password")}

                  <div className="small">
                     By clicking Signup, you agree to the &nbsp;
                     <Link to="/legal" className="btn btn-link f-normal">
                        Privacy &amp; Legal Policies
                     </Link>
                     .
                  </div>
                  <div className="form-group">{this.renderButton("Signup")}</div>
               </form>
            </div>
         </div>
      );
   }
}

export default SignupForm;
