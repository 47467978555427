import React, { Fragment } from "react";

import SideNav from "components/nav/sideNav";
import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-account.css";

const timezonesOptions = require("data/timezones.json");

class AccountForm extends Form {
   navItems = [
      { name: "Basic Info", icon: "address-card", value: "/user/account/info" },
      { name: "Email Address", icon: "envelope", value: "/user/account/email" },
      { name: "Change Password", icon: "key", value: "/user/account/password" },
      { name: "Zoom", icon: "video", value: "/user/account/zoom" },
      { name: "Account Deletion", icon: "trash", value: "/user/account/delete" },
   ];

   state = {
      selectedNav: "",
      formData: {
         userFirstName: "",
         userLastName: "",
         userTimezone: "",
      },
      isValid: {
         userFirstName: null,
         userLastName: null,
         userTimezone: null,
      },
      errors: {},
      isLoaded: false,
   };

   async componentDidMount() {
      const user = userService.getCurrentUser();
      if (!user) return;

      const { data } = await userService.getAccount();
      const formData = this.state.formData;

      for (const [key, value] of Object.entries(data)) {
         if (formData[key] !== undefined) formData[key] = value;
      }

      this.setState({ formData, isLoaded: true });
   }

   doSubmit = async () => {
      try {
         const user = userService.getCurrentUser();
         const { userFirstName, userLastName, userTimezone } = this.state.formData;

         const submitData = {
            _id: user._id,
            form: "account",
            userFirstName,
            userLastName,
            userTimezone,
         };

         const res = await userService.saveAccount(submitData);
         msgService.responseSuccessById(res, "user_account_updated");
      } catch (err) {
         msgService.responseErrorById(err, "user_account_error");
      }
   };

   render() {
      return (
         <div className="account-page">
            <div className="sidemenu">
               <SideNav items={this.navItems} />
            </div>

            <div className="account-content">
               <div className="page-narrow">{this.renderContent()}</div>
            </div>
         </div>
      );
   }

   renderContent() {
      const { userTimezone } = this.state.formData;

      return (
         <Fragment>
            <p className="page-eyebrow">MY ACCOUNT</p>

            <p className="page-title">Basic Info</p>

            {this.state.isLoaded && (
               <form onSubmit={this.handleSubmit}>
                  {this.renderInput("userFirstName", "First Name", "given-name")}
                  {this.renderInput("userLastName", "Last Name", "family-name")}
                  {this.renderSelect("userTimezone", "Timezone", timezonesOptions, userTimezone)}

                  <div className="account-btns">{this.renderButton("Update Info")}</div>
               </form>
            )}
         </Fragment>
      );
   }
}

export default AccountForm;
