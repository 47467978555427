import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

class ResetRequest extends Form {
   state = {
      formData: {
         email: "",
      },
      isValid: {
         email: null,
      },
      errors: {},
   };

   doSubmit = async (e) => {
      //console.log("handleSubmit(e)");

      try {
         const { email } = this.state.formData;
         console.log("email:", email);

         const { data } = await userService.reset(email);
         console.log(data);

         this.props.history.push({
            pathname: "/user/reset/sent",
            data: email,
         });
      } catch (err) {
         msgService.responseErrorById(err, "forgot_password_error");
      }
   };

   render() {
      if (userService.getCurrentUser()) return <Redirect to="/" />;

      return (
         <div className="page">
            <div className="page-narrow">
               <p className="page-title">Password Reset Request</p>

               <p className="page-desc">Enter the email address associated with your account, and we’ll send you a link to reset your password.</p>

               <form onSubmit={(e) => this.handleSubmit(e)}>
                  {this.renderInput("email", "Email", "email")}

                  <div className="form-row form-btns">
                     <div className="form-col">
                        <div className="align-left">{this.renderButton("Email Reset Link")}</div>
                     </div>

                     <div className="form-col align-right">
                        <Link className="mx-2 btn btn-secondary" to="/user/login">
                           Back to Sign in
                        </Link>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default ResetRequest;
