import React, { Fragment } from "react";

import SideNav from "components/nav/sideNav";
import Form from "components/form/form";

import Modal from "components/modal/modal";

import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-account.css";

class AccountForm extends Form {
   navItems = [
      { name: "Basic Info", icon: "address-card", value: "/user/account/info" },
      { name: "Email Address", icon: "envelope", value: "/user/account/email" },
      { name: "Change Password", icon: "key", value: "/user/account/password" },
      { name: "Zoom", icon: "video", value: "/user/account/zoom" },
      { name: "Account Deletion", icon: "trash", value: "/user/account/delete" },
   ];

   state = {
      selectedNav: "",
      formData: {
         passwordOld: "",
      },
      isValid: {
         passwordOld: null,
      },
      errors: {},
      modalData: {},
   };

   showModalConfirmDelete = (e) => {
      //console.log("showModalConfirmDelete()");

      const modalData = {
         show: true,
         type: "modal",
         showClose: true,
         onClose: (e) => this.handleModalClose(e),
         title: "Confirm Account Deletion",
         text: "Are you sure you want to delete your account? This action is permanent and irreversible.",
         buttons: [
            {
               text: "Cancel",
               type: "secondary",
               onClose: (e) => this.handleModalClose(e),
            },
            {
               text: "Delete Account",
               type: "danger",
               //icon: "trash",
               onClick: (e) => this.handleSubmit(e),
            },
         ],
      };
      this.setState({ modalData });
   };

   handleModalClose = () => {
      //console.log("handleModalClose()");

      this.setState({ modalData: null });
   };

   handleSubmit = async (e) => {
      //console.log("handleSubmit()");

      const { passwordOld } = this.state.formData;

      const submitData = {
         passwordOld,
      };

      try {
         const { data } = await userService.deleteUser(submitData);
         console.log(data);

         if (data === "user_deleted") {
            this.props.history.push("/user/logout");
         } else {
            msgService.responseErrorById(data, "user_delete_error");
         }
      } catch (err) {
         msgService.responseErrorById(err, "user_delete_error");
      }
   };

   render() {
      const { modalData } = this.state;

      return (
         <div className="account-page">
            <div className="sidemenu">
               <SideNav items={this.navItems} />
            </div>

            <div className="account-content">
               <div className="page-narrow">{this.renderContent()}</div>
            </div>

            {modalData && <Modal modalData={modalData} />}
         </div>
      );
   }

   renderContent() {
      return (
         <Fragment>
            <p className="page-eyebrow">MY ACCOUNT</p>

            <p className="page-title">Delete Xureal Account</p>

            <p>Deleting your Xureal account is permanent, however it won't affect your Zoom account or any of the meetings you've scheduled. You can create a new Xureal account and reconnect to Zoom.</p>

            {this.renderPasswordOld("passwordOld", "Password")}

            <div className="account-btns">
               <button className="btn btn-danger" disabled={this.validateAll()} onClick={(e) => this.showModalConfirmDelete(e)}>
                  Delete Xureal Account
               </button>
            </div>
         </Fragment>
      );
   }
}

export default AccountForm;
