import React, { Component } from "react";

import socketService from "services/socketService";
import jitsiService from "services/jitsiService";

import RoomXurealSettings from "components/room/windows/roomXurealSettings";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-xureal.css";

class RoomXureal extends Component {
   state = {
      showSettingsModal: false,
      platformSettings: {
         audioInputDeviceId: "",
         audioOutputDeviceId: "",
         videoInputDeviceId: "",
      },

      audioIsMuted: false,
      videoIsMuted: false,

      dominantUserId: null,

      useBigThumbs: true,

      // settingsMenuOptions: [],
      shareStatus: null,
   };

   hostUserId;
   myUserId;
   myParticipantId;

   myAudioTrack;
   myVideoTrack;
   myVideoType;

   bigThumbHeight;
   secondaryThumbsTotal;

   async componentDidMount() {
      const { meetingId } = this.props.roomData;
      if (!meetingId) throw new Error("meetingId is missing");
      this.meetingId = meetingId;

      const { hostUserId } = this.props.roomData;
      if (!hostUserId) throw new Error("hostUserId is missing");
      this.hostUserId = hostUserId;

      const { userId: myUserId } = this.props.userData;
      if (!myUserId) throw new Error("myUserId is missing");
      this.myUserId = myUserId;

      console.log("meetingId:", meetingId, "myUserId:", myUserId, "hostUserId:", hostUserId);

      this.myAudioTrack = null;
      this.myVideoTrack = null;

      this.eventListeners("add");
      this.updateShareButton(this.myVideoType);

      await jitsiService.initPlatform({ meetingId, userId: myUserId });
   }

   eventListeners(action) {
      //console.log("eventListeners()");

      window[action + "EventListener"]("resize", this.onUpdateVideoSizes);

      window[action + "EventListener"]("participant-updated", this.onParticipantUpdated);
      window[action + "EventListener"]("conference-joined", this.onConferenceJoined);
      // window[action+"EventListener"]("participant-joined", this.onParticipantJoin);
      // window[action+"EventListener"]("participant-leave", this.onParticipantLeave);
      window[action + "EventListener"]("attach-mediatrack", this.onAttachMediaTrack);
      window[action + "EventListener"]("detach-mediatrack", this.onDetachMediaTrack);
      window[action + "EventListener"]("trackmute-changed", this.onTrackMuteChanged);
      window[action + "EventListener"]("localvideotype-changed", this.onLocalVideoTypeChanged);
      window[action + "EventListener"]("dominant-participant", this.onDominantSpeakerChanged);
   }

   componentDidUpdate() {
      this.onUpdateVideoSizes();
   }

   componentWillUnmount() {
      jitsiService.unloadPlatform();
      this.eventListeners("remove");
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   /* WINDOW EVENTS */

   onUpdateVideoSizes = () => {
      if (!this.bigThumbHeight) {
         const videoEl = document.getElementById("video-user-me");
         if (!videoEl) return;

         const style = videoEl.currentStyle || window.getComputedStyle(videoEl);
         const thumbHeight = videoEl.offsetHeight;
         const marginTop = parseInt(style.marginTop, 10);
         const marginBottom = parseInt(style.marginBottom, 10);
         //console.log("thumbHeight:", thumbHeight, "marginTop:", marginTop, "marginBottom:", marginBottom);

         this.bigThumbHeight = thumbHeight + marginTop + marginBottom;
      }

      const availHeight = document.getElementById("video-users-secondary").offsetHeight - 15;
      const maxThumbs = Math.floor(availHeight / this.bigThumbHeight);
      const newUseBigThumbs = this.secondaryThumbsTotal <= maxThumbs ? true : false;
      //console.log("maxThumbs:", maxThumbs, "secondaryThumbsTotal:", this.secondaryThumbsTotal, "newUseBigThumbs:", newUseBigThumbs);

      const { useBigThumbs } = this.state;

      if (useBigThumbs !== newUseBigThumbs) {
         this.setState({ useBigThumbs: newUseBigThumbs });
      }
   };

   /* VIDEO SERVICE EVENTS */

   onParticipantUpdated = ({ detail: { myParticipantId } }) => {
      //console.log("onParticipantUpdated() myParticipantId:", myParticipantId);

      this.platformIsConnected = true;

      const { usersList } = this.props;
      const userData = usersList.find((user) => user.userId === this.myUserId);
      userData.participantId = myParticipantId;

      this.myParticipantId = myParticipantId;

      socketService.setUserData({ name: "participantId", value: myParticipantId });
   };

   onConferenceJoined = () => {
      //console.log("onConferenceJoined()");
      this.updateShareButton(this.myVideoType);
   };

   // onParticipantJoin = ({ detail: { jitsiUser } }) => {
   //    //console.log("onParticipantJoin() jitsiUser:", jitsiUser);

   //    this.addParticipant(jitsiUser);
   // };

   // onParticipantLeave = ({ detail: { jitsiUser } }) => {
   //    //console.log("!!!!! onParticipantLeave() jitsiUser:", jitsiUser);

   //    this.removeParticipant(jitsiUser);
   // };

   onAttachMediaTrack = async ({ detail: { mediaTrack } }) => {
      //console.log("onAttachMediaTrack() mediaTrack:", mediaTrack);

      this.attachMediaTrack(mediaTrack);
   };

   onDetachMediaTrack = ({ detail: { mediaTrack } }) => {
      //console.log("onDetachMediaTrack() mediaTrack:", mediaTrack);

      this.removeMediaTrack(mediaTrack);
   };

   onTrackMuteChanged = ({ detail: { mediaTrack } }) => {
      //console.log("onTrackMuteChanged() mediaTrack:", mediaTrack);

      this.trackMuteChanged(mediaTrack);
   };

   onLocalVideoTypeChanged = ({ detail: { localVideoDeviceType } }) => {
      //console.log("onLocalVideoTypeChanged() localVideoDeviceType:", localVideoDeviceType);

      this.myVideoType = localVideoDeviceType;
      this.updateShareButton(this.myVideoType);
   };

   onDominantSpeakerChanged = ({ detail: { participantData } }) => {
      //console.log("onDominantSpeakerChanged() participantData:", participantData);

      this.setState({ dominantUserId: participantData.userId });
   };

   setVideoStatus = (newStatus) => {
      const { setVideoStatus } = this.props;
      setVideoStatus(newStatus);
   };

   /* AUDIO & VIDEO BUTTONS */

   toggleAudioMute = async () => {
      //console.log("toggleAudioMute()");

      const { audioIsMuted } = this.state;

      // socketService.setUserData({
      //    name: "audio-muted",
      //    value: !audioIsMuted,
      // });

      if (this.myAudioTrack) {
         if (!audioIsMuted) {
            this.myAudioTrack.mute();
            // this.myAudioTrack.track.enabled = false;
         } else {
            this.myAudioTrack.unmute();
            // this.myAudioTrack.track.enabled = true;
         }
      }
      // console.log("myAudioTrack", this.myAudioTrack, "audioIsMuted:", !audioIsMuted);

      this.setState({ audioIsMuted: !audioIsMuted });
   };

   toggleVideoMute = () => {
      //console.log("toggleVideoMute()");

      const { videoIsMuted } = this.state;

      // socketService.setUserData({
      //    name: "video-muted",
      //    value: !videoIsMuted,
      // });

      if (this.myVideoTrack) {
         if (!videoIsMuted) {
            this.myVideoTrack.mute();
            // this.myVideoTrack.track.enabled = false;
         } else {
            this.myVideoTrack.unmute();
            // this.myVideoTrack.track.enabled = true;
         }
      }

      const videoStatus = !videoIsMuted ? "off" : "on";
      this.setVideoStatus(videoStatus);

      this.setState({ videoIsMuted: !videoIsMuted });
   };

   /* SETTINGS MENU BUTTON */

   updateShareButton = (deviceType) => {
      //console.log("updateShareButton() deviceType:", deviceType);

      const sharingEnabled = jitsiService.isDesktopSharingEnabled();
      //console.log("sharingEnabled:", sharingEnabled);

      let shareStatus;

      if (deviceType) {
         shareStatus = deviceType;
      }
      if (deviceType === "camera" && !sharingEnabled) {
         shareStatus = "disabled";
      }
      if (!deviceType && sharingEnabled) {
         shareStatus = "camera";
      }
      //console.log("shareStatus:", shareStatus);

      this.setState({ shareStatus });
   };

   toggleSettingsMenu = () => {
      //console.log("toggleSettingsMenu()");

      const { showSettingsModal } = this.state;

      this.setState({ showSettingsModal: !showSettingsModal });
   };

   settingsMenuOptionSelected = (callback) => {
      //console.log("settingsMenuOptionSelected() callback:", callback);

      this[callback]();
   };

   toggleVideoType = () => {
      //console.log("toggleVideoType()");

      jitsiService.setVideoType();
   };

   updatePlatformSettings = (newSettings) => {
      //console.log("updatePlatformSettings() newSettings:", newSettings);

      // const { audioInputDeviceId, audioOutputDeviceId, videoInputDeviceId } = newSettings;
      // console.log("audioInputDeviceId:", audioInputDeviceId, "audioOutputDeviceId:", audioOutputDeviceId, "videoInputDeviceId:", videoInputDeviceId);

      jitsiService.updateLocalTracks(newSettings);

      this.setState({ showSettingsModal: false });
   };

   /* LEAVE BUTTON */

   // leaveConference = () => {
   //    //console.log("leaveConference()");

   //    jitsiService.unloadPlatform();

   //    const { leaveMeeting } = this.props;
   //    leaveMeeting();
   // };

   /* MEDIA TRACKS */

   addRefs(userData) {
      //console.log("addRefs() userData:", userData);

      userData.refs = {};
      userData.refs["audio"] = React.createRef();
      userData.refs["video"] = React.createRef();

      const mediaTracks = jitsiService.getParticipantTracks(userData.participantId);
      if (!mediaTracks) return;

      for (const [trackType, mediaTrack] of Object.entries(mediaTracks)) {
         this.attachMediaTrack(mediaTrack, trackType);
      }
   }

   async attachMediaTrack(mediaTrack) {
      //console.log("attachMediaTrack() mediaTrack:", mediaTrack);

      const { addedToUser, userId, deviceType } = mediaTrack;
      if (addedToUser) return console.log("ADDED ALREADY addedToUser:", addedToUser);

      const usersList = [...this.props.usersList];
      const userData = usersList.find((user) => userId === user.userId);
      if (!userData) return console.log("UNKNOWN userId:", userId);

      const isLocal = mediaTrack.isLocal();
      const trackType = mediaTrack.getType();
      //console.log("!!!!! isLocal:", isLocal, "trackType:", trackType, "deviceType:", deviceType);

      if (!userData.refs) this.addRefs(userData);
      if (!userData.tracks) userData.tracks = {};

      if (userData.tracks[trackType]) {
         this.removeMediaTrack(userData.tracks[trackType]);
      }
      userData.tracks[trackType] = mediaTrack;

      mediaTrack.addedToUser = true;
      //console.log("ADDED:", trackType, "for:", userData.userFirstName, userId);

      if (trackType === "video" || !isLocal) {
         mediaTrack.attach(userData.refs[trackType].current);
      }

      if (deviceType === "desktop" && !isLocal) {
         this.props.setScreenShare("add", mediaTrack);
         //console.log("FULLSCREEN:", trackType, deviceType, "for:", userData.userFirstName, userId);
         this.updateShareButton("remote");
      }

      if (isLocal) {
         const { audioIsMuted, videoIsMuted } = this.state;

         if (trackType === "audio") {
            this.myAudioTrack = mediaTrack;
            audioIsMuted ? await mediaTrack.mute() : await mediaTrack.unmute();
         } else {
            this.myVideoTrack = mediaTrack;
            videoIsMuted ? await mediaTrack.mute() : await mediaTrack.unmute();

            const videoStatus = videoIsMuted ? "off" : "on";
            this.setVideoStatus(videoStatus);
         }
      }

      if (trackType === "video") {
         userData.videoIsMuted = mediaTrack.isMuted();
      } else if (trackType === "audio") {
         userData.audioIsMuted = mediaTrack.isMuted();
      }

      const { setUserData } = this.props;
      setUserData(userData);
   }

   trackMuteChanged(mediaTrack) {
      //console.log("trackMuteChanged() mediaTrack:", mediaTrack);

      const { userId } = mediaTrack;
      const trackType = mediaTrack.getType();
      const isMuted = mediaTrack.isMuted();
      //console.log("userId:", userId, "trackType:", trackType, "isMuted:", isMuted);

      const { usersList } = this.props;
      const userData = usersList.find((user) => userId === user.userId);
      if (!userData) return console.log("UNKNOWN userId:", userId);

      userData[trackType + "IsMuted"] = isMuted;

      const { setUserData } = this.props;
      setUserData(userData);
   }

   async removeMediaTrack(mediaTrack) {
      //console.log("removeMediaTrack() mediaTrack:", mediaTrack);

      if (!mediaTrack) return;

      const { participantId, deviceType } = mediaTrack;
      const isLocal = mediaTrack.isLocal();
      const trackType = mediaTrack.getType();

      if (deviceType === "desktop" && !isLocal) {
         this.props.setScreenShare("remove", mediaTrack);
         //console.log("THUMBNAIL:", trackType, deviceType, "for:", mediaTrack.userId);
         this.updateShareButton(this.myVideoType);
      }

      const usersList = [...this.props.usersList];
      const userData = usersList.find((user) => participantId === user.participantId);
      if (!userData) {
         //console.log("User already removed participantId:", participantId);
         return;
      }

      mediaTrack.detach(userData.refs[trackType].current);
      //console.log("DETACHED:", trackType, "for:", userData.userFirstName, userData.userId);

      userData.tracks[trackType] = null;

      const { setUserData } = this.props;
      setUserData(userData);
   }

   /* RENDER */

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Video</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon={"times"} className="icon" />
               </button>
            </div>

            <div className="side-body">{this.renderVideo()}</div>
         </div>
      );
   }

   renderVideo() {
      const { audioIsMuted, videoIsMuted, showSettingsModal, platformSettings, shareStatus } = this.state;
      const audioIcon = audioIsMuted ? "microphone-slash" : "microphone";
      const videoIcon = videoIsMuted ? "video-slash" : "video";

      const { primaryUsersHtml, secondaryUsersHtml } = this.renderUsers();

      let shareClasses = "video-ctrl-btn video-ctrl-btn-video";
      if (!shareStatus || shareStatus === "disabled" || shareStatus === "remote") shareClasses += " disabled";

      let shareIcon, shareText;
      if (shareStatus === "desktop") {
         shareIcon = "webcam";
         shareText = "Share";
      } else {
         shareIcon = "desktop";
         shareText = "Share";
      }
      // console.log("shareStatus:", shareStatus);

      return (
         <div className="room-video">
            <div id="video-users">
               <div id="video-users-primary" className="video-users">
                  {primaryUsersHtml}
               </div>
               <div id="video-users-secondary" className="video-users">
                  {secondaryUsersHtml}
               </div>
            </div>

            <div id="video-ctrls">
               <div className="video-ctrl-btn" onClick={this.toggleAudioMute}>
                  <FontAwesomeIcon icon={audioIcon} className="video-ctrl-btn-icon" />
                  <div className="video-ctrl-btn-txt">Audio</div>
               </div>

               <div className="video-ctrl-btn video-ctrl-btn-video" onClick={this.toggleVideoMute}>
                  <FontAwesomeIcon icon={videoIcon} className="video-ctrl-btn-icon" />
                  <div className="video-ctrl-btn-txt">Video</div>
               </div>

               <div className={shareClasses} onClick={this.toggleVideoType}>
                  <FontAwesomeIcon icon={shareIcon} className="video-ctrl-btn-icon" />
                  <div className="video-ctrl-btn-txt">{shareText}</div>
               </div>

               <div className="video-ctrl-btn video-ctrl-btn-video" onClick={this.toggleSettingsMenu}>
                  <FontAwesomeIcon icon={"cog"} className="video-ctrl-btn-icon" />

                  <div className="video-ctrl-btn-txt">Settings</div>
               </div>

               {/* <RoomXurealOptions name="xu-options" options={settingsMenuOptions} onChange={this.settingsMenuOptionSelected}></RoomXurealOptions> */}

               {/* <div className="video-ctrl-btn" onClick={this.leaveConference}>
                  <FontAwesomeIcon icon="door-open" className="video-ctrl-btn-icon" />
                  <div className="video-ctrl-btn-txt">Leave</div>
               </div> */}
            </div>
            {showSettingsModal && <RoomXurealSettings platformSettings={platformSettings} submitHandler={this.updatePlatformSettings} />}
         </div>
      );
   }

   renderUsers() {
      let primaryUsersHtml = [];
      let secondaryUsersHtml = [];

      const { usersList } = this.props;
      if (!usersList || usersList.length === 0) return [primaryUsersHtml, secondaryUsersHtml];

      usersList.sort((a, b) => {
         return a.priority - b.priority;
      });
      //console.log("usersList", usersList);

      this.secondaryThumbsTotal = 0;

      for (const user of usersList) {
         const { isLocal, isHost } = user;

         const html = this.renderUser(user);

         if (isLocal || isHost) {
            primaryUsersHtml.push(html);
         } else {
            this.secondaryThumbsTotal++;
            secondaryUsersHtml.push(html);
         }
      }

      return { primaryUsersHtml, secondaryUsersHtml };
   }

   renderUser(userData) {
      //console.log("renderUser() user:", user);

      const { useBigThumbs, dominantUserId } = this.state;
      const { socketId, isLocal, isHost, userFirstName, userId } = userData;
      //console.log("isLocal", isLocal, "isHost", isHost);

      let classes = "video-user";
      if (isLocal) classes += " me";
      if (isHost) classes += " host";

      if (isLocal || isHost) {
         classes += " primary";
      } else if (useBigThumbs) {
         classes += " primary";
      }

      //console.log("userId", userId, "dominantUserId", dominantUserId);
      if (userId === dominantUserId) {
         classes += " dominant";
      }

      const audioIsMuted = userData.audioIsMuted !== undefined ? userData.audioIsMuted : null;
      const videoIsMuted = userData.videoIsMuted !== undefined ? userData.videoIsMuted : null;
      //console.log("audioIsMuted:", audioIsMuted, "videoIsMuted:", videoIsMuted);

      const audioIcon = audioIsMuted ? "microphone-slash" : "microphone";
      const videoIcon = videoIsMuted ? "video-slash" : "video";

      let audioClasses = "video-user-icon";
      if (audioIsMuted === null) audioClasses += " disabled";

      let videoClasses = "video-user-icon";
      if (videoIsMuted === null) videoClasses += " disabled";

      const chipId = isLocal ? "video-user-me" : "video-user-" + socketId;

      if (!userData.refs) this.addRefs(userData);

      return (
         <div id={chipId} className={classes} key={chipId}>
            <div className="video-user-video">
               <div className="video-user-video">
                  <audio autoPlay={true} ref={userData.refs["audio"]}></audio>
                  <video autoPlay={true} className="video-user-video-src" ref={userData.refs["video"]}></video>
               </div>
            </div>
            <div className="video-user-info">
               <div className="video-user-name">{userFirstName}</div>

               <div className={videoClasses}>
                  <FontAwesomeIcon icon={videoIcon} />
               </div>
               <div className={audioClasses}>
                  <FontAwesomeIcon icon={audioIcon} />
               </div>
            </div>
         </div>
      );
   }
}

export default RoomXureal;
