import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import socketService from "services/socketService";

import "css/room-videoplayer.css";

import videoImg from "img/video.png";

const videoPlayerData = require("data/videoplayer.json");

const VIDEO_PATH = "/video/";

class RoomVideoPlayer extends Component {
   state = {
      isLoading: true,
      isPlaying: false,
      isExpanded: false,
      isMuted: false,

      // loadProgress: 0,
      playProgress: 0,
   };

   playerVideoRef = null;
   nearest5 = 0;

   componentDidMount() {
      this.playerVideoRef = React.createRef();
   }

   onTimeUpdate = (event) => {
      //console.log("event:", event);

      const videoPlayer = event.currentTarget;
      const { currentTime, duration } = videoPlayer;

      const playProgress = Math.round((currentTime / duration) * 100);
      //console.log("playProgress:", playProgress);

      // const { buffered } = videoPlayer;
      // const bufferEnd = buffered.end(0);
      // const loadProgress = Math.round((bufferEnd / duration) * 100);
      // //console.log("loadProgress:", loadProgress);

      const nearest5 = Math.ceil(playProgress / 5) * 5;

      if (this.nearest5 !== nearest5) {
         this.nearest5 = nearest5;
         this.updateUserContentStatus("play-" + nearest5 + "%");
      }

      this.setState({ playProgress });
   };

   onPlaying = (event) => {
      //console.log("event:", event);

      this.setState({ isPlaying: true });
   };

   onPause = (event) => {
      //console.log("event:", event);

      this.updateUserContentStatus("pause-" + this.nearest5 + "%");

      this.setState({ isPlaying: false });
   };

   toggleAudioMute = async () => {
      //console.log("toggleAudioMute()");

      const { isMuted } = this.state;

      this.setState({ isMuted: !isMuted });
   };

   barClicked = (event) => {
      const rect = event.target.getBoundingClientRect();
      const clickRatio = (event.clientX - rect.left) / rect.width;
      // console.log("clickRatio:", clickRatio);

      if (this.playerVideoRef && this.playerVideoRef.current) {
         const { duration } = this.playerVideoRef.current;
         const currentTime = duration * clickRatio;
         // console.log("duration:", duration, "currentTime:", currentTime);
         this.playerVideoRef.current.currentTime = currentTime;
         this.playerVideoRef.current.play();
      }
   };

   closeClick = (event) => {
      const { setUserContent } = this.props;
      setUserContent({ contentType: null });
   };

   updateUserContentStatus(contentStatus) {
      const { contentId } = this.props;
      socketService.setUserContent({ contentType: "video", contentId, contentStatus });
   }

   playClicked = (event) => {
      //console.log("playClicked()");

      const { isPlaying } = this.state;

      if (this.playerVideoRef && this.playerVideoRef.current) {
         if (isPlaying) {
            this.playerVideoRef.current.pause();
         } else {
            this.playerVideoRef.current.play();
         }
      }
   };

   expandClicked = (event) => {
      //console.log("expandClicked()");

      const { isExpanded } = this.state;

      this.setState({ isExpanded: !isExpanded });
   };

   render() {
      const { playProgress, isMuted } = this.state;

      const { contentId } = this.props;
      const videoData = videoPlayerData.find((video) => video.id === contentId);
      //console.log("videoData:", videoData);

      const videoSrc = videoData ? VIDEO_PATH + videoData.src : "";
      //console.log("videoSrc:", videoSrc);

      const { isPlaying, isExpanded } = this.state;

      const playIcon = isPlaying ? "pause" : "play";
      const expandIcon = isExpanded ? "compress-arrows-alt" : "expand-arrows-alt";

      // const loadStyles = { width: loadProgress + "%" };
      const playStyles = { width: playProgress + "%" };

      const imgClass = isExpanded ? "expand" : "";

      const audioIcon = isMuted ? "volume-mute" : "volume";

      return (
         <div className="videoplayer-wrapper">
            <div className="videoplayer">
               <div className="top">
                  <div className="close btn" onClick={this.closeClick}>
                     <FontAwesomeIcon icon="times" className="icon" />
                  </div>
               </div>

               <div className="box">
                  <div className="mid">
                     <img width="640" height="360" className={imgClass} src={videoImg} alt="" />

                     {videoData && <video className="video" autoPlay={true} muted={isMuted} ref={this.playerVideoRef} src={videoSrc} onTimeUpdate={this.onTimeUpdate} onPlaying={this.onPlaying} onPause={this.onPause} onClick={this.playClicked}></video>}
                     {!videoData && <div className="overlay">{contentId + " NOT FOUND"}</div>}

                     {!isPlaying && (
                        <div className="overlay">
                           <FontAwesomeIcon icon="play" className="bigplay-icon" />
                        </div>
                     )}
                  </div>

                  <div className="btm">
                     <div className="controls">
                        <button className="btn" onClick={this.playClicked}>
                           <FontAwesomeIcon icon={playIcon} className="icon" />
                        </button>

                        <div className="progress" onClick={this.barClicked}>
                           <div className="bar progress-area"></div>
                           {/* <div style={loadStyles} className="bar progress-load"></div> */}
                           <div style={playStyles} className="bar progress-play"></div>
                        </div>

                        <button className="btn" onClick={this.toggleAudioMute}>
                           <FontAwesomeIcon icon={audioIcon} className="icon" />
                        </button>

                        <button className="btn" onClick={this.expandClicked}>
                           <FontAwesomeIcon icon={expandIcon} className="icon" />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default RoomVideoPlayer;
