import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";

import Form from "components/form/form";

import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-login.css";

import logoImg from "img/logos/xureal_vertical_color_black_tag.svg";
import loginImg from "img/login.png";

class LoginForm extends Form {
   state = {
      formData: {
         email: "",
         password: "",
      },
      isValid: {
         email: null,
         password: null,
      },
      errors: {},
      modalData: {},
   };

   doSubmit = async (e) => {
      //console.log("doSubmit(e)");

      try {
         const { email, password } = this.state.formData;
         await userService.login(email, password);

         const { state } = this.props.location;

         let url;
         if (state) {
            url = state.from.pathname;
         } else {
            url = "/meetings";
         }

         // window.location = url;
         this.props.history.push(url);
      } catch (err) {
         //console.error(err);
         msgService.responseErrorById(err, "user_login_incorrect");
      }
   };

   showModalValidation(title, text) {
      const modalData = {
         type: "modal",
         showClose: true,
         title,
         text,
      };
      this.setState({ modalData });
   }

   render() {
      if (userService.getCurrentUser()) return <Redirect to="/" />;

      return (
         <div className="login-page">
            <div className="login-side login-left">{this.renderGraphic()}</div>

            <div className="login-side login-right">{this.renderLogin()}</div>
         </div>
      );
   }

   renderGraphic() {
      return (
         <Fragment>
            <div className="login-left-area">
               <div className="login-graphic">
                  <img src={logoImg} alt="Logo" width="30%" />
               </div>

               <div className="login-graphic">
                  <img src={loginImg} alt="Login Graphic" width="80%" />
               </div>
            </div>
         </Fragment>
      );
   }

   renderLogin() {
      return (
         <Fragment>
            <div className="login-right-area">
               <div className="login-screen">{this.renderLoginScreen()}</div>

               <div className="login-footer">{this.renderFooter()}</div>
            </div>
         </Fragment>
      );
   }

   renderLoginScreen() {
      return (
         <Fragment>
            <p className="page-title">Sign in to your account</p>

            <p className="page-desc">
               Don't have an account? &nbsp;
               <Link to="/user/signup" className="btn btn-link">
                  Register
               </Link>
            </p>

            <form onSubmit={this.handleSubmit}>
               {this.renderInput("email", "Email", "email")}
               {this.renderPasswordOld("password", "Password")}

               <div className="row form-btns">
                  <div className="col">
                     <Link to="/user/reset/request" className="btn btn-link">
                        Trouble signing in?
                     </Link>
                  </div>

                  <div className="col align-right">{this.renderButton("Sign in")}</div>
               </div>
            </form>
         </Fragment>
      );
   }

   renderFooter() {
      return (
         <Fragment>
            <div className="login-footer">
               <span className="small">Copyright &copy;2020 XUREAL, LLC. All rights reserved. &nbsp;</span>
               <Link to="/legal" className="btn btn-link f-normal small">
                  Privacy &amp; Legal Policies
               </Link>
            </div>
         </Fragment>
      );
   }
}

export default LoginForm;
