import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Checkbox extends Component {
   handleClick = (e) => {
      //console.log("handleClick()");

      e.preventDefault();

      const { name, value, onChange } = this.props;

      const currentTarget = { name, value: !value };
      onChange({ currentTarget });
   };

   render() {
      const { name, label, value, isDisabled } = this.props;

      let classes = "form-group form-check";
      if (isDisabled) classes += " disabled";

      return (
         <div className={classes}>
            {/* <input id={name} name={name} value={selected} onChange={onChange} disabled={isDisabled ? isDisabled : false} type="checkbox" className="form-check-input"></input> */}

            <button className="group-input form-check-input" onClick={this.handleClick}>
               {value && <FontAwesomeIcon icon="check" className="form-check-icon" />}
            </button>
            <label className="group-label form-check-label" htmlFor={name}>
               {label}
            </label>
         </div>
      );
   }
}

export default Checkbox;
