import React from "react";

const Textarea = ({ name, label, value, rows, error, onChange }) => {
   return (
      <div className="form-group">
         <label className="group-label" htmlFor={name}>
            {label}
         </label>
         <textarea
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            className="xu-auto group-input"
            rows={rows}
            autoComplete="off"
         />
         {error && <div className="group-feedback">{error}</div>}
      </div>
   );
};

export default Textarea;
