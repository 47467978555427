import React, { Fragment } from "react";

import SideNav from "components/nav/sideNav";
import Form from "components/form/form";

import zoomService from "services/zoomService";
import msgService from "services/msgService";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../css/page-account.css";

class AccountForm extends Form {
   navItems = [
      { name: "Basic Info", icon: "address-card", value: "/user/account/info" },
      { name: "Email Address", icon: "envelope", value: "/user/account/email" },
      { name: "Change Password", icon: "key", value: "/user/account/password" },
      { name: "Zoom", icon: "video", value: "/user/account/zoom" },
      { name: "Account Deletion", icon: "trash", value: "/user/account/delete" },
   ];

   state = {
      zoomCode: "",
      selectedNav: "",
      formData: {
         zoomUserId: null,
         zoomEmail: null,
      },
      errors: {},
      isDisconnecting: false,
      isLoaded: false,
   };

   async componentDidMount() {
      const zoomCode = this.props.match.params.code;
      //console.log("zoomCode:", zoomCode);

      let zoomProfileData;

      if (zoomCode) {
         this.setState({ zoomCode, isLoaded: false });
         zoomProfileData = await zoomService.connectService(zoomCode);
      } else {
         zoomProfileData = await zoomService.getInfo();
      }

      this.showZoomProfile(zoomProfileData);
   }

   handleDisconnectSubmit = async (e) => {
      this.setState({ isDisconnecting: true, isLoaded: false });

      try {
         const zoomProfileData = await zoomService.revokeService();
         this.showZoomProfile(zoomProfileData);
      } catch (err) {
         msgService.responseErrorById(err, "zoom_revoke_error");
      }
   };

   showZoomProfile = (zoomProfileData) => {
      //console.log("showZoomProfile() zoomProfileData:", zoomProfileData);

      const formData = { ...this.state.formData };
      for (let i in zoomProfileData) {
         formData[i] = zoomProfileData[i];
      }

      window.history.replaceState(null, null, "/user/account/zoom");

      this.setState({ formData, zoomCode: null, isLoaded: true, isDisconnecting: false });
   };

   render() {
      return (
         <div className="account-page">
            <div className="sidemenu">
               <SideNav items={this.navItems} />
            </div>

            <div className="account-content">
               <div className="page-narrow">{this.renderContent()}</div>
            </div>
         </div>
      );
   }

   renderContent() {
      const { isLoaded, isDisconnecting, zoomCode } = this.state;
      const { zoomUserId, zoomEmail } = this.state.formData;
      //console.log("zoomUserId:", zoomUserId, "zoomEmail:", zoomEmail);

      const connectUrl = process.env.REACT_APP_SERVER_URL + "/api/zoom/connect";

      return (
         <Fragment>
            <p className="page-eyebrow">MY ACCOUNT</p>

            <p className="page-title">Zoom Connection</p>

            {isDisconnecting && <p>Disconnecting...</p>}

            {zoomCode && <p>Connecting...</p>}

            {isLoaded && zoomUserId !== null && (
               <Fragment>
                  <div className="form-group">
                     <label className="group-label" htmlFor="zoomEmail">
                        Zoom Account Email:
                     </label>
                     <input id="zoomEmail" name="zoomEmail" value={zoomEmail} type="text" className="group-input" disabled />
                  </div>

                  <div className="account-btns">
                     <button onClick={this.handleDisconnectSubmit} className="btn btn-danger">
                        Disconnect Zoom
                     </button>
                  </div>
               </Fragment>
            )}

            {isLoaded && !zoomUserId && !zoomCode && (
               <Fragment>
                  <p>You will be temporarily redirected to the zoom.us website to authorize the connection.</p>

                  <a href={connectUrl}>
                     <button className="btn btn-primary">Connect Zoom</button>
                  </a>
               </Fragment>
            )}
         </Fragment>
      );
   }
}

export default AccountForm;
