import React, { Component } from "react";

import userService from "services/userService";
import meetingService from "services/meetingService";
import socketService from "services/socketService";

import RoomMain from "components/room/roomMain";
import RoomPanelsMenu from "components/room/roomPanelsMenu";

import RoomNavi from "components/room/panels/roomNavi";
import RoomChat from "components/room/panels/roomChat";
import RoomCatalog from "components/room/panels/roomCatalog";
// import RoomMap from "components/room/panels/roomMap";
import RoomLeaderboard from "components/room/panels/roomLeaderboard";
import RoomHost from "components/room/panels/roomHost";

// import RoomZoom from "components/room/platforms/roomZoom";
// import RoomJitsi from "components/room/platforms/roomJitsi";
import RoomXureal from "components/room/platforms/roomXureal";

import RoomJoin from "components/room/windows/roomJoin";
import RoomRedeem from "components/room/windows/roomRedeem";

import "../../css/page-room.css";

class Room extends Component {
   state = {
      roomData: {
         meetingId: null,
         meetingPlatform: null,
         meetingLogo: null,
         meetingContentUrl: null,
         meetingShowMap: null,
         meetingShowChat: null,
         hostUserId: null,
      },

      roomAspects: {},

      userData: {
         userId: null,
         userFirstName: null,
         userTokens: 0,
         email: null,
         isHost: null,
      },

      usersList: [],

      meetingIsJoined: false,

      currentMenuOption: null,
      currentNaviOption: "Atrium",
      currentHostLocation: null,

      desktopMediaTrack: null,
      redeemItemId: null,
      triviaQuizId: null,

      userContent: {
         contentType: null,
         contentId: null,
         contentStatus: null,
      },

      chatMessages: [],

      menuBadges: {
         video: null,
         chat: 0,
      },
   };

   hostUserId;
   myUserId;
   isHost;

   aspectsSentToChild = false;
   socketIsConnected = false;

   socketQueue = [];

   iframeWindow = null;

   screenShareRef = null;

   async componentDidMount() {
      let { meetingId, userId } = this.props.match.params;
      //console.log("meetingId:", meetingId, "userId:", userId);

      if (!meetingId) return;

      const user = userService.getCurrentUser();
      if (user) {
         userId = user._id;
      }
      //console.log("user:", user);

      const _roomData = await meetingService.getRoomData(meetingId, userId);
      //console.log("roomData:", _roomData);
      //console.log("meetingContentUrl:", roomData.meetingContentUrl);

      const roomData = {
         meetingId: _roomData.meetingId,
         meetingPlatform: _roomData.meetingPlatform,
         meetingLogo: _roomData.meetingLogo,
         meetingContentUrl: _roomData.meetingContentUrl,
         meetingShowMap: _roomData.meetingShowMap,
         meetingShowChat: _roomData.meetingShowChat,
         hostUserId: _roomData.hostUserId,
      };

      this.hostUserId = _roomData.hostUserId;

      this.screenShareRef = React.createRef();

      this.setMyUserData(_roomData);
      this.setState({ roomData });

      this.initMessageListener();

      if (userId === "5f6adf499f58b61cd6a2e58a") {
         this.joinMeeting(user.userFirstName, user.email);

         // this.setUserContent({
         //    contentType: "trivia",
         //    contentId: "brand-love",
         //    contentStatus: "open",
         // });
      }
   }

   joinMeeting = async (userFirstName, email) => {
      //console.log("joinMeeting() userFirstName:", userFirstName, "email:", email);

      let user = userService.getCurrentUser();
      if (!user) {
         user = await meetingService.ensureParticipant({ email, userFirstName });
      }
      //console.log("user:", user);

      this.setMyUserData(user);
      this.initSocket();
      this.setMenuId("video");

      this.setState({ meetingIsJoined: true });

      // this.addFakeUsers();
   };

   setMyUserData = (_userData) => {
      //console.log("setMyUserData() _userData:", _userData);

      let userId = _userData.userId ? _userData.userId : null;
      if (!userId) userId = _userData._id ? _userData._id : null;

      if (!userId) return;

      this.myUserId = userId;

      if (this.hostUserId) {
         this.isHost = this.myUserId === this.hostUserId ? true : false;
      }

      let userData = { ...this.state.userData };
      userData.userId = userId;
      userData.userFirstName = _userData.userFirstName ? _userData.userFirstName : null;
      userData.email = _userData.email ? _userData.email : null;
      userData.isHost = this.isHost;
      //console.log("userData:", userData);

      this.setState({ userData });
   };

   setRoomAspect = ({ name, value }) => {
      //console.log("setRoomAspect() name:", name, " value:", value);

      let roomAspects = this.state.roomAspects;

      roomAspects[name] = value;

      this.sendAspectToChild(name, value);

      if (name === "room-content" && value.contentId) {
         //console.log("video:", value);
         this.setUserContent(value);
      }

      this.setState({ roomAspects });
   };

   setUserAspect = ({ userId, name, value }) => {
      //console.log("setUserAspect() userId:", userId, "name:", name, " value:", value);

      let usersList = [...this.state.usersList];

      let userData = usersList.find((user) => user.userId === userId);
      userData.userAspects[name] = value;

      if (name === "participantId") {
         userData.participantId = value;
         //console.log("userId:", userId, "participantId:", value);
      }

      this.setState({ usersList });
   };

   setVideoStatus = (videoStatus) => {
      //console.log("setVideoStatus() videoStatus:", videoStatus);

      const menuBadges = { ...this.state.menuBadges };
      menuBadges.video = videoStatus;

      this.setState({ menuBadges });
   };

   setMenuId = (menuOption) => {
      // console.log("setMenuId() menuOption:", menuOption);

      if (!menuOption) menuOption = null;

      const { currentMenuOption } = this.state;
      const menuBadges = { ...this.state.menuBadges };

      if (menuOption === currentMenuOption) {
         menuOption = null;
      }

      if (menuOption === "leave") {
         this.leaveMeeting();
         menuOption = null;
      }

      if (menuOption === "chat") {
         menuBadges.chat = null;
      }

      this.setState({ currentMenuOption: menuOption, menuBadges });
   };

   setNaviOption = (naviOption) => {
      //console.log("setNaviOption() naviOption:", naviOption);

      // if (this.iframeWindow && typeof this.iframeWindow.contentWindow.showSceneById !== "undefined") {
      //    this.iframeWindow.contentWindow.showSceneById(naviOption);
      // }

      this.sendAspectToChild("user-location", naviOption);

      this.setState({ currentNaviOption: naviOption });
   };

   setScreenShare = async (action, mediaTrack) => {
      //console.log("setScreenShare() desktopMediaTrack:", desktopMediaTrack);

      if (!mediaTrack) return;

      let { desktopMediaTrack } = this.state;

      if (action === "add" && mediaTrack !== desktopMediaTrack) {
         if (desktopMediaTrack) await desktopMediaTrack.detach(this.screenShareRef.current);
         mediaTrack.attach(this.screenShareRef.current);
         desktopMediaTrack = mediaTrack;
      } else if (action === "remove" && mediaTrack === desktopMediaTrack) {
         mediaTrack.detach(this.screenShareRef.current);
         desktopMediaTrack = null;
      }

      this.setState({ desktopMediaTrack });
   };

   setUserContent = ({ contentType, contentId, contentStatus }) => {
      //console.log("setUserContent() contentType:", contentType, "contentId:", contentId);

      if (contentType === null) {
         contentId = null;
         contentStatus = null;
      }

      const roomContent = this.state.roomAspects["room-content"];
      if (this.isHost && contentType === null && roomContent && roomContent.contentType !== null) {
         this.setRoomContent({ contentType, contentId, contentStatus });
      }

      if (contentType && !contentStatus) contentStatus = "open";

      //socketService.setUserData({ name: "user-content", value: { contentType, contentId, contentStatus } });
      socketService.setUserContent({ contentType, contentId, contentStatus });

      this.setState({ userContent: { contentType, contentId, contentStatus } });
   };

   setRoomContent = ({ contentType, contentId, contentStatus }) => {
      //console.log("setUserContent() contentType:", contentType, "contentId:", contentId);

      if (this.isHost) {
         // socketService.setRoomData({ name: "room-content", value: { contentType, contentId, contentStatus } });
         socketService.setRoomContent({ contentType, contentId, contentStatus });
      }
   };

   setRedeemProductId = (redeemItemId) => {
      //console.log("setRedeemProductId() redeemItemId:", redeemItemId);

      this.setState({ redeemItemId });
   };

   setTriviaQuizId = (triviaQuizId) => {
      //console.log("setTriviaQuizId() redeemItemId:", redeemItemId);

      this.setState({ triviaQuizId });
   };

   // addFakeUsers() {
   //    console.log("addFakeUsers()");

   //    let delay = 500;
   //    for (var i = 0; i < 5; i++) {
   //       setTimeout(() => {
   //          const newUserData = {
   //             meetingId: this.meetingId,
   //             socketId: new Date().getTime(),
   //             userId: makeid(randomInt(6, 12)),
   //             userFirstName: makeid(randomInt(6, 12)),
   //             isHost: false,
   //             userAspects: [],
   //          };
   //          this.addUser(newUserData);
   //       }, delay);

   //       delay += 750;
   //    }

   //    function makeid(length) {
   //       var result = "";
   //       var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
   //       var charactersLength = characters.length;
   //       for (var i = 0; i < length; i++) {
   //          result += characters.charAt(Math.floor(Math.random() * charactersLength));
   //       }
   //       return result;
   //    }

   //    function randomInt(min, max) {
   //       return min + Math.floor((max - min) * Math.random());
   //    }
   // }

   onLoadIframe = () => {
      //console.log("onLoadIframe()");

      this.iframeWindow = window.frames["room-content-iframe"];

      this.sendAspectsToChild();
   };

   /* SOCKET / USER MANAGEMENT */

   initSocket = () => {
      //console.log("initSocket()");

      const { meetingId } = this.state.roomData;
      const { userId, userFirstName, isHost } = this.state.userData;

      const socketParams = {
         meetingId,
         userId,
         userFirstName,
         isHost,
      };
      socketService.connectSocket(socketParams).catch((err) => {
         console.error(err);
         return;
      });

      socketService.relayMessage("socket-connected", this.socketConnected);
      socketService.relayMessage("meeting-joined", this.meetingJoined);
      socketService.relayMessage("user-join", this.addUser);
      socketService.relayMessage("user-leave", this.removeUser);
      socketService.relayMessage("room-updated", this.setRoomAspect);
      socketService.relayMessage("user-updated", this.setUserAspect);
      socketService.relayMessage("chat-message", this.gotMessage);
   };

   socketConnected = () => {
      //console.log("socketConnected()");

      for (const eventData of this.socketQueue) {
         //console.log("RELEASED:", eventData);
         this.processMessage(eventData);
      }

      this.socketQueue = [];
      this.socketIsConnected = true;

      this.setState({ usersList: [] });
      this.sendAspectsToChild();
   };

   sendAspectsToChild = () => {
      //console.log("sendAspectsToChild()");

      if (!this.aspectsSentToChild && this.socketIsConnected && this.iframeWindow) {
         this.aspectsSentToChild = true;

         this.iframeWindow.contentWindow.postMessage({ type: "hello-from-parent", name: "hello", value: "child" }, "*");

         const { userFirstName, email, isHost } = this.state.userData;

         this.sendAspectToChild("is-host", isHost);
         this.sendAspectToChild("first-name", userFirstName);
         this.sendAspectToChild("email", email);
      }
   };

   sendAspectToChild(name, value) {
      if (!this.iframeWindow || !this.iframeWindow.contentWindow) {
         return console.error("iFrame not loaded.");
      }
      this.iframeWindow.contentWindow.postMessage({ type: "room-updated", name, value }, "*");
   }

   meetingJoined = (socketData) => {
      //console.log("meetingJoined() socketData:", socketData);

      const { usersList, roomAspects, roomChatHistory } = socketData;

      for (const userData of usersList) {
         this.addUser(userData);
      }

      for (const [name, value] of Object.entries(roomAspects)) {
         this.setRoomAspect({ name, value });
      }

      if (roomChatHistory && roomChatHistory.length > 0) {
         this.setState({ chatMessages: roomChatHistory });
      }
   };

   leaveMeeting = () => {
      //console.log("leaveMeeting()");

      socketService.disconnectSocket();

      this.setState({ meetingIsJoined: false, usersList: [] });
   };

   addUser = (_userData) => {
      //console.log("addUser() _userData:", _userData);

      const { userId } = _userData;

      let usersList = [...this.state.usersList];
      let userData = usersList.find((user) => user.userId === userId);

      if (!userData) {
         userData = _userData;
         usersList.push(userData);
      }
      //console.log("userData:", userData);

      const isLocal = userId === this.myUserId ? true : false;
      userData.isLocal = isLocal;
      //console.log("userId:", userId, "isHost:", "isLocal:", isLocal);

      const isHost = userId === this.hostUserId ? true : false;
      userData.isHost = isHost;
      //console.log("hostUserId:", this.hostUserId, "isHost:", isHost);

      if (isHost) {
         userData.priority = 1;
      } else if (isLocal) {
         userData.priority = 2;
      } else {
         userData.priority = 2 + Math.max(0, usersList.length - 2);
      }

      //console.log("usersList:", usersList);

      this.setState({ usersList });
   };

   removeUser = ({ userId }) => {
      //console.log("removeUser() userId:", userId);

      let usersList = [...this.state.usersList];

      usersList = usersList.filter((user) => user.userId !== userId);
      //console.log("usersList:", usersList);

      this.setState({ usersList });
   };

   setUserData = (userData) => {
      //console.log("setUserData() userData:", userData);

      let usersList = [...this.state.usersList];

      const { userId } = userData;
      usersList = usersList.filter((user) => user.userId !== userId);

      usersList.push(userData);

      this.setState({ usersList });
   };

   /* REAL-TIME ROOM SOCKET VARIABLES */

   initMessageListener() {
      //console.log("initMessageListener()");

      const validTypes = ["hello-from-child", "update-room", "update-user", "update-tokens", "update-position", "show-content", "leave-meeting"];

      window.addEventListener("message", (event) => {
         //console.log(event);

         const eventData = event.data;
         //console.log("eventData:,", eventData);

         if (validTypes.includes(eventData.type)) {
            // const originHost = new URL(event.origin).host;
            // if (eventData.type && originHost !== "xureal.com" && originHost !== window.location.host) {
            //    console.info(window.location.host, "not accepting messages from:", originHost);
            //    return;
            // }

            //console.log("eventData:,", eventData);

            if (!this.socketIsConnected) {
               //console.log("QUEUED:", eventData);
               this.socketQueue.push(eventData);
            } else {
               this.processMessage(eventData);
            }
         }
      });
   }

   processMessage(eventData) {
      //console.log("processMessage() eventData:", eventData);

      if (eventData.type === "hello-from-child") {
         const { name, value } = eventData;
         console.log("PARENT RECIEVED MESSAGE: ", name, value);
      }

      if (this.isHost && eventData.type === "update-room") {
         //console.log("UPDATE-ASPECT:", eventData.name, ":", eventData.value);

         const { name, value } = eventData;
         socketService.setRoomData({ name, value });
      }

      if (eventData.type === "update-user") {
         //console.log("UPDATE-USER:", eventData.newUserData);

         const { userAspectData } = eventData;
         socketService.setUserData(userAspectData);
      }

      if (eventData.type === "update-position") {
         //console.log("UPDATE-POSITION:", eventData.newUserData);

         const { newUserData } = eventData;
         socketService.sendRequest("update-position", { newUserData });
      }

      if (eventData.type === "update-tokens") {
         //console.log("UPDATE-TOKENS:", eventData.newTokens);

         const userData = { ...this.state.userData };
         userData.userTokens = this.numberWithCommas(eventData.newTokens);
         //console.log("userData:", userData);

         this.setState({ userData });
      }

      if (eventData.type === "show-content") {
         const { contentType, contentId } = eventData;
         //console.log("SHOW-CONTENT: contentType", contentType, "contentId", contentId);

         // const { newUserData } = eventData;
         // socketService.sendRequest("show-content", { newUserData });

         this.setUserContent({ contentType, contentId });
      }

      if (eventData.type === "leave-meeting") {
         //console.log("LEAVE-MEETING:");

         this.leaveMeeting();
         return;
      }
   }

   numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   gotMessage = (data) => {
      //console.log("gotMessage() data:", data);

      const { chatMessages, currentMenuOption } = this.state;

      const chatIsOpen = currentMenuOption === "chat";

      chatMessages.push(data);

      const menuBadges = { ...this.state.menuBadges };

      const { chat } = menuBadges;
      menuBadges.chat = !chatIsOpen ? chat + 1 : 0;

      this.setState({ chatMessages, menuBadges });
   };

   /* PAGE RENDER */

   render() {
      if (this.state.roomData.meetingPlatform === null) return null;

      const { currentMenuOption, roomData, userData, usersList, meetingIsJoined, currentNaviOption, redeemItemId, menuBadges, desktopMediaTrack, userContent } = this.state;
      const { userFirstName, email, isHost } = userData;
      const formData = { userFirstName, email };

      return (
         <div className="room">
            <div className="room-main">
               <RoomMain roomData={roomData} userData={userData} usersList={usersList} onLoadIframe={this.onLoadIframe} screenShareRef={this.screenShareRef} currentNaviOption={currentNaviOption} desktopMediaTrack={desktopMediaTrack} userContent={userContent} setUserContent={this.setUserContent} />
               {isHost && this.renderContentStatus()}
            </div>

            {meetingIsJoined && this.renderSide()}

            {meetingIsJoined && <RoomPanelsMenu className="room-menu" roomData={roomData} userData={userData} urrentMenuOption={currentMenuOption} menuBadges={menuBadges} setMenuId={this.setMenuId}></RoomPanelsMenu>}

            {!meetingIsJoined && <RoomJoin formData={formData} joinMeeting={this.joinMeeting} />}
            {meetingIsJoined && redeemItemId && <RoomRedeem roomData={roomData} userData={userData} redeemItemId={redeemItemId} setRedeemProductId={this.setRedeemProductId} />}
         </div>
      );
   }

   renderSide() {
      const { currentMenuOption, roomData, userData, currentNaviOption, chatMessages, roomAspects } = this.state;

      return (
         <div className="room-side">
            {this.renderPlatform(currentMenuOption)}
            {currentMenuOption === "nav" && <RoomNavi roomData={roomData} userData={userData} setMenuId={this.setMenuId} setNaviOption={this.setNaviOption} currentNaviOption={currentNaviOption} />}
            {currentMenuOption === "chat" && <RoomChat roomData={roomData} userData={userData} setMenuId={this.setMenuId} chatMessages={chatMessages} />}
            {currentMenuOption === "catalog" && <RoomCatalog roomData={roomData} userData={userData} setMenuId={this.setMenuId} setRedeemProductId={this.setRedeemProductId} />}
            {/* {currentMenuOption === "map" && <RoomMap roomData={roomData} userData={userData} roomAspects={roomAspects} setMenuId={this.setMenuId} />} */}
            {currentMenuOption === "leaderboard" && <RoomLeaderboard roomData={roomData} userData={userData} setMenuId={this.setMenuId} />}
            {currentMenuOption === "host" && <RoomHost roomData={roomData} userData={userData} roomAspects={roomAspects} setMenuId={this.setMenuId} />}
         </div>
      );
   }

   renderPlatform(currentMenuOption) {
      //console.log("renderPlatform()");

      const showVideo = currentMenuOption === "video";

      const { roomData, userData, usersList } = this.state;

      const { meetingPlatform } = roomData;
      if (meetingPlatform === "none") return null;

      const styles = showVideo ? { display: "block", height: "100%" } : { display: "none" };

      return (
         <div style={styles}>
            {/* {meetingPlatform === "zoom" && <RoomZoom roomData={roomData} />}
            {meetingPlatform === "jitsi" && <RoomJitsi roomData={roomData} />} */}
            {/* leaveMeeting={this.leaveMeeting} */}
            {meetingPlatform === "xureal" && <RoomXureal roomData={roomData} userData={userData} usersList={usersList} setMenuId={this.setMenuId} setVideoStatus={this.setVideoStatus} setUserData={this.setUserData} setScreenShare={this.setScreenShare} />}
         </div>
      );
   }

   renderContentStatus() {
      //const roomContent = this.state.roomAspects["room-content"];

      const { usersList } = this.state;

      const html = [];

      for (const userData of usersList) {
         const { userId, userFirstName, userAspects } = userData;
         const userContent = userAspects["user-content"];

         const contentType = userContent && userContent.contentType ? userContent.contentType : "x";
         const contentId = userContent && userContent.contentId ? userContent.contentId : "x";
         const contentStatus = userContent && userContent.contentStatus ? userContent.contentStatus : "x";

         html.push(
            <div key={userId} className="user-content">
               <strong>{userFirstName}</strong>
               <div>{contentType}</div>
               <div>{contentId}</div>
               <div>{contentStatus}</div>
            </div>
         );
      }

      return <div className="users-content">{html}</div>;
   }
}

export default Room;
