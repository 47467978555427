import axios from "axios";
import _ from "lodash";

const jitsiDomain = process.env.REACT_APP_JITSI_DOMAIN;

const connectionOptions = {
   hosts: {
      domain: jitsiDomain,
      muc: "conference." + jitsiDomain,
   },
   externalConnectUrl: "https://" + jitsiDomain + "/http-pre-bind",
   bosh: "https://" + jitsiDomain + "/http-bind",
   clientNode: "https://" + jitsiDomain + "/jitsimeet",
};

const initOptions = {
   useIPv6: true,
   disableAudioLevels: true,
   disableSimulcast: false,
   enableWindowOnErrorHandler: false,
   disableThirdPartyRequests: true,
   enableAnalyticsLogging: false,

   openBridgeChannel: false,
   startSilent: false,
   enableTalkWhileMuted: true,
   enableNoAudioDetection: true,
   enableNoisyMicDetection: true,
   startAudioMuted: false,
   startVideoMuted: false,
   enableRemb: true,
   enableTcc: true,
   enableLayerSuspension: true,
   p2p: {
      enabled: false,
   },
   rttMonitor: {
      enabled: true,
   },
};

const localTrackSettings = {
   //devices: ["audio", "video"],
   resolution: 180,
   constraints: {
      video: {
         width: { ideal: 320 },
         height: { ideal: 180 },
         aspectRatio: { exact: 16 / 9 },
         frameRate: { ideal: 18 },
         facingMode: { ideal: "user" },
      },
   },
   // disableAudioLevels: true,
   // disableSimulcast: false,
   // enableWindowOnErrorHandler: false,
   // disableThirdPartyRequests: true,
   // enableAnalyticsLogging: false,
};

const desktopTrackSettings = {
   //devices: ["desktop"],
   resolution: 720,
   constraints: {
      video: {
         width: { ideal: 1280 },
         height: { ideal: 720 },
         aspectRatio: { exact: 16 / 9 },
         frameRate: { ideal: 18 },
      },
   },
   // disableAudioLevels: true,
   // disableSimulcast: false,
   // enableWindowOnErrorHandler: false,
   // disableThirdPartyRequests: true,
   // enableAnalyticsLogging: false,
};

// const roomOptions = {
//    openBridgeChannel: false,
//    startSilent: false,
//    enableTalkWhileMuted: true,
//    enableNoAudioDetection: true,
//    enableNoisyMicDetection: true,
//    startAudioMuted: false,
//    startVideoMuted: false,
//    enableRemb: true,
//    enableTcc: true,
//    enableLayerSuspension: true,
//    p2p: {
//       enabled: false,
//    },
//    rttMonitor: {
//       enabled: true,
//    },
// };

const deviceTypes = {
   MICROPHONE: "microphone",
   CAMERA: "camera",
   DESKTOP: "desktop",
};

const devices = {
   microphone: "audio",
   camera: "video",
   desktop: "desktop",
};

let jitsiConnection;
let jitsiConference;

let conferenceIsJoined;
let localTracksStarted;

let meetingId;
let myUserId;
let myParticipantId;

let localVideoDeviceType;
let localTrackDeviceIds;
let localTracks;

let remoteTracks;
let participantList;

let participantUserIds;

let JitsiMeetJS;

async function initPlatform(connectParams) {
   //console.log("initPlatform() connectParams:", connectParams);

   console.log("jitsiDomain:", jitsiDomain);

   conferenceIsJoined = false;
   localTracksStarted = false;

   meetingId = null;
   myUserId = null;
   myParticipantId = null;

   localVideoDeviceType = null;

   localTrackDeviceIds = {
      audio: null,
      video: null,
   };

   localTracks = {
      audio: null,
      video: null,
   };

   remoteTracks = [];
   participantList = [];

   participantUserIds = {
      // [participantId] : [userId]
   };

   myUserId = connectParams.userId;
   if (!myUserId) return console.error("missing userId:", myUserId);
   //console.log("myUserId:", myUserId);

   meetingId = connectParams.meetingId;
   if (!meetingId) return console.error("Can't connect to video chat server. meetingId is missing.");

   // verify the bosh server is reachable
   axios.get(connectionOptions.bosh, { headers: null }).catch((error) => {
      console.error("Unable to connect to the video chat server. The Internet connection or video chat server may be down.");
   });

   JitsiMeetJS = window.JitsiMeetJS;

   JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, onDeviceListChanged);
   JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
   JitsiMeetJS.init(initOptions);

   initPlatformConnection();

   return;
}

/* JITSI CONNECTION */

function initPlatformConnection() {
   //console.log("initPlatformConnection()");

   jitsiConnection = new JitsiMeetJS.JitsiConnection(null, null, connectionOptions);

   connectionEvents(jitsiConnection, "add");

   jitsiConnection.connect();
}

/* CONFERENCE ROOM */

function initPlatformConference() {
   //console.log("initPlatformConference()");

   jitsiConference = jitsiConnection.initJitsiConference(meetingId, initOptions);

   jitsiConference.setDisplayName(myUserId);
   //console.log("displayName:", jitsiConference.options.name);

   myParticipantId = jitsiConference.myUserId();
   //console.log("myParticipantId:", myParticipantId);
   participantJoin(myParticipantId, {
      userId: myUserId,
      participantId: myParticipantId,
   });

   participantUserIds[myParticipantId] = myUserId;

   dispatchEvent(new CustomEvent("participant-updated", { detail: { myParticipantId } }));

   conferenceEvents(jitsiConference, "add");

   // start local audio / video
   updateLocalTracks();

   jitsiConference.join();
}

/* PARTICIPANTS JOIN / LEAVE */

function participantJoin(participantId, jitsiUser) {
   //console.log("participantJoin() participantId:", participantId, "jitsiUser:", jitsiUser);

   let participantData = getParticipant(participantId);
   if (participantData) return console.error("PARTICIPANT EXISTS:", participantData, participantList);

   const userId = jitsiUser.userId ? jitsiUser.userId : jitsiUser.getDisplayName();
   if (!userId) return console.error("missing userId:", userId);

   const isLocal = participantId === myParticipantId;

   participantData = {
      userId,
      participantId,
      isLocal,
      tracks: {},
   };

   if (userId === myUserId && participantId !== myParticipantId) {
      //console.log("JOIN: userId:", userId, "participantId:", participantId, "GHOST!!");
      jitsiConference.kickParticipant(participantId);
      return;
   } else if (participantId === myParticipantId) {
      //console.log("JOIN: userId:", userId, "participantId:", participantId, "ME!!");
   } else {
      //console.log("JOIN: userId:", userId, "participantId:", participantId);
   }

   participantUserIds[participantId] = userId;

   participantList.push(participantData);

   // dispatchEvent(new CustomEvent("participant-joined", { detail: { participantData } }));
}

function participantLeave(participantId, jitsiUser) {
   //console.log("participantLeave() participantId:", participantId, "jitsiUser:", jitsiUser);

   const userId = jitsiUser.userId ? jitsiUser.userId : jitsiUser.getDisplayName();
   if (!userId) return console.error("missing userId:", userId);

   participantList = participantList.filter((participant) => participantId === participant.participantId);

   // console.log("LEAVE: userId:", userId, "participantId:", participantId);
   // dispatchEvent(new CustomEvent("participant-leave", { detail: { participantData } }));
}

function getParticipant(participantId) {
   //console.log("getParticipant() participantId:", participantId);

   return participantList.find((participant) => participantId === participant.participantId);
}

function getParticipantTracks(participantId) {
   //console.log("getParticipantTracks() participantId:", participantId);

   const participantData = getParticipant(participantId);

   return participantData ? participantData.tracks : null;
}

/* LOCAL TRACKS */

function setVideoType(videoType) {
   //console.log("setVideoType() videoType:", videoType);

   if (!videoType) videoType = localVideoDeviceType === "camera" ? "desktop" : "camera";

   updateLocalTrack(videoType, "default");
}

function updateLocalTracks(deviceIds) {
   // console.log("updateLocalTracks() deviceIds:", deviceIds);

   const audioInputDeviceId = deviceIds && deviceIds.audioInputDeviceId ? deviceIds.audioInputDeviceId : "default";
   updateLocalTrack(deviceTypes.MICROPHONE, audioInputDeviceId);

   const videoInputDeviceId = deviceIds && deviceIds.videoInputDeviceId ? deviceIds.videoInputDeviceId : "default";
   updateLocalTrack(deviceTypes.CAMERA, videoInputDeviceId);

   if (deviceIds && deviceIds.audioOutputDeviceId) {
      //console.log("speaker USE: deviceId:", deviceIds.audioOutputDeviceId);
      JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceIds.audioOutputDeviceId);
   }
}

async function updateLocalTrack(deviceType, useDeviceId) {
   //console.log("updateLocalTrack() deviceType:", deviceType, "useDeviceId:", useDeviceId);

   const trackType = deviceType === deviceTypes.MICROPHONE ? "audio" : "video";
   const inputDeviceId = useDeviceId ? await getActualDeviceId(useDeviceId, trackType) : await getDeviceIdByType(trackType);
   //console.log("inputDeviceId:", inputDeviceId, "localTrackDeviceId:", localTrackDeviceIds[trackType]);

   if (trackType === "video" && deviceType !== localVideoDeviceType) {
      createLocalTrack(trackType, deviceType, inputDeviceId);
      return;
   }

   if (inputDeviceId !== localTrackDeviceIds[trackType]) {
      createLocalTrack(trackType, deviceType, inputDeviceId);
      return;
   }
}

async function createLocalTrack(trackType, deviceType, inputDeviceId) {
   //console.log("createLocalTrack() trackType:", trackType, "deviceType:", deviceType, "inputDeviceId:", inputDeviceId);

   //console.log("CREATE:", trackType, deviceType, "inputDeviceId:", inputDeviceId);

   if (trackType === "video") {
      localVideoDeviceType = deviceType;
   }

   const trackSettings = deviceType === "desktop" ? _.cloneDeep(desktopTrackSettings) : _.cloneDeep(localTrackSettings);
   _.merge(trackSettings, initOptions);
   trackSettings.devices = [devices[deviceType]];
   //console.log("trackSettings:", trackSettings);

   JitsiMeetJS.createLocalTracks(trackSettings)
      .then(async (mediaTracks) => {
         //console.log("createLocalTracks() mediaTracks:", mediaTracks);

         if (!mediaTracks || mediaTracks.length === 0) return;

         for (const mediaTrack of mediaTracks) {
            await localTrackCreated(mediaTrack);
         }
      })
      .catch((error) => {
         if (error.name && error.name === "gum.screensharing_user_canceled") {
            console.log("SCREEN SHARE: Cancelled by user.");
         } else if (error.name && error.name === "gum.permission_denied") {
            console.log("SCREEN SHARE: Permission denied. Check system 'Screen Recording' security settings.");
         } else {
            console.error(error);
         }
      })
      .finally(() => {
         updateVideoDeviceType();
      });
}

async function localTrackCreated(mediaTrack) {
   //console.log("localTrackCreated() mediaTrack:", mediaTrack);

   const deviceId = mediaTrack.getDeviceId();
   const trackType = mediaTrack.getType();
   const actualDeviceId = await getActualDeviceId(deviceId, trackType);

   // remove old track
   if (localTracks[trackType]) await removeMediaTrack(localTracks[trackType], true);

   mediaTrack.attachedToDom = false;
   mediaTrack.streamingToServer = false;

   localTracks[trackType] = mediaTrack;
   localTrackDeviceIds[trackType] = actualDeviceId;

   //console.log("CREATED:", trackType, "actualDeviceId:", actualDeviceId);

   addLocalTracksToRoom();
}

function addLocalTracksToRoom() {
   //console.log("addLocalTracksToRoom()");

   const trackTypes = ["audio", "video"];

   for (const trackType of trackTypes) {
      const mediaTrack = localTracks[trackType];

      if (mediaTrack) {
         // send stream to server
         if (conferenceIsJoined && jitsiConference && !mediaTrack.streamingToServer) {
            localTracksStarted = true;
            mediaTrack.streamingToServer = true;
            jitsiConference.addTrack(mediaTrack);
            //console.log("STREAM: Local " + trackType + " track to server.");
         }

         // add to participantList
         addMediaTrack(mediaTrack);
      }
   }
}

function updateVideoDeviceType() {
   //console.log("updateVideoDeviceType()");

   if (localTracks["video"]) {
      localVideoDeviceType = localTracks["video"].deviceType;
      //console.log("localVideoDeviceType:", localVideoDeviceType);
      dispatchEvent(new CustomEvent("localvideotype-changed", { detail: { localVideoDeviceType } }));
   }
}

/* MEDIA TRACKS */

function addMediaTrack(mediaTrack) {
   //console.log("addMediaTrack() mediaTrack:", mediaTrack);

   if (mediaTrack.addedToParticipant) return;

   const isLocal = mediaTrack.isLocal();
   const trackType = mediaTrack.getType();
   const participantId = isLocal ? myParticipantId : mediaTrack.getParticipantId();
   const deviceType = mediaTrack.videoType ? mediaTrack.videoType : "audio";
   const userId = isLocal ? myUserId : participantUserIds[participantId];
   if (!userId) return console.error("missing userId:", userId);

   if (userId === myUserId && !isLocal) return console.info("IGNORE GHOST:", deviceType, "userId:", userId);

   mediaTrack.userId = userId;
   mediaTrack.participantId = participantId;
   mediaTrack.deviceType = deviceType;
   mediaTrack.addedToParticipant = true;

   mediaTrackEvents(mediaTrack, "add");

   if (!isLocal) remoteTracks.push(mediaTrack);

   const participantData = getParticipant(participantId);
   if (!participantData) return console.log("No user for participantId:", participantId, participantData);
   participantData.tracks[trackType] = mediaTrack;

   if (!isLocal && deviceType === "desktop" && localVideoDeviceType === "desktop") {
      setVideoType("camera");
   }

   //console.info("ADD:", deviceType, "userId:", userId);
   dispatchEvent(new CustomEvent("attach-mediatrack", { detail: { mediaTrack } }));
}

async function removeMediaTrack(mediaTrack, waitForIt) {
   //console.log("removeMediaTrack() mediaTrack:", mediaTrack);

   if (!mediaTrack) return console.error("missing mediaTrack:", mediaTrack);

   //const { deviceType } = mediaTrack;
   const isLocal = mediaTrack.isLocal();
   const trackType = mediaTrack.getType();
   const trackId = mediaTrack.getId();
   const participantId = isLocal ? myParticipantId : mediaTrack.getParticipantId();
   const userId = isLocal ? myUserId : participantUserIds[participantId];
   if (!userId) return console.error("missing userId:", userId);
   //console.log("Removing media track:", trackId, "isLocal:", isLocal, "participantId:", participantId, "userId:", userId);

   mediaTrack.userId = userId;

   mediaTrackEvents(mediaTrack, "remove");

   if (isLocal) {
      if (waitForIt) {
         await jitsiConference.removeTrack(mediaTrack);
      } else {
         jitsiConference.removeTrack(mediaTrack);
      }

      //console.log("STOP: Local", trackType, deviceType, "track to server.");
   }

   remoteTracks = remoteTracks.filter((track) => {
      return track.getId() !== trackId;
   });

   if (!participantId) console.log("missing participantId:", participantId);
   const participantData = getParticipant(participantId);
   if (!participantData) console.log("missing participantData:", participantData);
   if (participantData) participantData.tracks[trackType] = null;

   mediaTrack.dispose();

   //console.log("REMOVE:", trackType, deviceType, "userId:", userId);
   dispatchEvent(new CustomEvent("detach-mediatrack", { detail: { mediaTrack } }));
}

/* ******************** */
/* CONNECTION EVENTS */

function connectionEvents(connection, action) {
   //console.log("connectionEvents()", action);

   connection[action + "EventListener"](JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionEstablished);
   connection[action + "EventListener"](JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
   connection[action + "EventListener"](JitsiMeetJS.events.connection.CONNECTION_DROPPED_ERROR, onConnectionDropped);
   connection[action + "EventListener"](JitsiMeetJS.events.connection.SERVER_ERROR, onServerError);
   connection[action + "EventListener"](JitsiMeetJS.events.connection.OTHER_ERROR, onOtherError);
   connection[action + "EventListener"](JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onConnectionDisconnected);
}

function onConnectionEstablished(connectionId) {
   //console.log("onConnectionEstablished()", connectionId);

   initPlatformConference();
}

function onConnectionFailed(e) {
   console.error("onConnectionFailed()", e);
}

function onConnectionDropped(e) {
   console.error("onConnectionDropped()", e);
}

function onServerError(e) {
   console.error("onServerError()", e);
}

function onOtherError(e) {
   console.error("onOtherError()", e);
}

function onConnectionDisconnected(e) {
   console.log("onConnectionDisconnected()", e);
   // this is normal
}

function onDeviceListChanged(devices) {
   // console.info("onDeviceListChanged() devices:", devices);
   if (localTracksStarted) {
      // a new microphone / camera was probably added / removed
      updateLocalTracks();
   }
}

/* CONFERENCE ROOM EVENTS */

function conferenceEvents(conference, action) {
   //console.log("conferenceEvents()", action);

   conference[action + "EventListener"](JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.CONFERENCE_FAILED, onConferenceFailed);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.CONFERENCE_ERROR, onConferenceError);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.CONNECTION_ERROR, onConnectionError);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.SETUP_FAILED, onSetupFailed);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.VIDEOBRIDGE_NOT_AVAILABLE, onVideoBridgeNotAvailable);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.JINGLE_FATAL_ERROR, onJingleFatalError);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.CONFERENCE_MAX_USERS, onConferenceMaxUsers);

   conference[action + "EventListener"](JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.USER_LEFT, onUserLeave);

   conference[action + "EventListener"](JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, onTrackMuteChanged);
   // conference[action + "EventListener"](JitsiMeetJS.events.conference.LOCAL_TRACK_STOPPED, onLocalTrackStopped);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrackAdded);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoteTrackRemoved);

   conference[action + "EventListener"](JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, onDominantSpeakerChanged);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.TALK_WHILE_MUTED, onTalkWhileMuted);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.NO_AUDIO_INPUT, onNoAudioInput);
   conference[action + "EventListener"](JitsiMeetJS.events.conference.NOISY_MIC, onNoisyMic);
}

function onConferenceJoined() {
   //console.log("onConferenceJoined()");

   conferenceIsJoined = true;

   addLocalTracksToRoom();

   dispatchEvent(new CustomEvent("conference-joined"));
}

function onConferenceFailed(e) {
   console.error("onConferenceFailed()", e);
}

function onConferenceError(e) {
   console.error("onConferenceError()", e);
}

function onConnectionError(e) {
   console.error("onConnectionError()", e);
}

function onSetupFailed(e) {
   console.error("onSetupFailed()", e);
}

function onVideoBridgeNotAvailable(e) {
   console.error("onVideoBridgeNotAvailable()", e);
}

function onJingleFatalError(e) {
   console.error("onJingleFatalError()", e);
}

function onUserJoined(participantId, jitsiUser) {
   //console.log("onUserJoined()", participantId, jitsiUser);

   participantJoin(participantId, jitsiUser);
}

function onUserLeave(participantId, jitsiUser) {
   //console.log("onUserLeave()", participantId, jitsiUser);

   participantLeave(participantId, jitsiUser);
}

function onRemoteTrackAdded(mediaTrack) {
   //console.log("onRemoteTrackAdded() mediaTrack:", mediaTrack);

   if (mediaTrack.isLocal()) {
      // console.log("Ignoring local media track:", mediaTrack.getId());
      return;
   }

   addMediaTrack(mediaTrack);
}

function onRemoteTrackRemoved(mediaTrack) {
   //console.log("onRemoteTrackRemoved() mediaTrack:", mediaTrack);

   if (mediaTrack.isLocal()) return;

   removeMediaTrack(mediaTrack);
}

function onConferenceMaxUsers(e) {
   console.error("onConferenceMaxUsers()", e);
}

function onNoAudioInput(e) {
   console.info("onNoAudioInput()", e);
}

function onDominantSpeakerChanged(participantId) {
   console.log("onDominantSpeakerChanged()", participantId);
   const userId = participantUserIds[participantId];
   const participantData = {
      userId,
      participantId,
   };
   dispatchEvent(new CustomEvent("dominant-participant", { detail: { participantData } }));
}

function onTalkWhileMuted(e) {
   console.log("onTalkWhileMuted()", e);
}

function onNoisyMic(e) {
   console.log("onNoisyMic()", e);
}

/* MEDIA TRACK EVENTS */

function mediaTrackEvents(mediaTrack, action) {
   //console.log("mediaTrackEvents()", action);

   const isLocal = mediaTrack.isLocal();

   // mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, onTrackMuteChanged);
   mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.GENERAL, onGeneralTrackError);
   mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, onTrackAudioLevelChanged);

   if (isLocal) {
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, onLocalTrackStopped);
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.UNSUPPORTED_RESOLUTION, onUnsupportedResolution);
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.PERMISSION_DENIED, onPermissionDenied);
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.NOT_FOUND, onDeviceNotFound);
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.CONSTRAINT_FAILED, onConstraintFailed);
   } else {
      mediaTrack[action + "EventListener"](JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, onRemoteTrackStopped);
   }
}

function onTrackMuteChanged(mediaTrack) {
   //console.log("onTrackMuteChanged() mediaTrack:", mediaTrack);

   dispatchEvent(new CustomEvent("trackmute-changed", { detail: { mediaTrack } }));
}

function onTrackAudioLevelChanged(e) {
   console.info("onTrackAudioLevelChanged()", e);
}

function onGeneralTrackError(e) {
   console.error("onGeneralTrackError()", e);
}

function onLocalTrackStopped(mediaTrack) {
   //console.log("onLocalTrackStopped() mediaTrack:", mediaTrack);
   if (localVideoDeviceType === "desktop") {
      setVideoType("camera");
   }
}

function onRemoteTrackStopped(mediaTrack) {
   //console.log("onRemoteTrackStopped() mediaTrack:", mediaTrack);
   // removeMediaTrack(mediaTrack);
}

function onUnsupportedResolution(e) {
   console.error("onUnsupportedResolution()", e);
}

function onPermissionDenied(e) {
   console.error("onPermissionDenied()", e);
}

function onDeviceNotFound(e) {
   console.error("onDeviceNotFound()", e);
}

function onConstraintFailed(e) {
   console.error("onConstraintFailed()", e);
}

/* UTILS */

function getMediaDevices() {
   //console.log("getMediaDevices()");

   return new Promise((resolve, reject) => {
      try {
         JitsiMeetJS.mediaDevices.enumerateDevices((mediaDevices) => {
            //console.log("mediaDevices:", mediaDevices);
            resolve(mediaDevices);
         });
      } catch (err) {
         resolve([]);
      }
   });
}

function getDeviceOptions(mediaDevices, deviceKind) {
   //console.log("getDeviceOptions() mediaDevices", mediaDevices, "deviceKind:", deviceKind);

   const deviceType = deviceKind.indexOf("input") !== -1 ? "input" : "output";
   const deviceChangeable = window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(deviceType);
   //console.log("deviceKind:", deviceKind, "deviceType:", deviceType, "deviceChangeable:", deviceChangeable);

   if (!deviceChangeable) {
      console.log("Unable to change:", deviceKind, "deviceChangeable:", deviceChangeable);
      return [];
   }

   const devices = mediaDevices.filter((d) => d.kind === deviceKind);

   const options = [];
   for (const device of devices) {
      const { label, deviceId } = device;
      options.push({ text: label, value: deviceId, key: deviceKind + "-" + deviceId });
   }

   return options;
}

function isDesktopSharingEnabled() {
   if (!jitsiConference) return false;
   return JitsiMeetJS.isDesktopSharingEnabled();
}

/* MEDIA DEVICES */

async function getActualDeviceId(deviceId, trackType) {
   //console.log("getActualDeviceId() deviceId:", deviceId, "deviceId:", deviceId);

   if (deviceId === "default") {
      deviceId = await getDeviceIdByType(trackType);
   }
   // console.log("deviceId:", deviceId);

   return deviceId;
}

async function getDeviceIdByType(trackType) {
   //console.log("getDeviceIdByType() trackType:", trackType);

   const deviceKind = trackType + "input";
   //console.log("deviceKind:", deviceKind);

   const mediaDevices = await getMediaDevices();
   //console.log("mediaDevices:", mediaDevices);

   let defaultDevice = mediaDevices.find((d) => d.kind === deviceKind && d.deviceId === "default");

   let actualDeviceId;
   if (defaultDevice) {
      const defaultGroupId = defaultDevice.groupId;
      // console.log("defaultGroupId:", defaultGroupId);
      actualDeviceId = mediaDevices.find((d) => d.groupId === defaultGroupId && d.deviceId !== "default").groupId;
   } else {
      defaultDevice = mediaDevices.find((d) => d.kind === deviceKind);
      actualDeviceId = defaultDevice.deviceId;
   }
   // console.log("actualDeviceId:", actualDeviceId);

   return actualDeviceId;
}

/* STOP AND UNLOAD JITSI */

async function unloadPlatform() {
   //console.log("unloadPlatform()");

   conferenceIsJoined = false;

   removeMediaTrack(localTracks["audio"]);
   localTracks["audio"] = null;

   removeMediaTrack(localTracks["video"]);
   localTracks["video"] = null;

   for (const mediaTrack of remoteTracks) {
      removeMediaTrack(mediaTrack);
   }
   remoteTracks = [];

   if (jitsiConference) {
      conferenceEvents(jitsiConference, "remove");

      await jitsiConference.leave();
   }

   if (jitsiConnection) {
      connectionEvents(jitsiConnection, "remove");

      await jitsiConnection.disconnect();
   }

   if (JitsiMeetJS) JitsiMeetJS.mediaDevices.removeEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, onDeviceListChanged);
}

export default {
   initPlatform,
   updateLocalTracks,
   getMediaDevices,
   getDeviceOptions,
   getParticipant,
   getParticipantTracks,
   isDesktopSharingEnabled,
   setVideoType,
   unloadPlatform,
};
