import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-navi.css";

const naviOptions = require("data/naviOptions.json");

class RoomNavi extends Component {
   componentDidMount() {}

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   optionClicked = (event) => {
      //console.log("optionClicked()");

      const value = event.currentTarget.value;

      const { setNaviOption } = this.props;
      setNaviOption(value);
   };

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Navigation</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>

            <div className="side-body">
               <div className="navi-menu">{this.renderBtns()}</div>
            </div>
         </div>
      );
   }

   renderBtns() {
      const { currentNaviOption } = this.props;

      const html = [];

      for (const naviOption of naviOptions) {
         const { name, id, disabled } = naviOption;

         let classes = "navi-btn";
         if (disabled) classes += " disabled";
         if (currentNaviOption === id) classes += " selected";

         html.push(
            <button className={classes} key={"navi-btn-" + name} onClick={this.optionClicked} value={id}>
               <div className="btn-name">{name}</div>
               <div className="btn-circle">
                  <FontAwesomeIcon icon="chevron-right" className="btn-icon" />
               </div>
            </button>
         );
      }

      return html;
   }
}

export default RoomNavi;
