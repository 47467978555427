import React, { Component } from "react";

import "css/room-loader.css";

import xurealLogo from "img/logos/xureal_vertical_color_white_tag.svg";

class RoomLoader extends Component {
   render() {
      let { percentLoaded } = this.props;

      const barStyles = { width: percentLoaded + "%" };

      return (
         <div className="xu-modal">
            <div className="loader-box">
               <img className="loader-logo" src={xurealLogo} alt="XUREAL Logo" />
               <div className="loader-title">Loading XUREAL</div>
               <div className="loader-progress">
                  <div style={barStyles} className="loader-bar"></div>
               </div>
               <div className="loader-percent">{percentLoaded}%</div>
            </div>
         </div>
      );
   }
}

export default RoomLoader;
