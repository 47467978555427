import React from "react";

//import { Link } from "react-router-dom";

const Topper = (props) => {
   const { currentPage } = props;
   //console.log("currentPage:", currentPage);

   const classes = {
      details: "topper-item",
      invite: "topper-item",
      participants: "topper-item",
   };

   classes[currentPage] += " topper-active";

   return (
      <div className="page-topper">
         <div className="page-wide">
            <div className="meetings-topper">
               <div className={classes["details"]}>
                  <div className="topper-dot"></div>
                  <div className="topper-text">Create Meeting</div>
               </div>
               <div className={classes["invite"]}>
                  <div className="topper-dot"></div>
                  <div className="topper-text">Customize Email Invite</div>
               </div>
               <div className={classes["participants"]}>
                  <div className="topper-dot"></div>
                  <div className="topper-text">Add Participants</div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Topper;
