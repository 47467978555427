import React, { Component, Fragment } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import "../../css/react-multi-email.css";

class MultiEmail extends Component {
   render() {
      const { emails } = this.props;

      return (
         <Fragment>
            <ReactMultiEmail
               placeholder=""
               emails={emails}
               onChange={(emails) => {
                  this.props.onUpdate(emails);
               }}
               validateEmail={(email) => {
                  return isEmail(email);
               }}
               getLabel={(email, index, removeEmail) => this.getLabel(email, index, removeEmail)}
            />
         </Fragment>
      );
   }

   getLabel = (email, index, removeEmail) => {
      //console.log("getLabel", email, index, removeEmail);

      const { invalidEmails } = this.props;

      const isValid = !invalidEmails.includes(email);
      //console.log("email:", email, "isValid:", isValid);

      const labelClass = isValid ? "text-success" : "text-danger";

      return (
         <div data-tag key={index} className={labelClass}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
               ×
            </span>
         </div>
      );
   };
}

export default MultiEmail;
