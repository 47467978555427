import http from "services/httpService";

import msgService from "services/msgService";

const meetingsEndpoint = "/api/meetings";
const meetingEndpoint = "/api/meetings/details";
const inviteEndpoint = "/api/meetings/invite";
const participantsEndpoint = "/api/meetings/participants";

const roomEndpoint = "/api/room";

async function getMeetings() {
   try {
      const { data: meetings } = await http.get(meetingsEndpoint);
      //console.log("meetings:", meetings);

      return meetings;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meetings_list_error");
   }
}

async function getMeeting(meetingId) {
   //console.log("getMeeting() meetingId:", meetingId);

   try {
      const { data: meeting } = await http.get(meetingEndpoint + "/" + meetingId);
      //console.log("meeting:", meeting);

      // if (meeting.password) meeting.passcode = meeting.password;

      return meeting;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_details_error");
   }
}

function saveMeeting(meetingData) {
   // console.log("saveMeeting()", meetingData);

   try {
      const meetingId = meetingData.meetingId;

      if (meetingId) return http.put(meetingEndpoint + "/" + meetingId, meetingData);

      return http.post(meetingEndpoint, meetingData);
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_update_error");
   }
}

async function deleteMeeting(meetingId) {
   //console.log("deleteMeeting() meetingId:", meetingId);

   return await http.delete(meetingEndpoint + "/" + meetingId);
}

async function getInviteData(meetingId) {
   //console.log("getInviteData() meetingId:", meetingId);

   try {
      const { data: meeting } = await http.get(inviteEndpoint + "/" + meetingId);
      //console.log("meeting:", meeting);

      return meeting;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_details_error");
   }
}

function saveInviteData(meetingId, inviteData) {
   //console.log("saveInviteData() meetingId:", meetingId, "inviteData:", inviteData);

   try {
      return http.put(inviteEndpoint + "/" + meetingId, inviteData);
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_update_error");
   }
}

async function getParticipants(meetingId) {
   //console.log("getParticipants() meetingId:", meetingId);

   try {
      const { data: meetingParticipants } = await http.get(participantsEndpoint + "/" + meetingId);
      //console.log("meetingParticipants:", meetingParticipants);

      return meetingParticipants;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_details_error");
   }
}

function saveParticipants(meetingId, meetingParticipants) {
   //console.log("saveParticipants() meetingId:", meetingId, "meetingParticipants:", meetingParticipants);

   try {
      return http.put(participantsEndpoint + "/" + meetingId, meetingParticipants);
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "meeting_update_error");
   }
}

async function getRoomData(meetingId, userId) {
   //console.log("getRoomData() meetingId:", meetingId, "userId:", userId);

   try {
      let roomDataUrl = roomEndpoint + "/" + meetingId;
      if (userId) {
         roomDataUrl += "/" + userId;
      }

      const { data: meeting } = await http.get(roomDataUrl);
      //console.log("meeting:", meeting);

      return meeting;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "room_details_error");
   }
}

async function ensureParticipant(formData) {
   //console.log("ensureParticipant() formData:", formData);

   try {
      const { data } = await http.post(roomEndpoint, formData);
      //console.log("data:", data);

      return data;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "room_details_error");
   }
}

export default {
   getMeetings,
   getMeeting,
   saveMeeting,
   deleteMeeting,
   getInviteData,
   saveInviteData,
   getParticipants,
   saveParticipants,
   getRoomData,
   ensureParticipant,
};
