import React from "react";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNav = ({ items, textProperty, valueProperty }) => {
   return (
      <nav className="account-nav">
         {items.map((item) => (
            <NavLink to={item[valueProperty]} key={item[valueProperty]}>
               <div className="account-item">
                  <div className="account-item-icon">
                     <FontAwesomeIcon icon={item.icon} className="btn-icon" />
                  </div>
                  <div className="btn btn-link account-item-text">{item[textProperty]}</div>
               </div>
            </NavLink>
         ))}
      </nav>
   );
};

SideNav.defaultProps = {
   textProperty: "name",
   valueProperty: "value",
};

export default SideNav;
