import React, { Fragment } from "react";

import Form from "components/form/form";
import SideNav from "components/nav/sideNav";

import userService from "services/userService";
import msgService from "services/msgService";

import "../../css/page-account.css";

class AccountForm extends Form {
   navItems = [
      { name: "Basic Info", icon: "address-card", value: "/user/account/info" },
      { name: "Email Address", icon: "envelope", value: "/user/account/email" },
      { name: "Change Password", icon: "key", value: "/user/account/password" },
      { name: "Zoom", icon: "video", value: "/user/account/zoom" },
      { name: "Account Deletion", icon: "trash", value: "/user/account/delete" },
   ];

   state = {
      selectedNav: "",
      email: "",
      formData: {
         passwordOld: "",
         passwordNew: "",
      },
      isValid: {
         passwordOld: null,
         passwordNew: null,
      },
      errors: {},
   };

   async componentDidMount() {
      const user = userService.getCurrentUser();
      if (!user) return;

      const { data } = await userService.getAccount(user._id);
      const email = data.email;

      this.setState({ email });
   }

   doSubmit = async () => {
      try {
         const user = userService.getCurrentUser();
         const { passwordOld, passwordNew } = this.state.formData;

         const submitData = {
            _id: user._id,
            form: "password",
            passwordOld,
            passwordNew,
         };

         const formData = {
            passwordOld: "",
            passwordNew: "",
         };

         const isValid = {
            passwordOld: null,
            passwordNew: null,
         };

         this.setState({ formData, isValid });

         const res = await userService.saveAccount(submitData);
         msgService.responseSuccessById(res, "user_password_updated");

         const token = res.headers["x-auth-token"];
         userService.loginWithJwt(token);
      } catch (err) {
         msgService.responseErrorById(err, "user_password_error");
      }
   };

   render() {
      return (
         <div className="account-page">
            <div className="sidemenu">
               <SideNav items={this.navItems} />
            </div>

            <div className="account-content">
               <div className="page-narrow">{this.renderContent()}</div>
            </div>
         </div>
      );
   }

   renderContent() {
      const { email } = this.state;

      return (
         <Fragment>
            <p className="page-eyebrow">MY ACCOUNT</p>

            <p className="page-title">Change Password</p>

            <form onSubmit={this.handleSubmit}>
               <input id="email" name="email" value={email} type="text" className="form-control font-weight-bold bk-light form-control-plaintext" disabled hidden autoComplete="email" />

               {this.renderPasswordOld("passwordOld", "Current Password")}
               {this.renderPasswordNew("passwordNew", "New Password")}

               <div className="account-btns">{this.renderButton("Update Password")}</div>
            </form>
         </Fragment>
      );
   }
}

export default AccountForm;
