import http from "services/httpService";

import msgService from "services/msgService";

//  const catalogEndpoint = "/api/catalog";

async function getCatalog(meetingId) {
   //console.log("getCatalog() meetingId:", meetingId);

   // const catalogUrl = catalogEndpoint + "/" + meetingId;

   const catalogUrl = "/catalog.json";

   try {
      const { data } = await http.get(catalogUrl);
      // console.log("data:", data);

      return data;
   } catch (err) {
      console.error(err);
      msgService.responseErrorById(err, "catalog_data_error");
   }
}

export default {
   getCatalog,
};
