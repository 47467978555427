import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { DATETIME_FORMAT_LONG, MEETING_CONTENT_URL } from "data/constants";

import meetingService from "services/meetingService";
import userService from "services/userService";
import msgService from "services/msgService";

import Form from "components/form/form";
import Topper from "components/meetings/topper";

import "../../css/page-meetings.css";

const platformOptions = require("data/platforms.json");
const timezonesOptions = require("data/timezones.json");
const durationOptions = require("data/durations.json");

class Meeting extends Form {
   state = {
      userTimezone: null,
      meetingId: null,
      minDate: null,
      maxDate: null,
      meetingData: {},
      formData: {
         meetingPlatform: "",
         meetingName: "",
         meetingDateTime: "",
         meetingTimezone: "",
         meetingDuration: "",
         meetingContentUrl: "",
      },
      isValid: {
         meetingPlatform: null,
         meetingName: null,
         meetingDateTime: null,
         meetingTimezone: null,
         meetingDuration: null,
         meetingContentUrl: null,
      },
      userPlatformOptions: [],
      errors: {},
      isLoaded: false,
   };

   async componentDidMount() {
      let userTimezone = userService.getTimezone();
      if (!userTimezone) userTimezone = moment.tz.guess();
      //console.log("userTimezone", userTimezone);

      // default values
      let formData = this.state.formData;
      formData.meetingPlatform = platformOptions[0].value;
      formData.meetingTimezone = userTimezone;
      formData.meetingDuration = "60";
      formData.meetingContentUrl = MEETING_CONTENT_URL;

      // get user platforms
      const { platforms: userPlatforms } = userService.getCurrentUser();
      //console.log("userPlatforms:", userPlatforms);

      const userPlatformOptions = [];
      for (const option of platformOptions) {
         option.connected = userPlatforms[option.value];
         userPlatformOptions.push(option);
      }
      //console.log("userPlatformOptions:", userPlatformOptions);

      const meetingId = this.props.match.params.meetingId;
      //console.log("meetingId:", meetingId);

      let meetingData = {};

      if (meetingId) {
         meetingData = await meetingService.getMeeting(meetingId);
         // console.log("meetingData:", meetingData);

         if (meetingData.meetingId) {
            for (let i in formData) {
               if (meetingData[i] !== undefined) formData[i] = meetingData[i];
            }

            formData.meetingDateTime = moment(meetingData.meetingDateTime).tz(meetingData.meetingTimezone).toDate();
         }

         //console.log("meetingData meetingTimezone:", meetingData.meetingTimezone);
         //console.log("meetingDateTime:", meetingData.meetingDateTime);
      }
      // console.log("formData:", formData);

      const nowDate = moment().tz(formData.meetingTimezone).startOf("minute");
      const remainder = nowDate.add(7.5, "minutes").minute() % 15;
      const minDate = moment(nowDate).subtract(remainder, "minutes");
      const maxDate = minDate.clone().add(6, "months");
      //console.log("minDate", minDate);
      //console.log("maxDate", maxDate);

      if (!formData.meetingDateTime) formData.meetingDateTime = minDate;

      this.setState({ userTimezone, meetingId, minDate, maxDate, meetingData, formData, userPlatformOptions, isLoaded: true });
   }

   findTimezone(timezone, propName) {
      var result = timezonesOptions.filter((obj) => {
         return obj.value.includes(timezone);
      });
      return result[0][propName];
   }

   doSubmit = async () => {
      //console.log("doSubmit()");

      const meetingData = { ...this.state.formData };

      let meetingId = this.props.match.params.meetingId;
      if (meetingId) meetingData.meetingId = meetingId;
      //console.log("meetingId:", meetingId);

      const format = "YYYY-MM-DDTHH:mm";
      meetingData.meetingDateTime = moment(meetingData.meetingDateTime).format(format) + ":00";
      console.log("meetingDateTime:", meetingData.meetingDateTime);

      const response = await meetingService.saveMeeting(meetingData);
      //console.log("response:", response);

      if (response && response.status === 200 && response.data && response.data.meetingId) {
         if (!meetingId) meetingId = response.data.meetingId;
         //window.location = "/meetings/invite/" + meetingId;
         this.props.history.push("/meetings/invite/" + meetingId);
      } else {
         msgService.responseErrorById(response, "meeting_update_error");
      }
   };

   render() {
      const { meetingPlatform, meetingDateTime, meetingTimezone, meetingDuration } = this.state.formData;
      const { userPlatformOptions, userTimezone, minDate, maxDate, isLoaded } = this.state;
      const dateTimeSettings = { minDate, maxDate };

      let localTime;
      if (userTimezone && meetingDateTime && meetingTimezone && userTimezone !== meetingTimezone) {
         const format = "YYYY-MM-DDTHH:mm:ss";
         const meetingTime = moment(meetingDateTime).format(format);
         const meetingZoned = moment.tz(meetingTime, meetingTimezone).toDate();
         localTime = moment(meetingZoned).tz(userTimezone).format(DATETIME_FORMAT_LONG) + " (" + userTimezone + ")";
         //console.log("meetingTime:", meetingTime);
         //console.log("selectedTimezone:", meetingTimezone);
         //console.log("meetingZoned:", meetingZoned);
         //console.log("userTimezone:", userTimezone);
         //console.log("meetingDateTime", meetingDateTime);
         //console.log("localTime:", localTime);
      }

      return (
         <div className="page">
            <Topper currentPage="details" />

            <div className="page-narrow">
               <p className="page-eyebrow">SCHEDULE A VIRTUAL MEETING</p>

               <p className="page-title">Meeting Details</p>

               <p className="page-desc">Enter or update the details for your meeting below.</p>

               {isLoaded && (
                  <Fragment>
                     <form onSubmit={this.handleSubmit}>
                        {this.renderPlatformMenu("meetingPlatform", "Meeting Platform", userPlatformOptions, meetingPlatform)}

                        {this.renderInput("meetingName", "Meeting Name")}

                        {this.renderDateTime("meetingDateTime", "Start Date and Time", dateTimeSettings, meetingDateTime)}

                        {this.renderSelect("meetingTimezone", "Meeting Timezone", timezonesOptions, meetingTimezone)}

                        {localTime && (
                           <div className="form-group">
                              <label className="group-label" htmlFor="local_time">
                                 Local Time:
                              </label>
                              <input id="local_time" name="local_time" value={localTime} type="text" className="xu-auto group-input" disabled />
                           </div>
                        )}

                        {this.renderSelect("meetingDuration", "Meeting Duration", durationOptions, meetingDuration)}

                        {this.renderInput("meetingContentUrl", "Meeting Content URL")}

                        <div className="form-row form-btns">
                           <div className="form-col">
                              <Link to="/meetings" className="btn btn-secondary">
                                 Back
                              </Link>
                           </div>

                           <div className="form-col align-right">
                              <div className="align-right">{this.renderButton("Save and Continue")}</div>
                           </div>
                        </div>
                     </form>
                  </Fragment>
               )}
            </div>
         </div>
      );
   }
}

export default Meeting;
