import React, { Component } from "react";

import leaderboardService from "services/leaderboardService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-leaderboard.css";

class RoomLeaderboard extends Component {
   state = {
      leaderboardData: [],
   };

   hostUserId;
   myUserId;

   async componentDidMount() {
      const { hostUserId } = this.props.roomData;
      if (!hostUserId) throw new Error("hostUserId is missing");
      this.hostUserId = hostUserId;

      const { userId: myUserId } = this.props.userData;
      if (!myUserId) throw new Error("myUserId is missing");
      this.myUserId = myUserId;

      //console.log("hostUserId:", hostUserId, "myUserId:", myUserId);

      const leaderboardData = await leaderboardService.getLeaderboard();
      //console.log("leaderboardData:", leaderboardData);

      this.setState({ leaderboardData });
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Leaderboard</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>

            <div className="side-body">{this.renderBoard()}</div>
         </div>
      );
   }

   renderBoard() {
      const { leaderboardData } = this.state;

      if (!leaderboardData || leaderboardData.length === 0) return null;

      return (
         <table className="board">
            <thead>
               <tr>
                  <th>Rank</th>
                  <th>Player</th>
                  <th>XR Points</th>
               </tr>
            </thead>
            <tbody>{this.renderPlayers(leaderboardData)}</tbody>
         </table>
      );
   }

   renderPlayers(leaderboardData) {
      const html = [];

      leaderboardData.sort((a, b) => {
         return b.StatValue - a.StatValue;
      });

      for (let i in leaderboardData) {
         const user = leaderboardData[i];
         const { DisplayName, StatValue } = user;

         let classes = "board-user";
         // if (isLocal) classes += " me";
         // if (isHost) classes += " host";

         html.push(
            <tr key={"board-" + i} className={classes}>
               <td className="rank">{parseInt(i) + 1}</td>
               <td className="name">{DisplayName}</td>
               <td className="points">{StatValue}</td>
            </tr>
         );
      }

      return html;
   }
}

export default RoomLeaderboard;
