import http from "services/httpService";
import userService from "services/userService";

const connectServiceEndpoint = "/api/zoom/connect/token";
const revokeServiceEndpoint = "/api/zoom/revoke";
const zoomProfileEndpoint = "/api/zoom/me";
const zoomSignatureEndpoint = "/api/zoom/signature";

async function connectService(zoomCode) {
   console.log("connectService()", zoomCode);

   const res = await http.put(connectServiceEndpoint, { zoomCode });

   const userAuthToken = res.headers["x-auth-token"];
   //console.log("res:", res, "userAuthToken:", userAuthToken);
   if (userAuthToken) userService.loginWithJwt(userAuthToken);

   return res.data;
}

async function revokeService() {
   //console.log("revokeService()");

   const res = await http.put(revokeServiceEndpoint);

   const userAuthToken = res.headers["x-auth-token"];
   //console.log("res:", res, "userAuthToken:", userAuthToken);
   if (userAuthToken) userService.loginWithJwt(userAuthToken);

   return res.data;
}

async function getInfo() {
   //console.log("getInfo()");

   try {
      const { data } = await http.get(zoomProfileEndpoint);
      return data;
   } catch (error) {
      return null;
   }
}

async function getSignature(meetingNumber, role) {
   //console.log("getSignature()", meetingNumber, role);

   try {
      const { data } = await http.post(zoomSignatureEndpoint, { meetingNumber, role });
      return data;
   } catch (error) {
      return null;
   }
}

export default {
   connectService,
   revokeService,
   getInfo,
   getSignature,
};
