import { toast } from "react-toastify";

export default function (msg) {
   switch (msg) {
      case "data_invalid":
         return toast.error("The data is not valid.");

      case "email_updated":
         return toast.success("Email address was updated.");

      case "email_match":
         return toast.error("Your account is already using this email address.");

      case "email_used":
         return toast.error("Email address has already been used.");

      case "email_update_error":
         return toast.error("Unable to update email.");

      case "login_oldpassword_invalid":
         return toast.error("The Old Password is incorrect.");

      case "account_updated":
         return toast.success("Your account info was updated.");

      case "user_notfound":
         return toast.error("The user wasn't found.");

      default:
         return toast.info(msg);
   }
}
