import React, { Component } from "react";

import socketService from "services/socketService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "css/room-map.css";

const naviOptions = require("data/naviOptions.json");
const videoPlayerOptions = require("data/videoplayer.json");
const view360Options = require("data/view360.json");
const triviaOptions = require("data/triviaGames.json");

class RoomHost extends Component {
   hostUserId;
   myUserId;

   componentDidMount() {
      const { hostUserId } = this.props.roomData;
      if (!hostUserId) throw new Error("hostUserId is missing");
      this.hostUserId = hostUserId;

      const { userId: myUserId } = this.props.userData;
      if (!myUserId) throw new Error("myUserId is missing");
      this.myUserId = myUserId;

      //console.log("hostUserId:", hostUserId, "myUserId:", myUserId);
   }

   closeClick = () => {
      //console.log("closeClick() ");

      const { setMenuId } = this.props;
      setMenuId(null);
   };

   contentClicked = (contentType, contentId) => {
      //console.log("contentClicked() contentType:", contentType, "contentId:", contentId);

      const { roomAspects } = this.props;

      if (contentType === "room-location") {
         const currentHostLocationId = roomAspects["room-location"];
         if (contentId === currentHostLocationId) contentId = null;
         socketService.setRoomData({ name: "room-location", value: contentId });
      } else {
         const userContent = roomAspects["room-content"];
         if (userContent && contentId === userContent.contentId) contentId = null;
         socketService.setRoomData({ name: "room-content", value: { contentType, contentId } });
      }
   };

   render() {
      return (
         <div className="side-area">
            <div className="side-head">
               <div className="side-title">Host Controls</div>
               <button className="side-close" onClick={this.closeClick}>
                  <FontAwesomeIcon icon="times" className="icon" />
               </button>
            </div>

            <div className="side-body">
               <div className="navi-menu">{this.renderList()}</div>
            </div>
         </div>
      );
   }

   renderList() {
      const { roomAspects } = this.props;

      const userContent = roomAspects["room-content"];
      const currentContentId = userContent && userContent.contentId ? userContent.contentId : null;

      const menuLists = [
         {
            optionsList: naviOptions,
            listTitle: "Locations",
            listId: "room-location",
            currentOptionId: roomAspects["room-location"],
            iconId: "chevron-right",
         },
         {
            optionsList: videoPlayerOptions,
            listTitle: "Videos",
            listId: "video",
            currentOptionId: currentContentId,
            iconId: "film",
         },
         {
            optionsList: view360Options,
            listTitle: "Products",
            listId: "view360",
            currentOptionId: currentContentId,
            iconId: "street-view",
         },
         {
            optionsList: triviaOptions,
            listTitle: "Trivia Games",
            listId: "trivia",
            currentOptionId: currentContentId,
            iconId: "stopwatch",
         },
      ];

      //console.log(menuLists);

      const html = [];

      for (const menuList of menuLists) {
         html.push(this.renderOptionsList(menuList));
      }

      return html;
   }

   renderOptionsList({ optionsList, listTitle, listId, currentOptionId, iconId }) {
      const html = [];

      for (const naviOption of optionsList) {
         const { name, id, disabled } = naviOption;

         let classes = "navi-btn";
         if (disabled) classes += " disabled";
         if (currentOptionId && currentOptionId === id) classes += " selected";

         const key = "navi-btn-" + listId + "-" + id;

         html.push(
            <button className={classes} key={key} onClick={(event) => this.contentClicked(listId, id)}>
               <div className="btn-name">{name}</div>
               <div className="btn-circle">
                  <FontAwesomeIcon icon={iconId} className="btn-icon" />
               </div>
            </button>
         );
      }

      const key = "list-" + listId;

      return (
         <div className="host-group" key={key}>
            <div className="side-title">{listTitle}</div>
            {html}
         </div>
      );
   }
}

export default RoomHost;
